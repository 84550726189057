var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-container"},[_c('Headbar',{scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('router-link',{attrs:{"to":{name: 'wards-create'}}},[(_vm.$store.getters.hasPermission('store wards'))?_c('Button',{attrs:{"className":"--primary --small"}},[_vm._v(" "+_vm._s(_vm.$t('wards.add_ward'))+" ")]):_vm._e()],1)]},proxy:true},{key:"right",fn:function(){return [_c('Search',{attrs:{"placeholder":_vm.$t('wards.search_wards')},on:{"search":_vm.search}})]},proxy:true}])}),_c('main',[_c('vue-good-table',{attrs:{"mode":"remote","styleClass":"vgt-table striped vgt-custom","columns":_vm.columns,"rows":_vm.wards,"isLoading":_vm.is_loading_wards,"search-options":{
            enabled: false,
        },"pagination-options":{
            enabled: true,
            mode: 'records',
            dropdownAllowAll: false,
            perPage: 15,
            perPageDropdownEnabled: false,
            rowsPerPageLabel: _vm.$t('x_per_page', {x: _vm.$t('wards.wards')}),
        },"totalRows":_vm.totalRecords},on:{"update:isLoading":function($event){_vm.is_loading_wards=$event},"update:is-loading":function($event){_vm.is_loading_wards=$event},"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'after')?_c('div',{staticClass:"td-after"},[_c('router-link',{attrs:{"to":{name: 'wards-update', params: {id: props.row.id}}}},[(_vm.$store.getters.hasPermission('update wards'))?_c('Button',{attrs:{"className":"--secondary --outline --mini --big-text"}},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'eye']}})],1):_vm._e()],1),(_vm.$store.getters.hasPermission('destroy wards'))?_c('Button',{attrs:{"className":"--secondary --outline --mini --big-text","onclick":function (){ return _vm.toggleDelete(props.row); }}},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'trash']}})],1):_vm._e()],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }