<template>
    <div class="page-container">
        <Headbar>
            <template v-slot:left>
                <router-link :to="{name: 'bookings-create'}">
                    <Button v-if="$store.getters.hasPermission('store bookings')" className="--primary --small">
                        {{ $t('bookings.add_booking') }}
                    </Button>
                </router-link>
                <router-link :to="{name: 'bookings-create-bulk'}">
                    <Button v-if="$store.getters.hasPermission('store bookings')" className="--primary --small">
                        {{ $t('bookings.add_bulk_booking') }}
                    </Button>
                </router-link>
            </template>
            <template v-slot:right>
                <Select v-model="filter_date" :options="filter_date_options" :allowEmpty="false"
                        className="filter booking-date" @updated="filterUpdated" label="label" track-by="value"/>
                <Select v-model="filter_ward" :options="filter_ward_options" :placeholder="$t('wards.select_ward')"
                        :custom-label="(row) => row.attributes.name" track-by="id"
                        className="filter" @updated="filterUpdated"/>

                <Search :placeholder="$t('bookings.search_bookings')" @search="search"/>
            </template>
        </Headbar>
        <main>
            <vue-good-table
                mode="remote"
                styleClass="vgt-table striped vgt-custom"
                :columns="columns"
                :rows="bookings"
                :isLoading.sync="is_loading_bookings"
                :search-options="{
                        enabled: false,
                    }"
                :sort-options="{
                        enabled: true,
                        multipleColumns: true,
                    }"
                :pagination-options="{
                        enabled: true,
                        mode: 'records',
                        dropdownAllowAll: false,
                        perPage: 15,
                        perPageDropdownEnabled: false,
                        rowsPerPageLabel: $t('x_per_page', {x: $t('bookings.bookings')}),
                    }"
                :totalRows="totalRecords"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange">
                <template slot="table-row" slot-scope="props">
                    <div v-if="props.column.field === 'date'">
                        <p>{{ $moment.utc(props.row.attributes.from).format('DD/MM/YYYY') }}</p>
                    </div>
                    <div v-if="props.column.field === 'time'">
                        <p>{{ $moment.utc(props.row.attributes.from).format('HH:mm') }} -
                            {{ $moment.utc(props.row.attributes.to).format('HH:mm') }}</p>
                    </div>
                    <div v-if="props.column.field === 'visitor_1_name'">
                        <p v-if="props.row.relationships && props.row.relationships.visitors && props.row.relationships.visitors.data.length >= 1">
                            {{ props.row.relationships.visitors.data[0].attributes.name }}
                        </p>
                    </div>
                    <div v-else-if="props.column.field === 'visitor_1_id'">
                        <p v-if="props.row.relationships && props.row.relationships.visitors && props.row.relationships.visitors.data.length >= 1">
                            {{ props.row.relationships.visitors.data[0].attributes.id_number }}
                        </p>
                    </div>
                    <div v-else-if="props.column.field === 'visitor_2_name'">
                        <p v-if="props.row.relationships && props.row.relationships.visitors && props.row.relationships.visitors.data.length >= 2">
                            {{ props.row.relationships.visitors.data[1].attributes.name }}
                        </p>
                    </div>
                    <div v-else-if="props.column.field === 'visitor_2_id'">
                        <p v-if="props.row.relationships && props.row.relationships.visitors && props.row.relationships.visitors.data.length >= 2">
                            {{ props.row.relationships.visitors.data[1].attributes.id_number }}
                        </p>
                    </div>
                  <div v-else-if="props.column.field === 'visitor_3_name'">
                    <p v-if="props.row.relationships && props.row.relationships.visitors && props.row.relationships.visitors.data.length >= 3">
                      {{ props.row.relationships.visitors.data[2].attributes.name }}
                    </p>
                  </div>
                    <div v-else-if="props.column.field === 'after'" class="td-after">
                        <Button v-if="$store.getters.hasPermission('update bookings')"
                                className="--secondary --outline --mini --big-text"
                                :onclick="()=>toggleUpdateVisitors(props.row)">
                            <font-awesome-icon :icon="['fal', 'user']"/>
                        </Button>
                        <router-link :to="{name: 'bookings-update', params: {id: props.row.id}}">
                            <Button v-if="$store.getters.hasPermission('update bookings')"
                                    className="--secondary --outline --mini --big-text">
                                <font-awesome-icon :icon="['fal', 'eye']"/>
                            </Button>
                        </router-link>
                        <Button v-if="$store.getters.hasPermission('destroy bookings')"
                                className="--secondary --outline --mini --big-text"
                                :onclick="()=>toggleDelete(props.row)">
                            <font-awesome-icon :icon="['fal', 'trash']"/>
                        </Button>
                    </div>
                    <span v-else>
                      {{ props.formattedRow[props.column.field] }}
                    </span>
                </template>
            </vue-good-table>
        </main>
    </div>
</template>

<script>
import Headbar from "../../components/headbar/Headbar";
import Search from "../../components/Search";
import Button from "../../components/Button";
import Select from "../../components/form/Select";
import ConfirmModal from "../../components/modal/ConfirmModal";
import BookingVisitorsModal from "../../components/bookings/BookingVisitorsModal";

export default {
    name: "bookings-index-page",
    components: {ConfirmModal, Select, Button, Search, Headbar},
    data: function () {
        const columns = [
            {
                label: this.$t('bookings.id'),
                field: 'id',
                sortable: false,
            },
            {
                label: this.$t('bookings.date'),
                field: 'date',
                sortable: true,
            },
            {
                label: this.$t('bookings.ward'),
                field: row => row.attributes.ward ? row.attributes.ward : row.relationships.patient.data.attributes.ward,
                sortable: false,
            },
            {
                label: this.$t('bookings.first_name'),
                field: 'relationships.patient.data.attributes.first_name',
                sortable: false,
            },
            {
                label: this.$t('bookings.last_name'),
                field: 'relationships.patient.data.attributes.last_name',
                sortable: false,
            },
            {
                label: this.$t('bookings.time'),
                field: 'time',
                sortable: false,
            },
            {
                label: this.$t('bookings.visitor_x_name', {x: 1}),
                field: 'visitor_1_name',
                sortable: false,
            },
            // {
            //     label: this.$t('bookings.visitor_x_id', {x: 1}),
            //     field: 'visitor_1_id',
            //     sortable: false,
            // },
            {
                label: this.$t('bookings.visitor_x_name', {x: 2}),
                field: 'visitor_2_name',
                sortable: false,
            },
            // {
            //     label: this.$t('bookings.visitor_x_id', {x: 2}),
            //     field: 'visitor_2_id',
            //     sortable: false,
            // }
          {
            label: this.$t('bookings.visitor_x_name', {x: 3}),
            field: 'visitor_3_name',
            sortable: false,
          },
        ];

        if (this.$store.getters.hasAnyPermission(['update bookings', 'destroy bookings']))
            columns.push({
                label: this.$t('bookings.actions'),
                field: 'after',
                tdClass: 'td-after',
                sortable: false
            })

        return {
            columns: columns,
            bookings: [],
            is_loading_bookings: false,
            totalRecords: null,
            serverParams: {
                sorting: [],
                filters: [
                    {
                        filter_by: 'from',
                        filter_operator: '>=',
                        filter_value: this.$moment().format('YYYY-MM-DD 00:00:00')
                    },
                    {
                        filter_by: 'to',
                        filter_operator: '<=',
                        filter_value: this.$moment().format('YYYY-MM-DD 23:59:59')
                    }
                ]
            },
            searchTerm: null,
            filter_ward: null,
            filter_ward_options: [],
            filter_date: {
                label: this.$t('bookings.todays_bookings'),
                value: 'today',
            },
            filter_date_options: [
                {
                    label: this.$t('bookings.todays_bookings'),
                    value: 'today',
                },
                {
                    label: this.$t('bookings.upcoming_bookings'),
                    value: 'upcoming',
                },
                {
                    label: this.$t('bookings.past_bookings'),
                    value: 'past',
                },
            ],
            is_loading_filter_ward_options: false,
            is_deleting: false,
        }
    },
    methods: {
        toggleDelete(booking) {
            this.$modal.show(
                ConfirmModal, {
                    title: this.$t('warning'),
                    message: this.$t('bookings.prompt_delete'),
                    confirmText: this.$t('yes'),
                    cancelText: this.$t('no'),
                    confirmClass: '--negative',
                    cancelClass: '--primary --outline'
                },
                {
                    name: 'confirm-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true) {
                            this.is_deleting = true;
                            this.$axios.delete(`bookings/${booking.id}`)
                                .then(({data}) => {
                                    this.is_deleting = false;
                                    this.retrieveBookings();
                                })
                                .catch(e => {
                                    this.is_deleting = false;

                                    this.$notify({
                                        title: this.$t('error'),
                                        text: this.$larerror(e.response.data, this.$t('bookings.error_delete')),
                                        type: 'error',
                                    });
                                });
                        }
                    }
                }
            );
        },

        toggleUpdateVisitors(booking) {
            this.$modal.show(
                BookingVisitorsModal, {
                    booking_id: booking.id
                },
                {
                    name: 'booking-visitors-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    width: '800px',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true) {

                        }
                    }
                }
            );
        },

        filterUpdated() {
            const filters = [];

            // date
            if (this.filter_date.value === 'today') {
                filters.push({
                        filter_by: 'from',
                        filter_operator: '>=',
                        filter_value: this.$moment().format('YYYY-MM-DD 00:00:00')
                    },
                    {
                        filter_by: 'to',
                        filter_operator: '<=',
                        filter_value: this.$moment().format('YYYY-MM-DD 23:59:59')
                    })
            } else if (this.filter_date.value === 'upcoming') {
                filters.push({
                    filter_by: 'from',
                    filter_operator: '>',
                    filter_value: this.$moment().format('YYYY-MM-DD HH:mm:ss')
                })
            } else if (this.filter_date.value === 'past') {
                filters.push({
                    filter_by: 'from',
                    filter_operator: '<',
                    filter_value: this.$moment().format('YYYY-MM-DD HH:mm:ss')
                })
            }

            if (filters.length)
                this.updateParams({
                    filters: filters
                });
            else this.removeParam('filters');

            this.retrieveBookings();
        },
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
            this.updateParams({page: params.currentPage});
            this.retrieveBookings();
        },
        onSortChange(params) {
            const sorts = [];

            if (!params || !params.length) {
                this.updateParams({sorting: []});
                return this.retrieveBookings();
                ;
            }

            params.forEach(p => {
                if (!p.type || p.type === 'none')
                    return;

                let sort_by = null;
                let sort_order = p.type;
                if (p.field === 'date')
                    sort_by = 'from';
                else
                    sort_by = p.field.split('.')[1];

                sorts.push({sort_order, sort_by})
            });

            this.updateParams({sorting: sorts});
            this.retrieveBookings();
        },
        removeParam(param) {
            this.$delete(this.serverParams, param);
        },
        search(searchTerm) {
            this.searchTerm = searchTerm;

            if (searchTerm && searchTerm.length)
                this.updateParams({term: searchTerm});
            else this.removeParam('term');

            this.retrieveBookings();
        },
        retrieveFilterWardOptions() {
            this.is_loading_filter_ward_options = false;
            this.$axios.get('wards/list')
                .then(({data}) => {
                    this.is_loading_filter_ward_options = false;
                    this.filter_ward_options = data.data;
                })
                .catch(e => {
                    this.is_loading_filter_ward_options = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('wards.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        retrieveBookings() {
            this.is_loading_bookings = true;

            const route = this.filter_ward ? `wards/${this.filter_ward.id}/bookings` : 'bookings';

            const serverParams = {...this.serverParams};
            const encodedFilters = serverParams.filters.map(f => btoa(JSON.stringify(f)));
            const encodedSorting = this.serverParams.sorting.map(f => btoa(JSON.stringify(f)));

            delete serverParams.filters;

            this.$axios.get(route, {params: {...serverParams, sorting: encodedSorting, filters: encodedFilters}})
                .then(({data}) => {
                    this.bookings = data.data;
                    this.totalRecords = data.meta.total;
                    this.is_loading_bookings = false;
                })
                .catch(e => {
                    this.is_loading_bookings = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('bookings.error_retrieve')),
                        type: 'error',
                    });
                });
        }
    },

    mounted() {
        this.retrieveBookings();
        this.retrieveFilterWardOptions();
    },

    head() {
        return {
            title: {
                inner: this.$t('nav.bookings')
            },
        }
    }
}
</script>

<style lang="scss" scoped>
.page-container {
    .td-after {
        @apply flex flex-row;

        & > * {
            @apply mr-3;

            &:last-child {
                @apply mr-0;
            }
        }
    }
}
</style>

<style lang="scss">
.multiselect.filter.booking-date {
    min-width: 180px;
}
</style>