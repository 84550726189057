<template>
    <div class="page-container">
        <Headbar>
            <template v-slot:left>
                <router-link :to="{name: 'wards-create'}">
                    <Button v-if="$store.getters.hasPermission('store wards')" className="--primary --small">
                        {{$t('wards.add_ward')}}
                    </Button>
                </router-link>
            </template>
            <template v-slot:right>
                <Search :placeholder="$t('wards.search_wards')" @search="search"/>
            </template>
        </Headbar>
        <main>
            <vue-good-table
                    mode="remote"
                    styleClass="vgt-table striped vgt-custom"
                    :columns="columns"
                    :rows="wards"
                    :isLoading.sync="is_loading_wards"
                    :search-options="{
                enabled: false,
            }"
                    :pagination-options="{
                enabled: true,
                mode: 'records',
                dropdownAllowAll: false,
                perPage: 15,
                perPageDropdownEnabled: false,
                rowsPerPageLabel: $t('x_per_page', {x: $t('wards.wards')}),
            }"
                    :totalRows="totalRecords"
                    @on-page-change="onPageChange"
                    @on-sort-change="onSortChange">
                <template slot="table-row" slot-scope="props">
                    <div v-if="props.column.field === 'after'" class="td-after">
                        <router-link :to="{name: 'wards-update', params: {id: props.row.id}}">
                            <Button v-if="$store.getters.hasPermission('update wards')" className="--secondary --outline --mini --big-text">
                                <font-awesome-icon :icon="['fal', 'eye']"/>
                            </Button>
                        </router-link>
                        <Button v-if="$store.getters.hasPermission('destroy wards')" className="--secondary --outline --mini --big-text"
                                :onclick="()=>toggleDelete(props.row)">
                            <font-awesome-icon :icon="['fal', 'trash']"/>
                        </Button>
                    </div>
                    <span v-else>
                      {{props.formattedRow[props.column.field]}}
                    </span>
                </template>
            </vue-good-table>
        </main>
    </div>
</template>

<script>
    import Headbar from "../../components/headbar/Headbar";
    import Search from "../../components/Search";
    import ConfirmModal from "../../components/modal/ConfirmModal";
    import Button from "../../components/Button";

    export default {
        name: "wards-index-page",
        components: {Button, Search, Headbar},
        data: function() {
                const columns = [
                    {
                        label: this.$t('wards.id'),
                        field: 'id',
                        sortable: false,
                    },
                    {
                        label: this.$t('wards.name'),
                        field: 'attributes.name',
                        sortable: false,
                    },
                    {
                        label: this.$t('wards.capacity'),
                        field: 'attributes.capacity',
                        sortable: false,
                    },
                    {
                        label: this.$t('wards.occupied'),
                        field: 'attributes.occupancy',
                        sortable: false,
                    },
                    {
                        label: this.$t('wards.available'),
                        field: 'attributes.vacancy',
                        sortable: false,
                    }
                ];

            if (this.$store.getters.hasAnyPermission(['update wards', 'destroy wards']))
                columns.push({
                    label: this.$t('wards.actions'),
                    field: 'after',
                    tdClass: 'td-after',
                    sortable: false
                });

            return {
                columns: columns,
                wards: [],
                is_loading_wards: false,
                totalRecords: null,
                serverParams: {},
                searchTerm: null,
                is_deleting: false,
            }
        },
        methods: {
            toggleDelete(ward) {
                this.$modal.show(
                    ConfirmModal, {
                        title: this.$t('warning'),
                        message: this.$t('wards.prompt_delete'),
                        confirmText: this.$t('yes'),
                        cancelText: this.$t('no'),
                        confirmClass: '--negative',
                        cancelClass: '--primary --outline'
                    },
                    {
                        name: 'confirm-modal',
                        adaptive: true,
                        resizable: true,
                        height: 'auto',
                        scrollable: true,
                        classes: 'modal',
                    }, {
                        'before-close': (e) => {
                            if (e.params === true) {
                                this.is_deleting = true;
                                this.$axios.delete(`wards/${ward.id}`)
                                    .then(({data}) => {
                                        this.is_deleting = false;
                                        this.retrieveWards();
                                    })
                                    .catch(e => {
                                        this.is_deleting = false;

                                        this.$notify({
                                            title: this.$t('error'),
                                            text: this.$larerror(e.response.data, this.$t('wards.error_delete')),
                                            type: 'error',
                                        });
                                    });
                            }
                        }
                    }
                );
            },
            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },
            onPageChange(params) {
                this.updateParams({page: params.currentPage});
                this.retrieveWards();
            },
            onSortChange(params) {
                this.updateParams({sort: params});
                this.retrieveWards();
            },
            removeParam(param) {
                this.$delete(this.serverParams, param);
            },
            search(searchTerm) {
                this.searchTerm = searchTerm;

                if (searchTerm && searchTerm.length)
                    this.updateParams({term: searchTerm});
                else this.removeParam('term');

                this.retrieveWards();
            },
            retrieveWards() {
                this.is_loading_wards = true;

                this.$axios.get('wards', {params: this.serverParams})
                    .then(({data}) => {
                        this.wards = data.data;
                        this.totalRecords = data.meta.total;
                        this.is_loading_wards = false;
                    })
                    .catch(e => {
                        this.is_loading_wards = false;

                        this.$notify({
                            title: this.$t('error'),
                            text: this.$larerror(e.response.data, this.$t('wards.error_retrieve')),
                            type: 'error',
                        });
                    });
            }
        },

        mounted() {
            this.retrieveWards();
        },

        head() {
            return {
                title: {
                    inner: this.$t('nav.wards')
                },
            }
        }
    }
</script>

<style lang="scss" scoped>
    .page-container {
        .td-after {
            @apply flex flex-row;

            & > * {
                @apply mr-3;

                &:last-child {
                    @apply mr-0;
                }
            }
        }
    }
</style>