<template>
    <div class="page-container">
        <Headbar>
            <template v-slot:left>
                <h1 v-if="original">{{original.id}} - {{original.attributes.name}}</h1>
            </template>
            <template v-slot:right>
                <Button v-if="original" className="--secondary --outline --small" :class="{'spinner-black': is_toggling_close}" :onclick="toggleClose">
                    {{$t(original.attributes.is_closed ? 'wards.open_ward' : 'wards.close_ward')}}
                </Button>

                <Button className="--primary --outline --small" :class="{spinner: is_saving}" :onclick="save">
                    {{$t('save')}}
                </Button>
            </template>
        </Headbar>
        <main>
            <div class="form-area">
                <Form class="form" @submit="save" :disabled="is_saving">
                    <SectionHeader :title="$t('wards.ward_details')"></SectionHeader>
                    <div class="form-body">
                        <FormGroupThree>
                            <FormInputText v-model="$v.ward.name.$model" identifier="name" :label="$t('wards.name')"
                                           :placeholder="$t('wards.name')" :disabled="is_saving"
                                           :has-error="$v.ward.name.$error">
                                <template v-slot:errors>
                                    <p v-if="!$v.ward.name.required">
                                        {{$t('validation.x_is_required',{x: $t('wards.name')})}}
                                    </p>
                                </template>
                            </FormInputText>
                            <FormInputText v-model="$v.ward.capacity.$model" identifier="capacity"
                                           :label="$t('wards.capacity')"
                                           :placeholder="$t('wards.capacity')" :disabled="is_saving" type="number"
                                           :has-error="$v.ward.capacity.$error">
                                <template v-slot:errors>
                                    <p v-if="!$v.ward.capacity.required">
                                        {{$t('validation.x_is_required',{x: $t('wards.capacity')})}}
                                    </p>
                                    <p v-else-if="!$v.ward.capacity.numeric">
                                        {{$t('validation.please_enter_number')}}
                                    </p>
                                </template>
                            </FormInputText>
                            <FormInputText v-model="$v.ward.max_parallel_bookings.$model"
                                           identifier="max_parallel_bookings"
                                           :label="$t('wards.max_parallel_bookings')" type="number"
                                           :placeholder="$t('wards.max_parallel_bookings')" :disabled="is_saving"
                                           :has-error="$v.ward.max_parallel_bookings.$error">
                                <template v-slot:errors>
                                    <p v-if="!$v.ward.max_parallel_bookings.required">
                                        {{$t('validation.x_is_required',{x: $t('wards.max_parallel_bookings')})}}
                                    </p>
                                    <p v-else-if="!$v.ward.max_parallel_bookings.numeric">
                                        {{$t('validation.please_enter_number')}}
                                    </p>
                                </template>
                            </FormInputText>
                        </FormGroupThree>
                    </div>
                </Form>
                <Form class="form" @submit="save" :disabled="is_saving">
                    <SectionHeader :title="$t('wards.availability')"></SectionHeader>
                    <div class="form-body">
                        <FormGroupThree>
                            <FormInputText v-model="$v.ward.occupied.$model" identifier="occupied"
                                           :label="$t('wards.occupied')" type="number"
                                           :placeholder="$t('wards.occupied')" :disabled="is_saving"
                                           :has-error="$v.ward.occupied.$error" :readonly="true">
                                <template v-slot:errors>
                                    <p v-if="!$v.ward.occupied.required">
                                        {{$t('validation.x_is_required',{x: $t('wards.occupied')})}}
                                    </p>
                                    <p v-else-if="!$v.ward.occupied.numeric">
                                        {{$t('validation.please_enter_number')}}
                                    </p>
                                </template>
                            </FormInputText>
                            <FormInputText v-model="$v.ward.available.$model" identifier="available"
                                           :label="$t('wards.available')" type="number"
                                           :placeholder="$t('wards.available')" :disabled="is_saving"
                                           :has-error="$v.ward.available.$error" :readonly="true">
                                <template v-slot:errors>
                                    <p v-if="!$v.ward.available.required">
                                        {{$t('validation.x_is_required',{x: $t('wards.available')})}}
                                    </p>
                                    <p v-else-if="!$v.ward.available.numeric">
                                        {{$t('validation.please_enter_number')}}
                                    </p>
                                </template>
                            </FormInputText>
                        </FormGroupThree>
                    </div>
                </Form>
              <Form class="form" @submit="save" :disabled="is_saving">
                <SectionHeader :title="$t('wards.time_slots')"></SectionHeader>
                <div class="form-body">
                  <FormGroupThree v-for="(timeslot, i) in $v.timeslots.$each.$iter" :key="timeslot.key"
                                  class="timeslot-row-group">
                    <FormInputDateTime v-model="timeslot.start.$model" :identifier="`timeslot-${+i}-start`"
                                       :label="$t('start', {x: +i+1})" :only-time="true" :minuteInterval="15"
                                       format="HH:mm" formatted="HH:mm"  :placeholder="$t('start')"
                                       :disabled="is_saving" :has-error="timeslot.start.$error"
                                       output-format="HH:mm:ss">
                      <template v-slot:errors>
                        <p v-if="!timeslot.start.required">
                          {{$t('validation.x_is_required',{x: $t('start')})}}
                        </p>
                      </template>
                    </FormInputDateTime>
                    <FormInputDateTime v-model="timeslot.finish.$model" :identifier="`timeslot-${+i}-finish`"
                                       :label="$t('finish', {x: +i+1})" :only-time="true" :minuteInterval="15"
                                       format="HH:mm" formatted="HH:mm" :placeholder="$t('finish')"
                                       :disabled="is_saving" :has-error="timeslot.finish.$error"
                                       output-format="HH:mm:ss">
                      <template v-slot:errors>
                        <p v-if="!timeslot.finish.required">
                          {{$t('validation.x_is_required',{x: $t('finish')})}}
                        </p>
                      </template>
                    </FormInputDateTime>
                    <Button className="--secondary --outline --mini" :onclick="() => removeTimeslot(i)"
                            class="button-remove">
                      <font-awesome-icon :icon="['far','times']"/>
                    </Button>
                  </FormGroupThree>
                </div>

                <div class="row-add-container">
                  <Button className="--primary --outline --small" :onclick="addTimeslot">
                    {{$t('wards.add_timeslot')}}
                  </Button>
                </div>
              </Form>
            </div>
            <div class="divider"></div>
            <div class="table-area">
                <header>
                  <h3>{{$t('wards.patients_in_ward')}}</h3>
                  <div class="actions">
                      <Button className="--secondary --outline --small" :onclick="toggleUpdateMaxDailyBookings">
                          {{$t('wards.update_max_daily_bookings')}}
                      </Button>
                      <Button className="--secondary --outline --small" :onclick="toggleUpdateMaxWeeklyBookings">
                          {{$t('wards.update_max_weekly_bookings')}}
                      </Button>
                  </div>
                </header>
                <vue-good-table
                        mode="remote"
                        styleClass="vgt-table striped vgt-custom"
                        :columns="columns"
                        :rows="patients"
                        :isLoading.sync="is_loading_patients"
                        :search-options="{
                enabled: false,
            }"
                        :sort-options="{
                          enabled: true,
                          multipleColumns: true,
                        }"
                        :pagination-options="{
                enabled: true,
                mode: 'records',
                dropdownAllowAll: false,
                perPage: 15,
                perPageDropdownEnabled: false,
                rowsPerPageLabel: $t('x_per_page', {x: $t('patients.patients')}),
            }"
                        :totalRows="totalRecords"
                        @on-page-change="onPageChange"
                        @on-sort-change="onSortChange">
                    <template slot="table-row" slot-scope="props">
                        <div v-if="props.column.field === 'gender'" class="td-gender">
                            <p v-if="props.row.attributes.gender.toLowerCase() === 'male'" class="male">
                                M
                            </p>
                            <p v-else-if="props.row.attributes.gender.toLowerCase() === 'female'" class="female">
                                F
                            </p>
                        </div>
                        <div v-else-if="props.column.field === 'third_party'" class="td-third-party">
                            <p v-if="['yes','true','accepted'].includes(props.row.attributes.third_party.toLowerCase())"
                               class="accepted">
                                {{$t('accepted')}}
                            </p>
                            <p v-else class="not-accepted">
                                {{$t('not_accepted')}}
                            </p>
                        </div>
                        <div v-else-if="props.column.field === 'media'" class="td-media">
                            <p>
                                {{props.row.attributes.media_permission ? $t('yes') : $t('no')}}
                            </p>
                        </div>
                        <span v-else>
                      {{props.formattedRow[props.column.field]}}
                    </span>
                    </template>
                </vue-good-table>
            </div>
        </main>
    </div>
</template>

<script>
    import Headbar from "../../components/headbar/Headbar";
    import Form from "../../components/form/Form";
    import {required, numeric} from 'vuelidate/lib/validators'
    import SectionHeader from "../../components/SectionHeader";
    import FormGroupThree from "../../components/form/FormGroupThree";
    import FormInputSelect from "../../components/form/FormInputSelect";
    import FormInputText from "../../components/form/FormInputText";
    import Button from "../../components/Button";
    import FormInputDateTime from "../../components/form/FormInputDateTime";
    import UsersCreateModal from "../../components/users/UsersCreateModal";
    import WardMaxDailyBookingsModal from "../../components/wards/WardMaxDailyBookingsModal";
    import WardMaxWeeklyBookingsModal from "../../components/wards/WardMaxWeeklyBookingsModal";

    export default {
        name: "wards-update-page",
        components: {
            FormInputDateTime,
            Button, FormInputText, FormInputSelect, FormGroupThree, SectionHeader, Form, Headbar
        },
        data() {
            return {
                original: null,
                ward: {
                    name: null,
                    capacity: null,
                    max_parallel_bookings: null,
                    occupied: null,
                    available: null,
                },
                timeslots: [
                  {key: 1, start: null, finish: null, id: null},
                ],
                timeslotsToDelete: [],
                patients: [],
                columns: [
                    {
                        label: this.$t('patients.id_number'),
                        field: 'attributes.id_number',
                        sortable: false,
                    },
                    {
                        label: this.$t('patients.ward'),
                        field: 'attributes.ward_name',
                        sortable: false,
                    },
                    {
                        label: this.$t('patients.first_name'),
                        field: 'attributes.first_name',
                        sortable: true,
                    },
                    {
                      label: this.$t('patients.last_name'),
                      field: 'attributes.last_name',
                      sortable: true,
                    },
                    {
                        label: this.$t('patients.date_of_birth'),
                        field: 'attributes.date_of_birth',
                        sortable: false,
                    },
                    {
                        label: this.$t('patients.gender'),
                        field: 'gender',
                        sortable: false,
                    },
                    {
                        label: this.$t('patients.third_party'),
                        field: 'third_party',
                        sortable: false,
                    },
                    {
                        label: this.$t('patients.media_permission'),
                        field: 'media',
                        sortable: false,
                    },
                ],
                is_loading_patients: false,
                totalRecords: null,
                serverParams: {sorting: []},
                is_saving: false,
                is_toggling_close: false,
                is_loading_original: false,
            }
        },
        validations: {
            ward: {
                name: {
                    required
                },
                capacity: {
                    required,
                    numeric
                },
                max_parallel_bookings: {
                    required,
                    numeric
                },
                occupied: {},
                available: {}
            },
            timeslots: {
              $each: {
                start: {required},
                finish: {required},
              }
            },
        },
        methods: {
          removeTimeslot(index) {
            index = parseInt(index);
            if (index >= this.$v.timeslots.$model.length)
              return;

            if(this.timeslots[index].id)
              this.timeslotsToDelete.push(this.timeslots[index].id);

            this.$v.timeslots.$model.splice(index, 1);
          },
          addTimeslot() {
            this.$v.timeslots.$touch();

            if (!this.$v.timeslots.$anyError)
              this.$v.timeslots.$model.push({
                key: this.$v.timeslots.$model.length + 1,
                start: null,
                finish: null,
                id: null
              });
          },
            retrieveWardPatients() {
                this.is_loading_patients = true;

                const filters = [{
                    filter_by: 'ward_id',
                    filter_value: this.$route.params.id
                },
                {
                  filter_by: 'discharged_on',
                  filter_operator: '=',
                  filter_value: null
                },
                {
                  filter_by: 'deceased_on',
                  filter_operator: '=',
                  filter_value: null
                }];

              const encodedFilters = filters.map(f=>btoa(JSON.stringify(f)));
              const encodedSorting = this.serverParams.sorting.map(f=>btoa(JSON.stringify(f)));

              const serverParams = {...this.serverParams};
              delete serverParams.filters;

              this.$axios.get('patients', {params: {...serverParams, sorting: encodedSorting, filters: encodedFilters}})
                    .then(({data}) => {
                        this.patients = data.data;
                        this.totalRecords = data.meta.total;
                        this.is_loading_patients = false;
                    })
                    .catch(e => {
                        this.is_loading_patients = false;

                        this.$notify({
                            title: this.$t('error'),
                            text: this.$larerror(e.response.data, this.$t('patients.error_retrieve')),
                            type: 'error',
                        });
                    });
            },
            populate() {
                if (!this.original || !this.ward)
                    return;

                this.$v.ward.name.$model = this.original.attributes.name;
                this.$v.ward.capacity.$model = this.original.attributes.capacity.toString();
                this.$v.ward.max_parallel_bookings.$model = this.original.attributes.max_parallel_bookings.toString();
                this.$v.ward.occupied.$model = this.original.attributes.occupancy.toString();
                this.$v.ward.available.$model = this.original.attributes.vacancy.toString();

                if(this.original.relationships.timeslots && this.original.relationships.timeslots.data && this.original.relationships.timeslots.data.length){
                  this.timeslots = [];
                  this.original.relationships.timeslots.data.forEach(t=>{
                    const start = this.$moment.utc(t.attributes.start, 'HH:mm:ss').format('HH:mm:ss');
                    const finish = this.$moment.utc(t.attributes.finish, 'HH:mm:ss').format('HH:mm:ss');

                    this.timeslots.push({
                      id: t.id,
                      key: t.id,
                      start,
                      finish,
                    })
                  })
                }
            },
            async retrieveOriginalWard() {
                this.is_loading_original = false;
                await this.$axios.get(`wards/${this.$route.params.id}`)
                    .then(({data}) => {
                        this.is_loading_original = false;
                        this.original = data.data;
                    })
                    .catch(e => {
                        this.is_loading_original = false;

                        this.$notify({
                            title: this.$t('error'),
                            text: this.$larerror(e.response.data, this.$t('wards.error_retrieve')),
                            type: 'error',
                        });
                    });
            },
            toggleUpdateMaxDailyBookings(){
              this.$modal.show(
                  WardMaxDailyBookingsModal, {
                    ward_id: this.$route.params.id
                  },
                  {
                    name: 'ward-max-daily-bookings-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                  }, {
                    'before-close': (e) => {}
                  }
              );
            },
            toggleUpdateMaxWeeklyBookings(){
                this.$modal.show(
                    WardMaxWeeklyBookingsModal, {
                        ward_id: this.$route.params.id
                    },
                    {
                        name: 'ward-max-weekly-bookings-modal',
                        adaptive: true,
                        resizable: true,
                        height: 'auto',
                        scrollable: true,
                        classes: 'modal',
                    }, {
                        'before-close': (e) => {}
                    }
                );
            },
            toggleClose(){
              this.is_toggling_close=true;
              this.$axios.put(`wards/${this.$route.params.id}/toggle-close-ward`).then(({data}) => {
                this.original.attributes.is_closed = data.data.attributes.is_closed;
                this.is_toggling_close = false;
              }).catch(e => {
                this.is_toggling_close = false;

                this.$notify({
                  title: this.$t('error'),
                  text: this.$larerror(e.response.data.errors, this.$t('wards.error_update')),
                  type: 'error',
                });
              });
            },
            save() {
                this.$v.ward.$touch();
                if (this.$v.ward.$anyError || this.is_saving)
                    return;

                this.is_saving = true;

                const payload = Object.assign({}, this.$v.ward.$model);

                if (this.original.attributes.name === this.$v.ward.$model.name)
                    delete payload.name;

                this.$axios.patch(`wards/${this.$route.params.id}`, payload).then(({data}) => {
                    this.$notify({
                        text: this.$t('wards.success_updated'),
                        type: 'success',
                    });

                  // delete timeslots
                  this.timeslotsToDelete.forEach(id => {
                      this.$axios.delete(`timeslots/${id}`);
                  });

                  // update timeslots
                  this.timeslots.filter(t => t.id).forEach(t => {
                    if(!t.start.$error && !t.finish.$error) {
                      const start = this.$moment(t.start, 'HH:mm:ss').format('HH:mm');
                      const finish = this.$moment(t.finish, 'HH:mm:ss').format('HH:mm');

                      this.$axios.patch(`timeslots/${t.id}`, {start, finish});
                    }
                  });

                  // add timeslots
                  this.timeslots.filter(t => !t.id).forEach(t => {
                    if(!t.start.$error && !t.finish.$error) {
                      const start = this.$moment(t.start, 'HH:mm:ss').format('HH:mm');
                      const finish = this.$moment(t.finish, 'HH:mm:ss').format('HH:mm');

                      this.$axios.post(`wards/${this.$route.params.id}/create-timeslot`, {
                        start, finish
                      });
                    }
                  });

                    this.is_saving = false;

                    this.$router.push({name: 'wards-index'});
                }).catch(e => {
                    this.is_saving = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data.errors, this.$t('wards.error_update')),
                        type: 'error',
                    });
                });
            },
            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },
            onPageChange(params) {
                this.updateParams({page: params.currentPage});
                this.retrieveWardPatients();
            },
            onSortChange(params) {
              const sorts = [];

              if (!params || !params.length) {
                this.updateParams({sorting: []});
                return this.retrieveWardPatients();
              }

              params.forEach(p => {
                if(!p.type || p.type === 'none')
                  return;

                let sort_by = null;
                let sort_order = p.type;
                if (p.field === 'attributes.first_name')
                  sort_by = 'first_name';
                else if (p.field === 'attributes.last_name')
                  sort_by = 'last_name';
                else
                  sort_by = p.field.split('.')[1];

                sorts.push({sort_order, sort_by})
              });

              this.updateParams({sorting: sorts});
              this.retrieveWardPatients();
            },
            removeParam(param) {
                this.$delete(this.serverParams, param);
            },
        },
        async mounted() {
            this.retrieveWardPatients();
            await this.retrieveOriginalWard();
            this.populate();
        },
        head() {
            return {
                title: {
                    inner: this.$t('wards.update_ward')
                },
            }
        }
    }
</script>

<style lang="scss" scoped>
    .page-container {
        main {
            @apply px-0;

            .form-area {
                @apply flex flex-row flex-wrap px-9;

                .form {
                    @apply mb-8;

                    width: calc(50% - 1rem);

                    &:nth-child(odd) {
                        @apply mr-4;
                    }

                    &:nth-child(even) {
                        @apply ml-4;
                    }

                    .form-body {
                        @apply px-8 py-7;

                        .timeslot-row-group {
                          .input-group {
                            @apply w-auto flex-1;
                          }

                          .button-remove {
                            @apply flex-none self-start mt-9;
                          }
                        }
                    }

                    .row-add-container {
                      @apply px-8 pb-8 mt-auto;
                    }
                }
            }

            .divider {
                @apply w-full border-b-2 border-primary;
            }

            .table-area {
                @apply px-9 py-7;

                header {
                  @apply flex flex-row items-center mb-6;

                  h3 {
                    @apply text-black font-bold text-xl;
                  }

                    .actions{
                        @apply ml-auto;

                        & > * {
                            @apply mr-4;

                            &:last-child {
                                @apply mr-0;
                            }
                        }
                    }
                }
            }
        }
    }
</style>