import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import {i18n} from './locale/i18n';
import './css/app.scss';
import PrettyCheck from 'pretty-checkbox-vue/check';
import PrettyRadio from 'pretty-checkbox-vue/radio';
import VueHead from 'vue-head';
import Notifications from 'vue-notification';
import Vuelidate from 'vuelidate';
import Multiselect from 'vue-multiselect';
import VueGoodTablePlugin from 'vue-good-table';
import VModal from 'vue-js-modal';
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import VueTippy, {TippyComponent} from "vue-tippy";
import VCalendar from 'v-calendar';

Vue.prototype.$moment = require('moment-timezone');
Vue.prototype.$axios = require('axios');

Vue.prototype.$axios.defaults.headers['Content-Type'] = 'application/json';
Vue.prototype.$axios.defaults.baseURL = process.env.VUE_APP_API_URL;
Vue.prototype.$axios.interceptors.request.use((config) => {
    if (store.getters.token)
        config.headers.common['Authorization'] = 'Bearer ' + store.getters.token.bearer;

    return config;
});

Vue.prototype.$axios.interceptors.response.use((res) => res, (error) => {
    if (error && error.response && error.response.status && error.response.status === 401) {
        store.dispatch('logout');
        router.push({name: 'login'});
    }

    return Promise.reject(error);
});

Vue.prototype.$larerror = (error, fallback = 'error') => {
    if (!error)
        return fallback;

    if (error.constructor === Object) {
        return error[Object.keys(error)[0]][0];
    } else if (error.errors && error.errors.constructor === Object)
        return error.errors[Object.keys(error.errors)[0]][0];
    else if (error.errors && error.errors.constructor === Array)
        return error.errors[0];

    if (error.message && error.message.constructor === String && error.message.length)
        return error.message;

    return error || fallback;
};

Vue.config.productionTip = false;

Vue.component('p-check', PrettyCheck);
Vue.component('p-radio', PrettyRadio);
Vue.use(VueHead);
Vue.use(Notifications);
Vue.use(Vuelidate);
Vue.component('multiselect', Multiselect);

import 'vue-good-table/dist/vue-good-table.css';

Vue.use(VueGoodTablePlugin);
Vue.use(VModal, {dynamic: true, injectModalsContainer: true});

Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker);
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';

Vue.use(VueTippy);
Vue.component("tippy", TippyComponent);

import "vue-multiselect/dist/vue-multiselect.min.css";

import {library} from '@fortawesome/fontawesome-svg-core';
import {} from '@fortawesome/free-solid-svg-icons';
import {
    faEye as farEye,
    faEyeSlash as farEyeSlash,
} from '@fortawesome/free-regular-svg-icons';
import {
    faAngleDown as farAngleDown, faSearch as farSearch, faTimes as farTimes, faUserCheck as farUserCheck, faUserSlash as farUserSlash, faCheck as farCheck,
} from '@fortawesome/pro-regular-svg-icons';
import {} from '@fortawesome/pro-solid-svg-icons';
import {
    faUsersMedical as falUsersMedical,
    faBuilding as falBuilding,
    faClipboardList as falClipboardList,
    faUserFriends as falUserFriends,
    faFileChartLine as falFileChartLine,
    faPencil as falPencil,
    faTrash as falTrash,
    faEye as falEye,
    faFilePdf as falFilePdf,
    faFileExcel as falFileExcel,
    faUser as falUser,
    faKey as falKey,
} from '@fortawesome/pro-light-svg-icons';
import {} from '@fortawesome/free-brands-svg-icons';
import {FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText} from '@fortawesome/vue-fontawesome';

library.add(farAngleDown, falUserFriends, falBuilding, falFileChartLine, falClipboardList, farEye, farEyeSlash,
    farSearch, falPencil, falTrash, farTimes, falFilePdf, falFileExcel, falUsersMedical, falEye, farUserCheck,
    farUserSlash, farCheck, falUser, falKey);

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('font-awesome-layers', FontAwesomeLayers);
Vue.component('font-awesome-layers-text', FontAwesomeLayersText);

Vue.use(VCalendar);

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')
