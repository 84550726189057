export default {
    nav: {
        patients: 'Patients',
        visitors: 'Visitors',
        wards: 'Wards',
        users: 'Users',
        reports: 'Reports',
        bookings: 'Bookings',
        login: 'Login',
        logout: 'Logout',
        roles: 'Roles & Permissions',
        roles_permissions: 'Roles & Permissions',
    },
    patients: {
        patients: 'patients',
        actions: 'Actions',
        first_name: 'First Name',
        last_name: 'Last Name',
        id_number: 'ID Number',
        date_of_birth: 'D.O.B',
        age: 'Age',
        n_years: '{n} year | {n} years',
        gender: 'Gender',
        locality: 'Locality',
        next_of_kin: 'Next of Kin',
        next_of_kin_details: 'Next of Kin Details',
        select_gender: 'Select gender',
        name: 'Full Name',
        phone: 'Phone',
        associated_visitors: 'Associated Visitors',
        visitor_id: 'Visitor ID',
        contact_number: 'Contact Number',
        building: 'Building',
        street: 'Street',
        city: 'City',
        country: 'Country',
        post_code: 'Post Code',
        third_party: 'Third Party',
        ward: 'Ward',
        admitted_only: 'Admitted Only',
        discharged_only: 'Discharged Only',
        deceased_only: 'Deceased Only',
        admitted_on: 'Admitted On',
        date_of_admission: 'Date of Admission',
        date_of_admission_short: 'Date of Adm',
        discharged_on: 'Discharged on',
        discharged: 'Discharged',
        deceased_on: 'Deceased on',
        deceased: 'Deceased',
        died_at_mdh: 'Died at MDH',
        admitted_from: 'Admitted From',
        export_pdf:'Export PDF',
        export_csv:'Export CSV',
        male: 'Male',
        female: 'Female',
        max_daily_bookings: 'Max Daily Bookings',
        max_weekly_bookings: 'Max Weekly Bookings',
        add_next_of_kin: 'Add Next of Kin',
        media: 'Media',
        media_permission: 'Media Permission',
        add_patient: 'Add Patient',
        update_patient: 'Update Patient',
        search_patients: 'Search patients',
        discharge_patient: 'Discharge patient',
        decease_patient: 'Decease patient',
        admit_patient: 'Admit patient',
        revive_patient: 'Revive patient',
        patient_details: 'Patient Details',
        other_details: 'Other Details',
        prompt_delete: 'Are you sure sure you want to delete?',
        prompt_delete_subtext: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed massa metus, ornare nec dui aliquam, dapibus dictum leo.',
        error_retrieve: 'Failed to retrieve patients',
        error_create: 'Failed to create patient',
        error_update: 'Failed to update patient',
        error_delete: 'Failed to delete patient',
        error_discharged: 'Failed to discharge patient',
        error_deceased: 'Failed to decease patient',
        success_created: 'Patient has been created',
        success_updated: 'Patient has been updated',
        error_retrieve_fields: 'Failed to retrieve fields',
        select_status: 'Select status'
    },
    wards: {
        wards: 'wards',
        ward_details: 'Ward Details',
        update_ward: 'Update Ward',
        id: 'ID',
        name: 'Name',
        capacity: 'Capacity',
        max_parallel_bookings: 'Max Parallel Bookings',
        patients_in_ward: 'Patients in ward',
        opens_at: 'Opens At',
        closes_at: 'Closes At',
        occupied: 'Occupied',
        available: 'Available',
        actions: 'Actions',
        time_slots: 'Time Slots',
        close_ward: 'Close Ward',
        open_ward: 'Open Ward',
        add_ward: 'Add Ward',
        select_ward: 'Select ward',
        search_wards: 'Search wards',
        availability: 'Availability',
        add_timeslot: 'Add Timeslot',
        update_max_daily_bookings: 'Update Max Daily Bookings',
        update_max_weekly_bookings: 'Update Max Weekly Bookings',
        prompt_delete: 'Are you sure sure you want to delete?',
        prompt_delete_subtext: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed massa metus, ornare nec dui aliquam, dapibus dictum leo.',
        error_retrieve: 'Failed to retrieve wards',
        error_create: 'Failed to create ward',
        error_update: 'Failed to update ward',
        error_update_max_daily_bookings: 'Failed to update Max Daily Bookings',
        error_update_max_weekly_bookings: 'Failed to update Max Weekly Bookings',
        error_delete: 'Failed to delete ward',
        success_created: 'Ward has been created',
        success_updated: 'Ward has been updated',
        success_max_daily_bookings_updated: 'Max Daily Bookings has been updated',
        error_closed: 'This ward is currently closed',
    },
    bookings: {
        bookings: 'bookings',
        id: 'ID',
        id_number: 'ID Number',
        phone: 'Phone',
        name: 'Name',
        full_name: 'Full Name',
        date: 'Date',
        time_from: 'Time From',
        time_to: 'Time To',
        ward: 'Ward',
        first_name: 'First Name',
        last_name: 'Last Name',
        time: 'Time',
        timeslot: 'Timeslot',
        associated_visitor: 'Associated Visitor',
        registered_visitors: 'Registered Visitors',
        x_already_in_registered_visitors: '{x} is already in the registered visitors list',
        user_with_id_x_already_exists: 'A user with id {x} already exists',
        visitor_x_name: 'Visitor {x} Name',
        visitor_x_id: 'Visitor {x} ID',
        actions: 'Actions',
        visitors: 'Visitor',
        add_visitor: 'Add Visitor',
        remove_visitor: 'Remove Visitor',
        add_booking: 'Add Booking',
        add_bulk_booking: 'Add Bulk Booking',
        edit_booking: 'Edit Booking',
        search_bookings: 'Search bookings',
        check_bookings: 'Check Bookings',
        booking_details: 'Booking Details',
        visitor_details: 'Visitor Details',
        upcoming_bookings: 'Upcoming Bookings',
        todays_bookings: 'Today\'s Bookings',
        past_bookings: 'Past Bookings',
        patient: 'Patient',
        from: 'From',
        to: 'To',
        patient_id: 'Patient ID',
        user_id: 'User ID',
        prompt_delete: 'Are you sure sure you want to delete?',
        prompt_delete_subtext: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed massa metus, ornare nec dui aliquam, dapibus dictum leo.',
        mark_visitor_absent: 'Mark visitor as not present for booking',
        mark_visitor_present: 'Mark visitor as present for booking',
        booking_visitors_title: 'Booking {x} - Visitors',
        visitor_index_fullname: 'Visitor {x} - Full Name',
        contact_number: 'Contact Number',
        visited: 'Visited',
        visitors_updated: 'Visitors have been updated',
        visitors_details:'Visitor\'s Details',
        unavailabilities: 'Unavailabilities',
        no_unavailabilities: 'No unavailabilities',
        create_bulk_booking: 'Create Bulk Booking',
        success_created: 'Booking has been created',
        success_updated: 'Booking has been updated',
        error_create: 'Failed to create booking',
        error_update: 'Failed to update booking',
        error_delete: 'Failed to delete booking',
        error_retrieve: 'Failed to retrieve bookings',
        error_retrieve_calendar: 'Failed to retrieve patient calendar',
        error_retrieve_timeslots: 'Failed to retrieve timeslots',
        error_present: 'Failed to update present status',
        error_check_bookings: 'Failed to check bookings',
        error_create_bulk_booking: 'Failed to create bulk booking',
        success_create_bulk_booking: 'Bulk booking has been created!'
    },
    audits: {
        audits: 'Audits',
        user: 'User',
        event: 'Event',
        date_n_time: 'Date & Time',
        values: 'Values',
        old_values:'Old Values',
        new_values:'New Values',
        updated: 'Updated',
        created: 'Created',
        error_retrieve_audits: 'Failed to retrieve audits',
    },
    visitors: {
        visitors: 'visitors',
        actions: 'Actions',
        name: 'Name',
        first_name: 'First Name',
        last_name: 'Last Name',
        id: 'ID',
        id_number: 'ID Number',
        phone: 'Phone',
        visitor_id: 'Visitor ID',
        add_visitor: 'Add Visitor',
        update_visitor: 'Update Visitor',
        search_visitors: 'Search visitors',
        discharge_visitor: 'Discharge visitor',
        decease_visitor: 'Decease visitor',
        admit_visitor: 'Admit visitor',
        revive_visitor: 'Revive visitor',
        visitor_details: 'Visitor Details',
        other_details: 'Other Details',
        prompt_delete: 'Are you sure sure you want to delete?',
        error_retrieve: 'Failed to retrieve visitors',
        error_create: 'Failed to create visitor',
        error_update: 'Failed to update visitor',
        error_delete: 'Failed to delete visitor',
        success_created: 'Visitor has been created',
        success_updated: 'Visitor has been updated',
        error_retrieve_fields: 'Failed to retrieve fields',
    },
    users: {
        users: 'users',
        add_user: 'Add User',
        edit_user: 'Edit User',
        search_users: 'Search users',
        name: 'Name',
        email: 'Email',
        password: 'Password',
        confirm_password: 'Confirm password',
        role: 'Role',
        roles: 'Roles',
        actions: 'Actions',
        prompt_delete: 'Are you sure sure you want to delete?',
        prompt_delete_subtext: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed massa metus, ornare nec dui aliquam, dapibus dictum leo.',
        success_created: 'User has been created',
        success_updated: 'User has been updated',
        error_create: 'Failed to create user',
        error_update: 'Failed to update user',
        error_delete: 'Failed to delete user',
        error_retrieve: 'Failed to retrieve users',
    },
    roles: {
        roles: 'roles',
        permissions: 'Permissions',
        permission: 'Permission',
        actions: 'Actions',
        name: 'Name',
        add_role: 'Add Role',
        edit_role: 'Edit Role',
        search_roles: 'Search Roles',
        prompt_delete: 'Are you sure you want to delete this role?',
        prompt_delete_subtext: '',
        error_retrieve: 'Failed to retrieve roles',
        error_create: 'Failed to create role',
        error_update: 'Failed to update role',
        error_delete: 'Failed to delete role',
        success_created: 'Role has been created',
        success_updated: 'Role has been updated',
        error_retrieve_fields: 'Failed to retrieve fields',
        role_details: 'Role Details',
        delete_role: 'Delete Role',
    },
    reports: {
        date_from: 'Date From',
        date_to: 'Date To',
        bookings: 'Bookings',
        visitors: 'Visitors',
        attended: 'Attended',
        didnt_attend: 'Didn\'t Attend',
        ward_bookings: 'Ward Bookings',
        patients_data: 'Patients Data',
        export_report: 'Export report',
        select_date_and_ward: 'Please select a date and ward',
        select_fields: 'Select fields',
        select_date: 'Please select a date',
        select_ward: 'Please select a ward',
        select_visitors_attendance: 'Please select visitors attendance type',
        no_data_found: 'No data found',
        error_generate: 'Failed to generate report',
        error_fields: 'Please select fields'
    },
    permissions: {
        permission: 'permission',
        permissions: 'permissions',
        error_retrieve: 'Failed to retrieve permissions',
        error_save: 'Failed to save permission',
        details_saved: 'permission details have been saved',
        saved: 'permission has been saved',
    },
    prompts: {
        are_you_sure: 'are you sure?',
        do_you_want_to_delete_this_x: 'do you want to delete this {x}?',
    },
    validation: {
        x_is_required: '{x} is required',
        x_are_required: '{x} are required',
        please_enter_x: 'please enter {x}',
        please_enter_longer_x: 'please enter a longer {x}',
        please_enter_shorter_x: 'please enter a shorter {x}',
        please_enter_valid_x: 'please enter a valid {x}',
        please_choose_x: 'please choose {x}',
        please_enter_number: 'please enter a number',
        please_enter_number_between_x: 'please enter a number between {x} and {y}',
        please_enter_url: 'please enter a URL',
    },
    auth: {
        unauthorized: 'Unauthorized',
        unauthenticated: 'Unauthenticated',
        forgot_password_q: 'Forgot password?',
        email: 'Email',
        password: 'Password',
        password_is_required: 'Password is required',
        email_is_required: 'Email is required',
        enter_valid_email: 'Enter a valid email',
        failed_login: 'Login Failed',
    },
    localities: {
        select_locality: 'Select locality',
        error_retrieve: 'Failed to retrieve localities',
    },
    start: 'Start',
    finish: 'Finish',
    add: 'Add',
    save: 'Save',
    search: 'Search',
    select: 'Select',
    page_not_found: 'Page not found',
    x_per_page: '{x} per page',
    error: 'Error',
    yes: 'Yes',
    no: 'No',
    accepted: 'Accepted',
    not_accepted: 'Not Accepted',
    warning: 'Warning',
}
