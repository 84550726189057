<template>
    <nav class="menubar-container">
        <img src="../assets/logo.png" class="logo" >

        <div v-if="$store.getters.user" class="user-wrapper">
            <div class="user">
                <div class="avatar">
                    <avatar background-color="#FF8F47" :size="30"
                            :username="$store.getters.user.attributes.name"></avatar>
                </div>
                <p>{{$store.getters.user.attributes.name}}</p>
                <font-awesome-icon :icon="['far', 'angle-down']"/>
            </div>
            <nav>
                <ul class="menu">
                    <li>
                        <a @click="logout">{{$t('nav.logout')}}</a>
                    </li>
                </ul>
            </nav>
        </div>
    </nav>
</template>

<script>
    import Avatar from 'vue-avatar'

    export default {
        name: "Menubar",
        components: {Avatar},
        methods: {
            async logout() {
                await this.$store.dispatch('logout');
                this.$router.push({name: 'login'});
            }
        }
    }
</script>

<style lang="scss" scoped>
    .menubar-container {
        @apply flex flex-row justify-between w-full bg-black h-14 text-white;

        grid-area: menubar;

        img.logo{
          @apply h-full w-auto py-2 ml-4;
        }

        .user-wrapper {
            @apply py-4 cursor-pointer mr-8;

            .user {
                @apply mx-4 flex flex-row items-center h-9 pb-4;

                .avatar {
                    @apply mr-3;
                }

                p {
                    @apply max-w-xs truncate text-sm;
                }

                svg {
                    @apply ml-3 text-2xl;
                }
            }

            nav {
                @apply relative hidden bg-black;
                z-index: 999;

                .menu {
                    @apply p-0 list-none;

                    li {
                        a {
                            @apply flex text-white cursor-pointer py-4 px-6 text-sm;
                        }
                    }
                }
            }

            &:hover, &:active, &:focus {
                nav {
                    @apply block;
                }
            }
        }
    }
</style>