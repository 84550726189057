<template>
    <div class="page-container">
        <Headbar>
            <template v-slot:left>
                <router-link :to="{name: 'patients-create'}">
                    <Button v-if="$store.getters.hasPermission('store patients')" className="--primary --small">
                        {{$t('patients.add_patient')}}
                    </Button>
                </router-link>
            </template>
            <template v-slot:right>
                <div class="export-container"
                     v-if="$store.getters.hasAnyRole(['medical_records', 'customer_care', 'management', 'administrator'])">
                  <Button className="--primary --outline --mini" :content="$t('patients.export_pdf')"
                          v-tippy="{ placement : 'bottom',  arrow: true }" :onclick="() => generateReport('pdf')"
                          :loading="is_generating_report">
                    <font-awesome-icon :icon="['fal', 'file-pdf']"/>
                  </Button>
                  <Button className="--primary --outline --mini" :content="$t('patients.export_csv')"
                          v-tippy="{ placement : 'bottom',  arrow: true }" :onclick="() => generateReport('excel')"
                          :loading="is_generating_report">
                    <font-awesome-icon :icon="['fal', 'file-excel']"/>
                  </Button>
                </div>
                <Select v-model="filter_gender" :options="filter_gender_options" :placeholder="$t('patients.select_gender')"
                        label="name" track-by="value"
                        className="filter" @updated="filterUpdated"/>
                <Select v-model="filter_status" :options="filter_status_options" :allowEmpty="false"
                        className="filter" @updated="filterUpdated" label="label" track-by="value"/>
                <Select v-model="filter_ward" :options="filter_ward_options" :placeholder="$t('wards.select_ward')"
                        :custom-label="(row) => row.attributes.name" track-by="id"
                        className="filter" @updated="filterUpdated"/>

                <Search :placeholder="$t('patients.search_patients')" @search="search"/>
            </template>
        </Headbar>
        <main>
            <vue-good-table
                    mode="remote"
                    styleClass="vgt-table striped vgt-custom"
                    :columns="columns"
                    :rows="patients"
                    :isLoading.sync="is_loading_patients"
                    :search-options="{
                enabled: false,
            }"
                    :pagination-options="{
                enabled: true,
                mode: 'records',
                dropdownAllowAll: false,
                perPage: 15,
                perPageDropdownEnabled: false,
                rowsPerPageLabel: $t('x_per_page', {x: $t('patients.patients')}),
            }"
                    :sort-options="{
                      enabled: true,
                      multipleColumns: true,
                    }"
                    :totalRows="totalRecords"
                    @on-page-change="onPageChange"
                    @on-sort-change="onSortChange">
                <template slot="table-row" slot-scope="props">
                    <div v-if="props.column.field === 'gender'" class="td-gender">
                        <p v-if="props.row.attributes.gender.toLowerCase() === 'male'" class="male">
                            M
                        </p>
                        <p v-else-if="props.row.attributes.gender.toLowerCase() === 'female'" class="female">
                            F
                        </p>
                    </div>
                    <div v-else-if="props.column.field === 'third_party'" class="td-third-party">
                        <p v-if="['yes','true','accepted'].includes(props.row.attributes.third_party.toLowerCase())"
                           class="accepted">
                            {{$t('accepted')}}
                        </p>
                        <p v-else class="not-accepted">
                            {{$t('not_accepted')}}
                        </p>
                    </div>
                    <div v-else-if="props.column.field === 'media'" class="td-media">
                        <p :class="{yes: props.row.attributes.media_permission, no: !props.row.attributes.media_permission}">
                            {{props.row.attributes.media_permission ? $t('yes') : $t('no')}}
                        </p>
                    </div>
                    <div v-else-if="props.column.field === 'after'" class="td-after">
                        <router-link :to="{name: 'patients-update', params: {id: props.row.id}}">
                            <Button v-if="$store.getters.hasAnyPermission(['read patients', 'update patients'])"
                                    className="--secondary --outline --mini --big-text">
                                <font-awesome-icon :icon="['fal', 'eye']"/>
                            </Button>
                        </router-link>
                        <Button v-if="$store.getters.hasPermission('destroy patients')"
                                className="--secondary --outline --mini --big-text"
                                :onclick="()=>toggleDelete(props.row)">
                            <font-awesome-icon :icon="['fal', 'trash']"/>
                        </Button>
                    </div>
                    <span v-else>
                      {{props.formattedRow[props.column.field]}}
                    </span>
                </template>
            </vue-good-table>
        </main>
    </div>
</template>

<script>
import Headbar from "../../components/headbar/Headbar";
import Search from "../../components/Search";
import Button from "../../components/Button";
import Select from "../../components/form/Select";
import ConfirmModal from "../../components/modal/ConfirmModal";

export default {
        name: "patients-index-page",
        components: {ConfirmModal, Select, Button, Search, Headbar},
        data: function () {
            const columns = [
                {
                    label: this.$t('patients.id_number'),
                    field: 'attributes.id_number',
                    sortable: false,
                },
                {
                    label: this.$t('patients.ward'),
                    field: 'attributes.ward_name',
                    sortable: false,
                },
                {
                    label: this.$t('patients.first_name'),
                    field: 'attributes.first_name',
                    sortable: true,
                },
                {
                  label: this.$t('patients.last_name'),
                  field: 'attributes.last_name',
                  sortable: true,
                },
                {
                    label: this.$t('patients.date_of_birth'),
                    field: 'attributes.date_of_birth',
                    sortable: true,
                },
                {
                  label: this.$t('patients.age'),
                  field: row => {
                    const years = this.$moment().diff(row.attributes.date_of_birth, 'years');
                    return this.$tc('patients.n_years', years, {n: years})
                  },
                  sortable: false,
                },
                {
                    label: this.$t('patients.gender'),
                    field: 'gender',
                    sortable: false,
                },
                {
                    label: this.$t('patients.third_party'),
                    field: 'third_party',
                    sortable: false,
                },
                {
                    label: this.$t('patients.media_permission'),
                    field: 'media',
                    sortable: false,
                },
            ];

            if (this.$store.getters.hasAnyPermission(['read patients', 'update patients', 'destroy patients']))
                columns.push({
                    label: this.$t('patients.actions'),
                    field: 'after',
                    tdClass: 'td-after',
                    sortable: false,
                });

            return {
                columns: columns,
                patients: [],
                is_loading_patients: false,
                totalRecords: null,
                serverParams: {
                    sorting:[],
                    filters: [
                        {
                            filter_by: 'discharged_on',
                            filter_operator: '=',
                            filter_value: null
                        },
                        {
                          filter_by: 'deceased_on',
                          filter_operator: '=',
                          filter_value: null
                        }
                    ]
                },
                searchTerm: null,
                filter_ward: null,
                filter_ward_options: [],
                filter_status: null,
                filter_status_options: [
                    {
                        label: this.$t('patients.admitted_only'),
                        value: 'admitted',
                    },
                    {
                        label: this.$t('patients.discharged_only'),
                        value: 'discharged',
                    },
                    {
                      label: this.$t('patients.deceased_only'),
                      value: 'deceased',
                    }
                ],
                filter_gender: null,
                filter_gender_options: [
                  {value: 'male', name: this.$t('patients.male')},
                  {value: 'female', name: this.$t('patients.female')},
                ],
                is_loading_filter_ward_options: false,
                is_deleting: false,
                is_generating_report:false,
            }
        },
        methods: {
            async generateReport(fileType = 'pdf') {
              this.is_generating_report = true;
              // let fields = [];
              //
              // // get fields
              // await this.$axios.get('patients/list-patient-fields')
              //     .then(({data}) => {
              //       fields = data;
              //     })
              //     .catch(e => {
              //       this.is_generating_report = false;
              //
              //       this.$notify({
              //         title: this.$t('error'),
              //         text: this.$larerror(e.response.data.errors, this.$t('reports.error_generate')),
              //         type: 'error',
              //       });
              //     });
              //
              // if (fields.length === 0) {
              //   this.$notify({
              //     text: this.$t('reports.error_fields'),
              //     type: 'error',
              //   });
              //
              //   return;
              // }
              let acceptHeader = null;
              if (fileType === 'pdf') {
                acceptHeader = 'application/pdf';
              } else if (fileType === 'excel') {
                acceptHeader = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
              }

              const encodedSorting = this.serverParams.sorting.map(f=>btoa(JSON.stringify(f)));
              const encodedFilters = this.serverParams.filters.map(f=>btoa(JSON.stringify(f)));

              await this.$axios.get(`patients/export`, {
                params: {
                  filters: encodedFilters,
                  sorting: encodedSorting,
                  file_type: fileType
                },
                headers: {
                  'Accept': acceptHeader
                },
                responseType:
                  'arraybuffer'
                  }).then(({data}) => {
                  this.blobToFile(data, 'Patient Data', acceptHeader);
                  this.is_generating_report = false;
                }).catch(e => {
                  this.is_generating_report = false;

                  this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('reports.error_generate')),
                    type: 'error',
                });
              });
            },
            blobToFile(data, filename, acceptHeader) {
              const blob = new Blob([data], {type: acceptHeader});
              const url = window.URL.createObjectURL(blob);

              const tempLink = document.createElement('a');
              tempLink.style.display = 'none';
              tempLink.href = url;
              tempLink.setAttribute('download', filename);
              if (typeof tempLink.download === 'undefined') {
                tempLink.setAttribute('target', '_blank');
              }

              document.body.appendChild(tempLink);
              tempLink.click();
              document.body.removeChild(tempLink);
            },
            toggleDelete(patient) {
                this.$modal.show(
                    ConfirmModal, {
                        title: this.$t('warning'),
                        message: this.$t('patients.prompt_delete'),
                        confirmText: this.$t('yes'),
                        cancelText: this.$t('no'),
                        confirmClass: '--negative',
                        cancelClass: '--primary --outline'
                    },
                    {
                        name: 'confirm-modal',
                        adaptive: true,
                        resizable: true,
                        height: 'auto',
                        scrollable: true,
                        classes: 'modal',
                    }, {
                        'before-close': (e) => {
                            if (e.params === true) {
                                this.is_deleting = true;
                                this.$axios.delete(`patients/${patient.id}`)
                                    .then(({data}) => {
                                        this.is_deleting = false;
                                        this.retrievePatients();
                                    })
                                    .catch(e => {
                                        this.is_deleting = false;

                                        this.$notify({
                                            title: this.$t('error'),
                                            text: this.$larerror(e.response.data, this.$t('patients.error_delete')),
                                            type: 'error',
                                        });
                                    });
                            }
                        }
                    }
                );
            },
            filterUpdated() {
                const filters = [];

                // status
                if (this.filter_status.value === 'admitted') {
                    filters.push({
                        filter_by: 'discharged_on',
                        filter_operator: '=',
                        filter_value: null
                    },
                    {
                      filter_by: 'deceased_on',
                      filter_operator: '=',
                      filter_value: null
                    })
                } else if (this.filter_status.value === 'discharged') {
                    filters.push({
                        filter_by: 'discharged_on',
                        filter_operator: '!=',
                        filter_value: null
                    },
                    {
                      filter_by: 'deceased_on',
                      filter_operator: '=',
                      filter_value: null
                    })
                } else if (this.filter_status.value === 'deceased') {
                  filters.push({
                    filter_by: 'deceased_on',
                    filter_operator: '!=',
                    filter_value: null
                  })
                }

                // ward
                if (this.filter_ward) {
                    filters.push({filter_by: 'ward_id', filter_value: this.filter_ward.id});
                }

                // gender
                if (this.filter_gender) {
                  filters.push({filter_by: 'gender', filter_value: this.filter_gender.value});
                }

                if (filters.length)
                    this.updateParams({
                        filters: filters
                    });
                else this.removeParam('filters');

                this.retrievePatients();
            },
            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },
            onPageChange(params) {
                this.updateParams({page: params.currentPage});
                this.retrievePatients();
            },
            onSortChange(params) {
                const sorts = [];

                if (!params || !params.length) {
                  this.updateParams({sorting: []});
                  return this.retrievePatients();
                }

                params.forEach(p => {
                  if(!p.type || p.type === 'none')
                    return;

                  let sort_by = null;
                  let sort_order = p.type;
                  if (p.field === 'attributes.first_name')
                    sort_by = 'first_name';
                  else if (p.field === 'attributes.last_name')
                    sort_by = 'last_name';
                  else if (p.field === 'attributes.date_of_birth')
                    sort_by = 'date_of_birth';
                  else
                    sort_by = p.field.split('.')[1];

                  sorts.push({sort_order, sort_by})
                });

                this.updateParams({sorting: sorts});
                this.retrievePatients();
            },
            removeParam(param) {
                this.$delete(this.serverParams, param);
            },
            search(searchTerm) {
                this.searchTerm = searchTerm;

                if (searchTerm && searchTerm.length)
                    this.updateParams({term: searchTerm});
                else this.removeParam('term');

                this.retrievePatients();
            },
            retrieveFilterWardOptions() {
                this.is_loading_filter_ward_options = false;
                this.$axios.get('wards/list')
                    .then(({data}) => {
                        this.is_loading_filter_ward_options = false;
                        this.filter_ward_options = data.data;
                    })
                    .catch(e => {
                        this.is_loading_filter_ward_options = false;

                        this.$notify({
                            title: this.$t('error'),
                            text: this.$larerror(e.response.data, this.$t('wards.error_retrieve')),
                            type: 'error',
                        });
                    });
            },
            retrievePatients() {
                this.is_loading_patients = true;

                const encodedFilters = this.serverParams.filters.map(f=>btoa(JSON.stringify(f)));
                const encodedSorting = this.serverParams.sorting.map(f=>btoa(JSON.stringify(f)));
                const term = this.serverParams.term;

                this.$axios.get('patients', {params: {page: this.serverParams.page ?? 1, term, sorting: encodedSorting, filters: encodedFilters}})
                    .then(({data}) => {
                        this.patients = data.data;
                        this.totalRecords = data.meta.total;
                        this.is_loading_patients = false;
                    })
                    .catch(e => {
                        this.is_loading_patients = false;

                        this.$notify({
                            title: this.$t('error'),
                            text: this.$larerror(e.response.data, this.$t('patients.error_retrieve')),
                            type: 'error',
                        });
                    });
            }
        },

        mounted() {
            this.retrievePatients();
            this.retrieveFilterWardOptions();
            this.filter_status = this.filter_status_options[0];
        },

        head() {
            return {
                title: {
                    inner: this.$t('nav.patients')
                },
            }
        }
    }
</script>

<style lang="scss" scoped>
    .page-container {
        .export-container{
          @apply flex flex-row items-center gap-x-2;

          svg{
            @apply text-xl;
          }
        }

        .td-after {
            @apply flex flex-row;

            & > * {
                @apply mr-3;

                &:last-child {
                    @apply mr-0;
                }
            }
        }

        .td-gender {
            p {
                &.male {
                    @apply text-male;
                }

                &.female {
                    @apply text-female;
                }
            }
        }

        .td-third-party {
            p {
                &.accepted {
                    @apply text-positive;
                }

                &.not-accepted {
                    @apply text-negative;
                }
            }
        }

      .td-media {
        p {
          &.yes {
            @apply text-positive;
          }

          &.no {
            @apply text-negative;
          }
        }
      }
    }
</style>
