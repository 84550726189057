<template>
    <div class="booking-visitors-modal">
        <ModalContainer :title="$t('bookings.booking_visitors_title', {x:booking_id})" identifier="booking-visitors-modal" :closable="true">
            <div class="loading-placeholder" v-if="is_loading_original"></div>
            <div v-if="!is_loading_original">
                <div class="visitor-container" v-for="(visitor, index) in visitors">
                    <div class="section-container">
                        <p class="header-text">{{ $t('bookings.visitor_index_fullname', {x: index + 1}) }}</p>
                        <p class="value-text">{{ visitor.attributes.name }}</p>
                    </div>
                    <div class="section-container">
                        <p class="header-text">{{ $t('bookings.id') }}</p>
                        <p class="value-text">{{ visitor.attributes.id_number }}</p>
                    </div>

                    <div class="section-container">
                        <p class="header-text">{{ $t('bookings.contact_number') }}</p>
                        <p class="value-text">{{ visitor.attributes.phone }}</p>
                    </div>

                    <div class="section-container check">
                        <p class="header-text">{{ $t('bookings.visited') }}</p>
                        <div class="check-container">
                            <FormInputCheck class="p-visitors" v-model="visitor.attributes.has_visited_boolean" @change="compareVisitors"/>
                        </div>
                    </div>
                </div>
                <Button className="--primary" :class="{spinner: is_saving}" :onclick="save"
                        :disabled="is_visitors_same">
                    {{ $t('save') }}
                </Button>
            </div>
        </ModalContainer>
    </div>
</template>

<script>
import ModalContainer from "../modal/ModalContainer";
import Form from "../form/Form";
import FormInputText from "../form/FormInputText";
import FormGroupTwo from "../form/FormGroupTwo";
import FormInputSelect from "../form/FormInputSelect";
import Button from "../Button";
import FormInputCheck from "../form/FormInputCheck";
import _ from 'lodash';

export default {
    name: "BookingVisitorsModal",
    components: {FormInputCheck, Button, FormInputSelect, FormGroupTwo, FormInputText, Form, ModalContainer},
    data() {
        return {
            original: null,
            visitors: null,
            originalVisitors: null,
            is_saving: false,
            is_loading_original: false,
            is_visitors_same: true,
        }
    },
    validations: {},

    props: {
        booking_id: {
            type: Number,
            required: true,
            default: null,
        }
    },

    methods: {
        close(status) {
            this.$modal.hide('booking-visitors-modal', status);
        },

        compareVisitors() {
            if (JSON.stringify(this.originalVisitors) === JSON.stringify(this.visitors)) this.is_visitors_same = true
            else this.is_visitors_same = false
        },

        async retrieveOriginalBooking() {
            this.is_loading_original = true;
            await this.$axios.get(`bookings/${this.booking_id}`)
                .then(({data}) => {
                    this.is_loading_original = false;
                    this.original = data.data;
                    this.visitors = data.data.relationships.visitors.data
                })
                .catch(e => {
                    this.is_loading_original = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('bookings.error_retrieve')),
                        type: 'error',
                    });
                });
        },

        async populate() {
            this.is_loading_original = true;
            this.visitors.forEach(visitor => {
                if (visitor.attributes.has_visited === 0) visitor.attributes.has_visited_boolean = false
                else if (visitor.attributes.has_visited === 1) visitor.attributes.has_visited_boolean = true
            })
            this.originalVisitors = JSON.parse(JSON.stringify(this.visitors));
            this.is_loading_original = false;
        },

        async save() {
            this.is_saving = true;
            for (const visitor of this.visitors) {
                let visitorCompare = _.find(this.originalVisitors, {id: visitor.id})
                if (visitor.attributes.has_visited_boolean === false) visitor.attributes.has_visited = 0
                else if (visitor.attributes.has_visited_boolean === true) visitor.attributes.has_visited = 1

                if (!(visitor.attributes.has_visited === visitorCompare.attributes.has_visited)) {
                    await this.$axios.put(`bookings/${this.booking_id}/visitors/${visitor.id}`)
                        .then(({data}) => {
                        })
                        .catch(e => {
                            this.$notify({
                                title: this.$t('error'),
                                text: this.$larerror(e.response.data, this.$t('bookings.error_present')),
                                type: 'error',
                            });
                        });
                }
            }

            this.$notify({
                text: this.$t('bookings.visitors_updated'),
                type: 'success',
            });

            this.is_saving = false;
            this.close(true)
        },
    },

    async mounted() {
        await this.retrieveOriginalBooking();
        await this.populate();
    }
}
</script>

<style lang="scss" scoped>
.loading-placeholder {
    @apply h-64;
}
.visitor-container {
    @apply flex flex-row justify-evenly border-b border-grey;

    &:last-of-type {
        @apply border-b-0 mb-8;
    }

    .section-container {
        @apply flex flex-col px-4 py-4;

        &.check {
            @apply items-center;

            .check-container {
                @apply mt-4;
            }
        }

        .header-text {
            @apply font-bold mt-2;
        }

        .value-text {
            @apply text-sm mt-4;
        }
    }
}

</style>