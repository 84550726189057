<template>
    <div class="headbar-container">
        <div class="left">
            <slot name="left"></slot>
        </div>
        <div class="right">
            <slot name="right"></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Headbar"
    }
</script>

<style lang="scss" scoped>
    .headbar-container {
        @apply w-full flex flex-row items-center justify-between py-7 px-9 border-b-2 border-primary;

        h1 {
            @apply text-black font-bold text-3xl;

            & > span {
                @apply text-negative uppercase;
            }
        }

        .left, .right {
            @apply flex flex-row items-center flex-none max-w-full;

            & > * {
                @apply mx-2;

                &:first-child {
                    @apply ml-0;
                }

                &:last-child {
                    @apply mr-0;
                }
            }
        }
    }
</style>