<template>
    <div class="page-container">
        <Headbar>
            <template v-slot:left>
                <h1>{{$t('wards.add_ward')}}</h1>
            </template>
            <template v-slot:right>
                <Button className="--primary --outline --small" :class="{spinner: is_saving}" :onclick="save">
                    {{$t('save')}}
                </Button>
            </template>
        </Headbar>
        <main>
            <Form class="form" @submit="save" :disabled="is_saving">
                <SectionHeader :title="$t('wards.ward_details')"></SectionHeader>
                <div class="form-body">
                    <FormGroupThree>
                        <FormInputText v-model="$v.ward.name.$model" identifier="name" :label="$t('wards.name')"
                                       :placeholder="$t('wards.name')" :disabled="is_saving"
                                       :has-error="$v.ward.name.$error">
                            <template v-slot:errors>
                                <p v-if="!$v.ward.name.required">
                                    {{$t('validation.x_is_required',{x: $t('wards.name')})}}
                                </p>
                            </template>
                        </FormInputText>
                        <FormInputText v-model="$v.ward.capacity.$model" identifier="capacity"
                                       :label="$t('wards.capacity')"
                                       :placeholder="$t('wards.capacity')" :disabled="is_saving" type="number"
                                       :has-error="$v.ward.capacity.$error">
                            <template v-slot:errors>
                                <p v-if="!$v.ward.capacity.required">
                                    {{$t('validation.x_is_required',{x: $t('wards.capacity')})}}
                                </p>
                                <p v-else-if="!$v.ward.capacity.numeric">
                                    {{$t('validation.please_enter_number')}}
                                </p>
                            </template>
                        </FormInputText>
                        <FormInputText v-model="$v.ward.max_parallel_bookings.$model" identifier="max_parallel_bookings"
                                       :label="$t('wards.max_parallel_bookings')"
                                       :placeholder="$t('wards.max_parallel_bookings')" :disabled="is_saving"
                                       type="number"
                                       :has-error="$v.ward.max_parallel_bookings.$error">
                            <template v-slot:errors>
                                <p v-if="!$v.ward.max_parallel_bookings.required">
                                    {{$t('validation.x_is_required',{x: $t('wards.max_parallel_bookings')})}}
                                </p>
                                <p v-else-if="!$v.ward.max_parallel_bookings.numeric">
                                    {{$t('validation.please_enter_number')}}
                                </p>
                            </template>
                        </FormInputText>
                    </FormGroupThree>
                </div>
            </Form>
            <Form class="form" @submit="save" :disabled="is_saving">
              <SectionHeader :title="$t('wards.time_slots')"></SectionHeader>
              <div class="form-body">
                <FormGroupThree v-for="(timeslot, i) in $v.timeslots.$each.$iter" :key="timeslot.key"
                                class="timeslot-row-group">
                  <FormInputDateTime v-model="timeslot.start.$model" :identifier="`timeslot-${+i}-start`"
                                     :label="$t('start', {x: +i+1})" :only-time="true" :minuteInterval="15"
                                     format="HH:mm" formatted="HH:mm"  :placeholder="$t('start')"
                                     :disabled="is_saving" :has-error="timeslot.start.$error"
                                     output-format="HH:mm:ss">
                      <template v-slot:errors>
                        <p v-if="!timeslot.start.required">
                          {{$t('validation.x_is_required',{x: $t('start')})}}
                        </p>
                      </template>
                  </FormInputDateTime>
                  <FormInputDateTime v-model="timeslot.finish.$model" :identifier="`timeslot-${+i}-finish`"
                                     :label="$t('finish', {x: +i+1})" :only-time="true" :minuteInterval="15"
                                     format="HH:mm" formatted="HH:mm" :placeholder="$t('finish')"
                                     :disabled="is_saving" :has-error="timeslot.finish.$error"
                                     output-format="HH:mm:ss">
                    <template v-slot:errors>
                      <p v-if="!timeslot.finish.required">
                        {{$t('validation.x_is_required',{x: $t('finish')})}}
                      </p>
                    </template>
                  </FormInputDateTime>
                  <Button className="--secondary --outline --mini" :onclick="() => removeTimeslot(i)"
                          class="button-remove">
                    <font-awesome-icon :icon="['far','times']"/>
                  </Button>
                </FormGroupThree>
              </div>

              <div class="row-add-container">
                <Button className="--primary --outline --small" :onclick="addTimeslot">
                  {{$t('wards.add_timeslot')}}
                </Button>
              </div>
            </Form>
        </main>
    </div>
</template>

<script>
    import Headbar from "../../components/headbar/Headbar";
    import Button from "../../components/Button";
    import Form from "../../components/form/Form";
    import SectionHeader from "../../components/SectionHeader";
    import FormGroupThree from "../../components/form/FormGroupThree";
    import FormInputText from "../../components/form/FormInputText";
    import {required, numeric} from 'vuelidate/lib/validators'
    import FormInputDateTime from "../../components/form/FormInputDateTime";

    export default {
        name: "wards-create-page",
        components: {FormInputDateTime, FormInputText, FormGroupThree, SectionHeader, Form, Button, Headbar},
        data() {
            return {
                ward: {
                    name: null,
                    capacity: null,
                    max_parallel_bookings: null,
                },
                timeslots: [
                  {key: 1, start: null, finish: null,},
                ],
                is_saving: false,
            }
        },
        validations: {
            ward: {
                name: {
                    required
                },
                capacity: {
                    required,
                    numeric
                },
                max_parallel_bookings: {
                    required,
                    numeric
                },
            },
            timeslots: {
              $each: {
                start: {required},
                finish: {required},
              }
            }
        },
        methods: {
            removeTimeslot(index) {
              index = parseInt(index);
              if (index >= this.$v.timeslots.$model.length)
                return;

              this.$v.timeslots.$model.splice(index, 1);
            },
            addTimeslot() {
              this.$v.timeslots.$touch();

              if (!this.$v.timeslots.$anyError)
                this.$v.timeslots.$model.push({
                  key: this.$v.timeslots.$model.length + 1,
                  start: null,
                  finish: null,
                });
            },
            save() {
                this.$v.ward.$touch();
                if (this.$v.ward.$anyError || this.is_saving)
                    return;

                this.is_saving = true;

                const payload = Object.assign({}, this.$v.ward.$model);

                this.$axios.post('wards', payload).then(({data}) => {
                    this.$notify({
                        text: this.$t('wards.success_created'),
                        type: 'success',
                    });

                    const ward = data.data;

                    const timeslotsLength =this.timeslots.length;
                    if(timeslotsLength > 0){
                      for(let x = 0; x < timeslotsLength; x++){
                        const timeslot = this.timeslots[x];

                        if(timeslot.start && timeslot.finish && !timeslot.start.$error && !timeslot.finish.$error) {
                          const start = this.$moment(timeslot.start, 'HH:mm:ss').format('HH:mm');
                          const finish = this.$moment(timeslot.finish, 'HH:mm:ss').format('HH:mm');

                          timeslot.start = start;
                          timeslot.finish = finish;

                          this.$axios.post(`wards/${ward.id}/create-timeslot`, timeslot);
                        }
                      }
                    }

                    this.is_saving = false;

                    this.$router.push({name: 'wards-index'});
                }).catch(e => {
                    this.is_saving = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data.errors, this.$t('wards.error_create')),
                        type: 'error',
                    });
                });
            }
        },

        head() {
            return {
                title: {
                    inner: this.$t('wards.add_ward')
                },
            }
        }
    }
</script>


<style lang="scss" scoped>
    .page-container {
        main {
            @apply flex flex-row flex-wrap;

            .form {
                @apply mb-8;

                width: calc(50% - 1rem);

                &:nth-child(odd) {
                    @apply mr-4;
                }

                &:nth-child(even) {
                    @apply ml-4;
                }

                .form-body {
                    @apply px-8 py-7;

                    .timeslot-row-group {
                      .input-group {
                        @apply w-auto flex-1;
                      }

                      .button-remove {
                        @apply flex-none self-start mt-9;
                      }
                    }
                }

                .row-add-container {
                  @apply px-8 pb-8 mt-auto;
                }
            }
        }
    }
</style>