<template>
    <div class="app-container">
        <Menubar/>
        <Sidebar/>
        <div class="main-wrapper">
            <main>
                <slot/>
            </main>
            <Footer/>
        </div>
    </div>
</template>

<script>
    import Menubar from "../components/Menubar";
    import Sidebar from "../components/Sidebar";
    import Footer from "../components/Footer";

    export default {
        name: "AppLayout",
        components: {Footer, Sidebar, Menubar}
    }
</script>

<style lang="scss" scoped>
    .app-container {
        @apply grid h-screen;

        grid-template-columns: auto 1fr;
        grid-template-rows: auto 1fr;
        grid-template-areas: "menubar menubar" "sidebar content";

        .main-wrapper {
            @apply overflow-auto flex flex-col;

            grid-area: content;

            main {
                @apply flex-1;
            }
        }
    }
</style>