<template>
    <div class="page-container">
        <Headbar>
            <template v-slot:left>
                <Button v-if="$store.getters.hasPermission('store visitors')" className="--primary --small"
                        :onclick="toggleCreate">
                    {{ $t('visitors.add_visitor') }}
                </Button>
            </template>
            <template v-slot:right>
                <Search :placeholder="$t('visitors.search_visitors')" @search="search"/>
            </template>
        </Headbar>
        <main>
            <vue-good-table
                mode="remote"
                styleClass="vgt-table striped vgt-custom"
                :columns="columns"
                :rows="visitors"
                :isLoading.sync="is_loading_visitors"
                :search-options="{
                    enabled: false,
                }"
                :pagination-options="{
                    enabled: true,
                    mode: 'records',
                    dropdownAllowAll: false,
                    perPage: 15,
                    perPageDropdownEnabled: false,
                    rowsPerPageLabel: $t('x_per_page', {x: $t('visitors.visitors')}),
                }"
                :sort-options="{
                    enabled: true,
                    multipleColumns: true,
                }"
                :totalRows="totalRecords"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange">
                <template slot="table-row" slot-scope="props">
                    <div v-if="props.column.field === 'role'">
                        <p>{{ props.row.relationships.roles.data[0].attributes.name }}</p>
                    </div>
                    <div v-else-if="props.column.field === 'after'" class="td-after">
                        <Button v-if="$store.getters.hasPermission('update visitors')"
                                className="--secondary --outline --mini --big-text"
                                :onclick="()=>toggleUpdate(props.row)">
                            <font-awesome-icon :icon="['fal', 'eye']"/>
                        </Button>
                        <Button v-if="$store.getters.hasPermission('destroy visitors')"
                                className="--secondary --outline --mini --big-text"
                                :onclick="()=>toggleDelete(props.row)">
                            <font-awesome-icon :icon="['fal', 'trash']"/>
                        </Button>
                    </div>
                    <span v-else>
                      {{ props.formattedRow[props.column.field] }}
                    </span>
                </template>
            </vue-good-table>
        </main>
    </div>
</template>

<script>
import Headbar from "../../components/headbar/Headbar";
import Search from "../../components/Search";
import Button from "../../components/Button";
import Select from "../../components/form/Select";
import ConfirmModal from "../../components/modal/ConfirmModal";
import VisitorsCreateModal from "../../components/visitors/VisitorsCreateModal";
import VisitorsUpdateModal from "../../components/visitors/VisitorsUpdateModal";

export default {
    name: "visitors-index-page",
    components: {ConfirmModal, Select, Button, Search, Headbar},
    data: function () {
        const columns = [
            {
                label: this.$t('visitors.id'),
                field: 'id',
                sortable: false,
            },
            {
                label: this.$t('visitors.name'),
                field: 'attributes.name',
                sortable: false,
            },
            {
                label: this.$t('visitors.id_number'),
                field: 'attributes.id_number',
                sortable: false,
            },
            {
                label: this.$t('visitors.phone'),
                field: 'attributes.phone',
                sortable: false,
            }
        ];

        if (this.$store.getters.hasAnyPermission(['update patients', 'destroy patients']))
            columns.push({
                label: this.$t('visitors.actions'),
                field: 'after',
                tdClass: 'td-after',
                sortable: false
            });

        return {
            columns: columns,
            visitors: [],
            is_loading_visitors: false,
            totalRecords: null,
            serverParams: {sorting: []},
            searchTerm: null,
            is_deleting: false,
        }
    },
    methods: {
        toggleDelete(visitor) {
            this.$modal.show(
                ConfirmModal, {
                    title: this.$t('warning'),
                    message: this.$t('visitors.prompt_delete'),
                    confirmText: this.$t('yes'),
                    cancelText: this.$t('no'),
                    confirmClass: '--negative',
                    cancelClass: '--primary --outline'
                },
                {
                    name: 'confirm-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true) {
                            this.is_deleting = true;
                            this.$axios.delete(`visitors/${visitor.id}`)
                                .then(({data}) => {
                                    this.is_deleting = false;
                                    this.retrieveVisitors();
                                })
                                .catch(e => {
                                    this.is_deleting = false;

                                    this.$notify({
                                        title: this.$t('error'),
                                        text: this.$larerror(e.response.data[0], this.$t('visitors.error_delete')),
                                        type: 'error',
                                    });
                                });
                        }
                    }
                }
            );
        },
        toggleCreate() {
            this.$modal.show(
                VisitorsCreateModal, {},
                {
                    name: 'visitors-create-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true)
                            this.retrieveVisitors();
                    }
                }
            );
        },
        toggleUpdate(visitor) {
            this.$modal.show(
                VisitorsUpdateModal, {
                    visitor_id: visitor.id,
                },
                {
                    name: 'visitors-update-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true)
                            this.retrieveVisitors();
                    }
                }
            );
        },
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
            this.updateParams({page: params.currentPage});
            this.retrieveVisitors();
        },
        onSortChange(params) {
            const sorts = [];

            if (!params || !params.length) {
                this.updateParams({sorting: []});
                return this.retrieveVisitors();
            }

            params.forEach(p => {
                if (!p.type || p.type === 'none')
                    return;

                let sort_by = null;
                let sort_order = p.type;
                if (p.field === 'attributes.name')
                    sort_by = 'name';
                else if (p.field === 'attributes.email')
                    sort_by = 'email';
                else if (p.field === 'role')
                    sort_by = 'role';
                else
                    sort_by = p.field.split('.')[1];

                sorts.push({sort_order, sort_by})
            });

            this.updateParams({sorting: sorts});
            this.retrieveVisitors();
        },
        removeParam(param) {
            this.$delete(this.serverParams, param);
        },
        search(searchTerm) {
            this.searchTerm = searchTerm;

            if (searchTerm && searchTerm.length)
                this.updateParams({term: searchTerm});
            else this.removeParam('term');

            this.retrieveVisitors();
        },
        retrieveVisitors() {
            this.is_loading_visitors = true;

            const encodedSorting = this.serverParams.sorting.map(f => btoa(JSON.stringify(f)));

            this.$axios.get('visitors', {params: {...this.serverParams, sorting: encodedSorting}})
                .then(({data}) => {
                    this.visitors = data.data;
                    this.totalRecords = data.meta.total;
                    this.is_loading_visitors = false;
                })
                .catch(e => {
                    this.is_loading_visitors = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('visitors.error_retrieve')),
                        type: 'error',
                    });
                });
        }
    },
    mounted() {
        this.retrieveVisitors();
    },
    head() {
        return {
            title: {
                inner: this.$t('nav.visitors')
            },
        }
    }
}
</script>

<style lang="scss" scoped>
.page-container {
    .td-after {
        @apply flex flex-row;

        & > * {
            @apply mr-3;

            &:last-child {
                @apply mr-0;
            }
        }
    }
}
</style>
