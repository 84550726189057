<template>
    <div class="page-container">
        <Headbar>
            <template v-slot:left>
                <h1>{{ $t('bookings.edit_booking') }}</h1>
            </template>
            <template v-slot:right>
                <Button className="--primary --outline --small" :class="{spinner: is_saving}" :onclick="save">
                    {{ $t('save') }}
                </Button>
            </template>
        </Headbar>
        <main>
            <div class="form-area">
                <Form class="form" @submit="save" :disabled="is_saving">
                    <SectionHeader :title="$t('bookings.booking_details')"></SectionHeader>
                    <div class="form-body">
                        <FormGroupTwo>
                            <FormInputSelect v-model="$v.booking.ward.$model" identifier="ward"
                                             :label="$t('bookings.ward')" :options="wardOptions"
                                             :placeholder="$t('bookings.ward')" :disabled="is_saving"
                                             :has-error="$v.booking.ward.$error" @updated="selectedWard"
                                             :display-custom-label="(row) => row.attributes.name" track-by="id">
                                <template v-slot:errors>
                                    <p v-if="!$v.booking.ward.required">
                                        {{ $t('validation.x_is_required', {x: $t('bookings.ward')}) }}
                                    </p>
                                </template>
                            </FormInputSelect>
                            <FormInputSelect v-model="$v.booking.patient.$model" identifier="patient"
                                             :label="$t('bookings.patient')" :options="patientOptions"
                                             :placeholder="$t('bookings.patient')"
                                             :disabled="is_saving || !booking.ward"
                                             :has-error="$v.booking.patient.$error"
                                             @updated="selectedPatient"
                                             :display-custom-label="(row) => `${row.attributes.last_name} ${row.attributes.first_name} - ${row.attributes.id_number}`"
                                             track-by="id">
                                <template v-slot:errors>
                                    <p v-if="!$v.booking.patient.required">
                                        {{ $t('validation.x_is_required', {x: $t('bookings.patient')}) }}
                                    </p>
                                </template>
                            </FormInputSelect>
                            <FormInputDateTime v-model="$v.booking.date.$model" identifier="date"
                                               :label="$t('bookings.date')" :only-date="true" @input="selectedDate"
                                               formatted="Do MMM YYYY" :placeholder="$t('bookings.date')"
                                               :disabled="is_saving" :has-error="$v.booking.date.$error"
                                               output-format="YYYY-MM-DD"></FormInputDateTime>
                            <FormInputSelect v-model="$v.booking.timeslot.$model" identifier="timeslot"
                                             :label="$t('bookings.timeslot')" :options="timeslotOptions"
                                             :placeholder="$t('bookings.timeslot')"
                                             :disabled="is_saving || !booking.patient || !booking.date"
                                             :has-error="$v.booking.timeslot.$error"
                                             :display-custom-label="(row) => `${row.from} - ${row.to}`"
                                             track-by="id">
                                <template v-slot:errors>
                                    <p v-if="!$v.booking.timeslot.required">
                                        {{ $t('validation.x_is_required', {x: $t('bookings.timeslot')}) }}
                                    </p>
                                </template>
                            </FormInputSelect>
                        </FormGroupTwo>
                    </div>
                </Form>
                <Form class="form" @submit="save" :disabled="is_saving">
                    <SectionHeader :title="$t('bookings.visitor_details')"></SectionHeader>
                    <div class="form-body">
                        <div class="input-group multiselect-input" :class="{error: $v.selected_visitors.$error}">
                            <label for="visitor">{{ $t('bookings.registered_visitors') }}</label>
                            <multiselect ref="visitors-multiselect" v-model="$v.selected_visitors.$model" identifier="visitor" :multiple="true"
                                         :label="$t('bookings.registered_visitors')" :options="visitorOptions"
                                         :placeholder="$t('bookings.registered_visitors')" :showLabels="false"
                                         :disabled="is_saving || is_loading_visitor_options || !booking.patient"
                                         :has-error="$v.selected_visitors.$error" :internal-search="false" @search-change="searchVisitors" :preserveSearch="true"
                                         :custom-label="(row) => `${row.attributes.name} - ${row.attributes.id_number} - ${row.attributes.phone}`"
                                         track-by="id">
                            </multiselect>
                            <div class="form-errors" v-if="$v.selected_visitors.$error">
                                <p v-if="!$v.selected_visitors.required">
                                    {{ $t('validation.x_is_required', {x: $t('bookings.visitors')}) }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="border-div"></div>
                    <div class="form-body">
                        <FormGroupThree v-for="(visitor, i) in $v.booking.visitors.$each.$iter" :key="visitor.key"
                                        class="visitor-row-group">
                            <FormInputText v-model="visitor.name.$model" :identifier="`visitor-${+i}-name`"
                                           :label="$t('bookings.visitor_x_name', {x: +i+1})"
                                           :placeholder="$t('bookings.name')" :disabled="is_saving || visitor.new.$model === false"
                                           :has-error="visitor.name.$error">
                                <template v-slot:errors>
                                    <p v-if="!visitor.name.required">
                                        {{ $t('validation.x_is_required', {x: $t('bookings.name')}) }}
                                    </p>
                                </template>
                            </FormInputText>
                            <FormInputText v-model="visitor.id_number.$model" :identifier="`visitor-${+i}-id_number`"
                                           :label="$t('bookings.id_number')"
                                           :placeholder="$t('bookings.id_number')" :disabled="is_saving || visitor.new.$model === false"
                                           :has-error="visitor.id_number.$error">
                                <template v-slot:errors>
                                    <p v-if="!visitor.id_number.required">
                                        {{ $t('validation.x_is_required', {x: $t('bookings.id_number')}) }}
                                    </p>
                                </template>
                            </FormInputText>
                            <FormInputText v-model="visitor.phone.$model" :identifier="`visitor-${+i}-phone`"
                                           :label="$t('bookings.phone')"
                                           :placeholder="$t('bookings.phone')" :disabled="is_saving || visitor.new.$model === false"
                                           :has-error="visitor.phone.$error">
                                <template v-slot:errors>
                                    <p v-if="!visitor.phone.required">
                                        {{ $t('validation.x_is_required', {x: $t('bookings.phone')}) }}
                                    </p>
                                </template>
                            </FormInputText>
                            <div class="actions">
                                <Button className="--secondary --outline --mini" :onclick="() => removeVisitor(i)"
                                        class="button-remove" :class="{only: $v.booking.visitors.$model.length === 1}"
                                        :disabled="$v.booking.visitors.$model.length === 1">
                                    <font-awesome-icon :icon="['far','times']"/>
                                </Button>
                                <Button v-if="visitor.$model.id"
                                        :className="`${visitor.$model.has_visited ? '--primary' : '--negative'} --mini`"
                                        :onclick="() => togglePresent(visitor.$model.id)"
                                        class="button-present"
                                        :class="{'spinner-black': visitor.$model.loading_visited}"
                                        :content="visitor.$model.has_visited ? $t('bookings.mark_visitor_absent') : $t('bookings.mark_visitor_present')"
                                        v-tippy="{ placement : 'top',  arrow: true }">
                                    <font-awesome-icon v-if="visitor.$model.has_visited" :icon="['far','user-check']"/>
                                    <font-awesome-icon v-else :icon="['far','user-slash']"/>
                                </Button>
                            </div>
                        </FormGroupThree>
                    </div>

                    <div class="row-add-container">
                        <Button className="--primary --outline --small" :onclick="addVisitor"
                                :disabled="!this.booking.visitors[this.booking.visitors.length-1].name || !this.booking.visitors[this.booking.visitors.length-1].id_number || !this.booking.visitors[this.booking.visitors.length-1].phone">
                            {{ $t('bookings.add_visitor') }}
                        </Button>
                    </div>
                </Form>
            </div>
            <div class="divider"></div>
            <div class="table-area">
                <vue-good-table
                    mode="remote"
                    styleClass="vgt-table striped vgt-custom"
                    :columns="columns"
                    :rows="ward_bookings"
                    :isLoading.sync="is_loading_ward_bookings"
                    :search-options="{
                enabled: false,
            }"
                    :pagination-options="{
                enabled: true,
                mode: 'records',
                dropdownAllowAll: false,
                perPage: 15,
                perPageDropdownEnabled: false,
                rowsPerPageLabel: $t('x_per_page', {x: $t('bookings.bookings')}),
            }"
                    :totalRows="totalRecords"
                    @on-page-change="onPageChange"
                    @on-sort-change="onSortChange">
                    <template slot="table-row" slot-scope="props">
                        <div v-if="props.column.field === 'date'">
                            <p>{{ $moment.utc(props.row.attributes.from).format('DD/MM/YYYY') }}</p>
                        </div>
                        <div v-if="props.column.field === 'time'">
                            <p>{{ $moment.utc(props.row.attributes.from).format('HH:mm') }} -
                                {{ $moment.utc(props.row.attributes.to).format('HH:mm') }}</p>
                        </div>
                        <div v-if="props.column.field === 'visitor_1_name'">
                            <p v-if="props.row.relationships && props.row.relationships.visitors && props.row.relationships.visitors.data.length >= 1">
                                {{ props.row.relationships.visitors.data[0].attributes.name }}
                            </p>
                        </div>
                        <div v-else-if="props.column.field === 'visitor_1_id'">
                            <p v-if="props.row.relationships && props.row.relationships.visitors && props.row.relationships.visitors.data.length >= 1">
                                {{ props.row.relationships.visitors.data[0].attributes.id_number }}
                            </p>
                        </div>
                        <div v-else-if="props.column.field === 'visitor_2_name'">
                            <p v-if="props.row.relationships && props.row.relationships.visitors && props.row.relationships.visitors.data.length >= 2">
                                {{ props.row.relationships.visitors.data[1].attributes.name }}
                            </p>
                        </div>
                        <div v-else-if="props.column.field === 'visitor_2_id'">
                            <p v-if="props.row.relationships && props.row.relationships.visitors && props.row.relationships.visitors.data.length >= 2">
                                {{ props.row.relationships.visitors.data[1].attributes.id_number }}
                            </p>
                        </div>
                        <span v-else>
                      {{ props.formattedRow[props.column.field] }}
                    </span>
                    </template>
                </vue-good-table>
            </div>
            <div class="divider"></div>
            <div class="table-area">
                <p class="title">{{ $t('audits.audits') }}</p>
                <vue-good-table
                    mode="remote"
                    styleClass="vgt-table striped vgt-custom"
                    :columns="auditColumns"
                    :rows="audits"
                    :isLoading.sync="is_loading_audits"
                    :search-options="{
                enabled: false,
            }"
                    :pagination-options="{
                enabled: true,
                mode: 'records',
                dropdownAllowAll: false,
                perPage: 15,
                perPageDropdownEnabled: false,
                rowsPerPageLabel: $t('x_per_page', {x: $t('audits.audits')}),
            }"
                    :totalRows="totalAuditRecords"
                    @on-page-change="onAuditPageChange"
                    @on-sort-change="onAuditSortChange">
                    <template slot="table-row" slot-scope="props">
                        <div v-if="props.column.field === 'values'" class="td-values">
                            <div v-if="Object.keys(props.row.attributes.old_values).length" class="row">
                                <p class="name">{{ $t('audits.old_values') }}</p>
                                <div class="values">
                                    <p v-for="key in Object.keys(props.row.attributes.old_values)">
                                        <strong>{{ $t(`bookings.${key}`) }}: </strong>{{ props.row.attributes.old_values[key] }}
                                    </p>
                                </div>
                            </div>
                            <div v-if="Object.keys(props.row.attributes.new_values).length" class="row">
                                <p class="name">{{ $t('audits.new_values') }}</p>
                                <div class="values">
                                    <p v-for="key in Object.keys(props.row.attributes.new_values)">
                                        <strong>{{ $t(`bookings.${key}`) }}: </strong>{{ props.row.attributes.new_values[key] }}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <span v-else>
                      {{ props.formattedRow[props.column.field] }}
                    </span>
                    </template>
                </vue-good-table>
            </div>
        </main>
    </div>
</template>

<script>
import Headbar from "../../components/headbar/Headbar";
import Form from "../../components/form/Form";
import SectionHeader from "../../components/SectionHeader";
import FormInputText from "../../components/form/FormInputText";
import {required, requiredIf} from 'vuelidate/lib/validators'
import FormGroupTwo from "../../components/form/FormGroupTwo";
import FormGroupThree from "../../components/form/FormGroupThree";
import FormInputSelect from "../../components/form/FormInputSelect";
import FormInputDateTime from "../../components/form/FormInputDateTime";
import Button from "../../components/Button";
import _ from "lodash";

export default {
    name: "bookings-update-page",
    components: {
        Button,
        FormGroupTwo,
        FormInputDateTime,
        FormInputSelect,
        FormGroupThree,
        FormInputText,
        SectionHeader,
        Form,
        Headbar
    },
    data() {
        return {
            original: null,
            booking: {
                ward: null,
                patient: null,
                date: null,
                timeslot: null,
                visitors: [
                    {key: 1, name: null, id_number: null, phone: null, new: false},
                ],
            },
            selected_visitors: [],
            slots: null,
            columns: [
                {
                    label: this.$t('bookings.id'),
                    field: 'id',
                    sortable: false,
                },
                {
                    label: this.$t('bookings.date'),
                    field: 'date',
                    sortable: false,
                },
                {
                    label: this.$t('bookings.ward'),
                    field: row => row.attributes.ward ? row.attributes.ward : row.relationships.patient.data.attributes.ward,
                    sortable: false,
                },
                {
                    label: this.$t('bookings.first_name'),
                    field: 'relationships.patient.data.attributes.first_name',
                    sortable: false,
                },
                {
                    label: this.$t('bookings.last_name'),
                    field: 'relationships.patient.data.attributes.last_name',
                    sortable: false,
                },
                {
                    label: this.$t('bookings.time'),
                    field: 'time',
                    sortable: false,
                },
                {
                    label: this.$t('bookings.visitor_x_name', {x: 1}),
                    field: 'visitor_1_name',
                    sortable: false,
                },
                {
                    label: this.$t('bookings.visitor_x_id', {x: 1}),
                    field: 'visitor_1_id',
                    sortable: false,
                },
                {
                    label: this.$t('bookings.visitor_x_name', {x: 2}),
                    field: 'visitor_2_name',
                    sortable: false,
                },
                {
                    label: this.$t('bookings.visitor_x_id', {x: 2}),
                    field: 'visitor_2_id',
                    sortable: false,
                },
            ],
            auditColumns: [
                {
                    label: this.$t('audits.user'),
                    field: 'relationships.user.data.attributes.name',
                    sortable: false,
                },
                {
                    label: this.$t('audits.event'),
                    field: row => this.$t(`audits.${row.attributes.event}`),
                    sortable: false,
                },
                {
                    label: this.$t('audits.date_n_time'),
                    field: row => this.$moment.utc(row.attributes.created_at).format('DD/MM/YYYY - HH:mm'),
                    sortable: false,
                },
                {
                    label: this.$t('audits.values'),
                    field: 'values',
                    sortable: false,
                }
            ],
            audits: [],
            ward_bookings: [],
            totalRecords: null,
            serverParams: {},
            is_saving: false,
            is_loading_ward_options: false,
            is_loading_patient_options: false,
            is_loading_timeslot_options: false,
            is_loading_calendar: false,
            is_loading_ward_bookings: false,
            is_loading_visitor_options: false,
            is_loading_audits: false,
            totalAuditRecords: null,
            auditServerParams: {},
            patientOptions: [],
            wardOptions: [],
            timeslotOptions: [],
            visitorOptions: [],
            visitor_exists: false,
        }
    },
    validations: {
        selected_visitors: {
            required: requiredIf(function () {
                return !(this.booking.visitors[0].name || this.booking.visitors[0].id_number || this.booking.visitors[0].phone);
            })
        },
        booking: {
            ward: {required},
            patient: {required},
            date: {required},
            timeslot: {required},
            visitors: {
                $each: {
                    name: {
                        required: requiredIf(function(visitor) {
                            return !this.selected_visitors.length || this.booking.visitors.length > 1 || visitor.id_number || visitor.phone;
                        })
                    },
                    id_number: {
                        required: requiredIf(function(visitor) {
                            return !this.selected_visitors.length || this.booking.visitors.length > 1 || visitor.name || visitor.phone;
                        })
                    },
                    phone: {
                        required: requiredIf(function(visitor) {
                            return !this.selected_visitors.length || this.booking.visitors.length > 1 || visitor.name || visitor.id_number;
                        })
                    },
                    new: {}
                }
            }
        }
    },
    methods: {
        searchVisitors: _.debounce(function (searchQuery, id) {
            if (!searchQuery) return;

            this.is_loading_visitor_options = true;

            let params = {term: searchQuery};

            this.$axios.get(`visitors/list`, {params: params})
                .then(({data}) => {
                    this.visitorOptions = data.data;
                    this.is_loading_visitor_options = false;

                    this.$refs['visitors-multiselect'].isOpen = true;
                })
                .catch(e => {
                    this.is_loading_visitor_options = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('visitors.error_retrieve')),
                        type: 'error',
                    });
                });
        }, 500),
        togglePresent(visitor_id) {
            const index = this.booking.visitors.findIndex(v => v.id === visitor_id);

            if (index === -1)
                return;

            this.$set(this.booking.visitors[index], 'loading_visited', true);

            this.$axios.put(`bookings/${this.$route.params.id}/visitors/${visitor_id}`)
                .then(({data}) => {
                    if (index !== -1) {
                        const current = this.booking.visitors[index].has_visited;
                        this.$set(this.booking.visitors[index], 'has_visited', current ? 0 : 1);
                    }

                    this.$set(this.booking.visitors[index], 'loading_visited', false);
                }).catch(e => {
                this.$set(this.booking.visitors[index], 'loading_visited', false);

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data, this.$t('bookings.error_present')),
                    type: 'error',
                });
            });
        },
        removeVisitor(index) {
            index = parseInt(index);
            if (index >= this.$v.booking.visitors.$model.length)
                return;

            this.$v.booking.visitors.$model.splice(index, 1);
        },
        addVisitor() {
            this.$v.booking.visitors.$touch();

            if (!this.$v.booking.visitors.$anyError)
                this.$v.booking.visitors.$model.push({
                    key: this.$v.booking.visitors.$model.length + 1,
                    id: null,
                    name: null,
                    id_number: null,
                    phone: null,
                    has_visited: null,
                    loading_visited: false,
                    new: true
                });
        },
        generateAvailabilities() {
            const {schedule, occupied_slots} = this.slots;
            if (!schedule.length) return [];

            const availabilities = schedule.map(s => ({from: s.opens_at, to: s.closes_at}));
            return availabilities;
        },
        retrieveAudits() {
            this.is_loading_audits = false;
            this.$axios.get(`bookings/${this.$route.params.id}/audits`, {params: this.auditServerParams})
                .then(({data}) => {
                    this.audits = data.data;
                    this.totalAuditRecords = data.meta.total;
                    this.is_loading_audits = false;
                })
                .catch(e => {
                    this.is_loading_audits = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('audits.error_retrieve_audits')),
                        type: 'error',
                    });
                });
        },
        updateAuditParams(newProps) {
            this.auditServerParams = Object.assign({}, this.auditServerParams, newProps);
        },
        onAuditPageChange(params) {
            this.updateAuditParams({page: params.currentPage});
            this.retrieveAudits();
        },
        onAuditSortChange(params) {
            this.updateAuditParams({sort: params});
            this.retrieveAudits();
        },
        getCalendar() {
            if (!this.booking.patient || !this.booking.date)
                return;

            this.booking.ward = null;
            this.booking.time_from = null;
            this.booking.time_to = null;

            this.is_loading_calendar = true;
            this.$axios.get(`patients/${this.booking.patient.id}/calendar`, {
                params: {
                    from: `${this.booking.date} 00:00:00`,
                    to: `${this.booking.date} 23:59:59`,
                }
            }).then(async ({data}) => {
                await this.getPatientWard();

                const schedule = this.booking.ward.attributes.schedule.map(ds => ({
                    opens_at: this.$moment(ds.opens_at, 'HH:mm').utc().format('HH:mm'),
                    closes_at: this.$moment(ds.closes_at, 'HH:mm').utc().format('HH:mm')
                }));

                const occupied_slots = data.data.map(s => ({
                    from: this.$moment.utc(s.attributes.from).format('HH:mm'),
                    to: this.$moment.utc(s.attributes.to).format('HH:mm')
                }));

                this.slots = {schedule, occupied_slots};
                this.slots.availabilities = this.generateAvailabilities();

                this.is_loading_calendar = false;
            }).catch(e => {
                this.is_loading_calendar = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data, this.$t('bookings.error_retrieve_calendar')),
                    type: 'error',
                });
            });
        },
        async getPatientWard() {
            if (!this.booking.patient)
                return;

            await this.$axios.get(`wards/${this.booking.patient.attributes.ward.id}`)
                .then(async ({data}) => {
                    this.booking.ward = data.data;
                    await this.getWardSchedule();
                    this.retrieveWardBookings();
                })
                .catch(e => {
                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('wards.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        async getWardSchedule() {
            await this.$axios.post(`wards/${this.booking.ward.id}/day-schedule`, {
                date: this.booking.date
            }).then(({data}) => {
                this.booking.ward.attributes.schedule = data;
            }).catch(e => {
                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data, this.$t('wards.error_retrieve')),
                    type: 'error',
                });
            });
        },
        selectedWard() {
            this.booking.patient = null;
            this.booking.timeslot = null;

            this.retrieveWardBookings();
            this.retrievePatientOptions();
        },
        selectedPatient() {
            this.booking.timeslot = null;

            if (this.booking.date) {
                this.retrievePatientTimeslotOptions();
                this.retrieveWardBookings();
            }
        },
        selectedDate() {
            this.booking.timeslot = null;

            this.retrieveWardBookings();
            this.retrievePatientTimeslotOptions();
        },
        async retrieveWardOptions() {
            this.is_loading_ward_options = false;
            await this.$axios.get('wards/list')
                .then(({data}) => {
                    this.wardOptions = data.data;
                    this.is_loading_ward_options = false;
                })
                .catch(e => {
                    this.is_loading_ward_options = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('wards.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        async retrievePatientOptions() {
            this.is_loading_patient_options = false;
            await this.$axios.get(`wards/${this.booking.ward.id}/patients`)
                .then(({data}) => {
                    this.patientOptions = data.data;
                    this.is_loading_patient_options = false;
                })
                .catch(e => {
                    this.is_loading_patient_options = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('patients.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        async retrievePatientTimeslotOptions() {
            if (!this.booking.patient || !this.booking.date)
                return;

            this.is_loading_timeslot_options = false;
            await this.$axios.get(`patients/${this.booking.patient.id}/availability`, {params: {date: this.booking.date}})
                .then(({data}) => {
                    this.timeslotOptions = data.map(d => ({
                        id: d.id,
                        from: this.$moment.utc(d.from, 'HH:mm:ss').format('HH:mm'),
                        to: this.$moment.utc(d.to, 'HH:mm:ss').format('HH:mm'),
                        '$isDisabled': d.status.toLocaleLowerCase() !== 'available'
                    }));

                    this.is_loading_timeslot_options = false;
                })
                .catch(e => {
                    this.is_loading_timeslot_options = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('bookings.error_retrieve_timeslots')),
                        type: 'error',
                    });
                });
        },
        async getVisitorIds() {
            const ids = [];

            for (let x = 0; x < this.booking.visitors.length; x++) {
                let v = this.booking.visitors[x];

                let exists = false;

                const filters = [
                    {
                        filter_by: 'id_number',
                        filter_operator: '=',
                        filter_value: v.id_number
                    }
                ]

                const encodedFilters = filters.map(f => btoa(JSON.stringify(f)));

                if(this.booking.visitors[x].id) ids.push(this.booking.visitors[x].id);
                else {
                    // check if visitor exists
                    await this.$axios.get('visitors', {params: {filters: encodedFilters}})
                        .then(async ({data}) => {
                            if (data.data.length)
                                exists = data.data[0];

                            if (exists) {
                                this.$notify({
                                    title: this.$t('error'),
                                    text: this.$t('bookings.user_with_id_x_already_exists', {x: v.id_number}),
                                    type: 'error',
                                });

                                this.visitor_exists = true;
                            } else {
                                // create visitor
                                await this.$axios.post(`visitors`, v).then(({data}) => {
                                    ids.push(data.data.id);
                                });
                            }
                        });
                }
            }

            return ids;
        },
        async populate() {
            if (!this.original)
                return;

            this.$v.booking.date.$model = this.$moment.utc(this.original.attributes.from).format('YYYY-MM-DD');

            this.$v.booking.ward.$model = _.find(this.wardOptions, {id: this.original.relationships.patient.data.attributes.ward_id});
            this.retrieveWardBookings();

            await this.retrievePatientOptions();
            this.$v.booking.patient.$model = _.find(this.patientOptions, {id: this.original.relationships.patient.data.id});

            await this.retrievePatientTimeslotOptions();

            const bookingTimeFrom = this.$moment.utc(this.original.attributes.from).format('HH:mm');
            const bookingTimeTo = this.$moment.utc(this.original.attributes.to).format('HH:mm');
            const timeslotIndex = this.timeslotOptions.findIndex(t => t.from === bookingTimeFrom && t.to === bookingTimeTo);

            if (timeslotIndex === -1)
                this.$v.booking.timeslot.$model = {
                    id: 0,
                    from: this.$moment.utc(this.original.attributes.from).format('HH:mm'),
                    to: this.$moment.utc(this.original.attributes.to).format('HH:mm'),
                };
            else
                this.$v.booking.timeslot.$model = this.timeslotOptions[timeslotIndex];

            if (this.original.relationships.visitors.data.length)
                this.$v.booking.visitors.$model = this.original.relationships.visitors.data.map((v, i) => ({
                    key: v.id,
                    id: v.id,
                    name: v.attributes.name,
                    id_number: v.attributes.id_number,
                    phone: v.attributes.phone,
                    has_visited: v.attributes.has_visited,
                    loading_visited: false,
                    new: false
                }));
        },
        async retrieveOriginalBooking() {
            this.is_loading_original = false;
            await this.$axios.get(`bookings/${this.$route.params.id}`)
                .then(({data}) => {
                    this.is_loading_original = false;
                    this.original = data.data;
                })
                .catch(e => {
                    this.is_loading_original = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('bookings.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        async save() {
            this.$v.booking.$touch();
            if (this.$v.booking.$anyError || this.is_saving)
                return;

            this.visitor_exists = false;
            this.is_saving = true;

            let visitor_ids;

            //array of new visitors
            if(this.booking.visitors.length && this.booking.visitors[0].name)
                visitor_ids = await this.getVisitorIds();

            //array of associated visitors
            if(this.selected_visitors.length) {
                this.selected_visitors.map((visitor) => visitor.id).forEach(id => {visitor_ids.push(id)});
            }

            if(this.visitor_exists) {
                this.is_saving = false;
                return;
            }

            const payload = {};
            payload.from = `${this.booking.date} ${this.$moment(this.booking.timeslot.from, 'HH:mm').format('HH:mm:ss')}`;
            payload.to = `${this.booking.date} ${this.$moment(this.booking.timeslot.to, 'HH:mm').format('HH:mm:ss')}`;
            payload.visitors = visitor_ids;

            this.$axios.patch(`bookings/${this.$route.params.id}`, payload).then(({data}) => {
                this.$notify({
                    text: this.$t('bookings.success_updated'),
                    type: 'success',
                });

                this.is_saving = false;

                this.$router.push({name: 'bookings-index'});
            }).catch(e => {
                this.is_saving = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('bookings.error_update')),
                    type: 'error',
                });
            });
        },
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
            this.updateParams({page: params.currentPage});
            this.retrieveWardBookings();
        },
        onSortChange(params) {
            this.updateParams({sort: params});
            this.retrieveWardBookings();
        },
        removeParam(param) {
            this.$delete(this.serverParams, param);
        },
        retrieveWardBookings() {
            this.ward_bookings = [];
            if (!this.booking.ward || !this.booking.date)
                return;

            this.is_loading_ward_bookings = true;

            const filters = [
                {
                    filter_by: 'from',
                    filter_operator: ">=",
                    filter_value: `${this.booking.date} 00:00:00`
                }, {
                    filter_by: 'to',
                    filter_operator: "<=",
                    filter_value: `${this.booking.date} 23:59:59`
                }];

            // filter by patient
            if (this.booking.patient)
                filters.push({
                    filter_by: 'patient_id',
                    filter_operator: "=",
                    filter_value: this.booking.patient.id
                })

            const encodedFilters = filters.map(f => btoa(JSON.stringify(f)));

            this.$axios.get(`wards/${this.booking.ward.id}/bookings`, {params: {filters: encodedFilters}})
                .then(({data}) => {
                    this.ward_bookings = data.data;
                    this.totalRecords = data.meta.total;
                    this.is_loading_ward_bookings = false;
                })
                .catch(e => {
                    this.is_loading_ward_bookings = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('bookings.error_retrieve')),
                        type: 'error',
                    });
                });
        }
    },

    async mounted() {
        this.retrieveAudits();
        await this.retrieveOriginalBooking();
        await this.retrieveWardOptions();
        await this.populate();
    },

    head() {
        return {
            title: {
                inner: this.$t('bookings.edit_booking')
            },
        }
    }
}
</script>

<style lang="scss" scoped>
.page-container {
    main {
        @apply px-0;

        .form-area {
            @apply flex flex-row flex-wrap px-9;

            .form {
                @apply mb-8;

                width: calc(50% - 1rem);

                &:nth-child(odd) {
                    @apply mr-4;
                }

                &:nth-child(even) {
                    @apply ml-4;
                }

                .form-body {
                    @apply px-8 py-7;

                    .visitor-row-group {

                        .input-group {
                            @apply w-auto flex-1;
                        }

                        .actions {
                            @apply flex flex-row items-center flex-none self-start mt-9;

                            .button-remove {
                                &.only {
                                    @apply opacity-0 cursor-default;
                                }
                            }

                            .button-present {
                                @apply ml-2;
                            }
                        }
                    }
                }

                .row-add-container {
                    @apply px-8 pb-8 mt-auto;
                }

                .border-div {
                    @apply border-t-2 border-grey-light w-11/12 mx-auto;
                }
            }
        }

        .divider {
            @apply w-full border-b-2 border-grey;
        }

        .table-area {
            @apply px-9 pt-7 pb-9;

            p.title {
                @apply text-black font-bold text-xl leading-none mb-6;
            }

            .td-values {
                @apply flex flex-col gap-y-4;

                .row {
                    @apply flex flex-col gap-y-1;

                    p.name {
                        @apply text-sm font-bold text-black leading-normal;
                    }

                    .values {
                        @apply flex flex-col;

                        .row-value {

                        }
                    }
                }
            }
        }
    }

    .input-group {
        &.multiselect-input {
            @apply w-full flex flex-col items-start mb-5;

            label {
                @apply text-sm text-black font-bold mb-3;
            }

            &.error {
                .input {
                    @apply border-negative;
                }
            }

            & > .form-errors {
                @apply mt-3 mb-1;

                p {
                    @apply text-negative italic text-sm py-1 ml-4;
                }
            }
        }
    }
}
</style>