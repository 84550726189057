<template>
  <div class="deceased-modal">
    <ModalContainer :title="$t('patients.decease_patient')" identifier="deceased-modal" :closable="true">
      <Form class="form" @submit="save" :disabled="is_saving">
        <FormInputDateTime v-model="$v.deceased_on.$model" identifier="deceased_on"
                           :label="$t('patients.deceased_on')" :only-date="true"
                           formatted="Do MMM YYYY" :placeholder="$t('patients.deceased_on')"
                           :disabled="is_saving" :has-error="$v.deceased_on.$error"
                           output-format="YYYY-MM-DD 00:00:00"></FormInputDateTime>
        <div class="died-at-mdh-container">
          <FormInputCheck
              v-model="$v.died_at_mdh.$model"
              :disabled="is_saving"
              :label="$t('patients.died_at_mdh')"
          />
        </div>

        <Button type="submit" className="--primary --small" :class="{spinner: is_saving}">
          {{ $t('patients.decease_patient') }}
        </Button>
      </Form>
    </ModalContainer>
  </div>
</template>

<script>
import ModalContainer from "../modal/ModalContainer";
import Button from "@/components/Button.vue";
import {required} from 'vuelidate/lib/validators'
import Form from "@/components/form/Form.vue";
import FormInputDateTime from "@/components/form/FormInputDateTime.vue";
import FormInputCheck from "@/components/form/FormInputCheck.vue";

export default {
  name: "DeceasedModal",
  components: {Form, FormInputCheck, FormInputDateTime, ModalContainer, Button},
  data() {
    return {
      deceased_on: null,
      died_at_mdh: false,
      is_saving: false,
    }
  },
  validations: {
    deceased_on: {
      required
    },
    died_at_mdh: {
      required
    }
  },
  methods: {
    close(status) {
      this.$modal.hide('deceased-modal', status);
    },
    save() {
      this.$v.$touch();
      if (this.$v.$anyError || this.is_saving)
        return;

      this.is_saving = true;

      this.$axios.put(`patients/${this.$route.params.id}/decease`, {
        deceased_on:this.deceased_on,
        died_at_mdh:this.died_at_mdh
      }).then(({data}) => {
        this.$notify({
          text: this.$t('patients.success_updated'),
          type: 'success',
        });

        this.is_saving = false;
        this.close(true);
      }).catch(e => {
        this.is_saving = false;

        this.$notify({
          title: this.$t('error'),
          text: this.$larerror(e.response.data.errors, this.$t('patients.error_deceased')),
          type: 'error',
        });
      });
    },
  },
}
</script>

<style lang="scss" scoped>
.deceased-modal{
  .died-at-mdh-container{
    @apply mb-4;
  }
}
</style>