<template>
    <div class="page-container">
        <Headbar>
            <template v-slot:left>
                <h1>{{$t('patients.add_patient')}}</h1>
            </template>
            <template v-slot:right>
                <Button className="--primary --outline --small" :class="{spinner: is_saving}" :onclick="save">
                    {{$t('save')}}
                </Button>
            </template>
        </Headbar>
        <main>
            <Form class="form" @submit="save" :disabled="is_saving">
                <SectionHeader :title="$t('patients.patient_details')"></SectionHeader>
                <div class="form-body">
                    <FormGroupThree>
                        <FormInputText v-model="$v.patient.first_name.$model" identifier="first_name" :label="$t('patients.first_name')"
                                       :placeholder="$t('patients.first_name')" :disabled="is_saving"
                                       :has-error="$v.patient.first_name.$error">
                            <template v-slot:errors>
                                <p v-if="!$v.patient.first_name.required">
                                    {{$t('validation.x_is_required',{x: $t('patients.first_name')})}}
                                </p>
                            </template>
                        </FormInputText>
                        <FormInputText v-model="$v.patient.last_name.$model" identifier="last_name" :label="$t('patients.last_name')"
                                       :placeholder="$t('patients.last_name')" :disabled="is_saving"
                                       :has-error="$v.patient.last_name.$error">
                          <template v-slot:errors>
                            <p v-if="!$v.patient.last_name.required">
                              {{$t('validation.x_is_required',{x: $t('patients.last_name')})}}
                            </p>
                          </template>
                        </FormInputText>
                        <FormInputText v-model="$v.patient.id_number.$model" identifier="id_number"
                                       :label="$t('patients.id_number')"
                                       :placeholder="$t('patients.id_number')" :disabled="is_saving"
                                       :has-error="$v.patient.id_number.$error">
                            <template v-slot:errors>
                                <p v-if="!$v.patient.id_number.required">
                                    {{$t('validation.x_is_required',{x: $t('patients.id_number')})}}
                                </p>
                            </template>
                        </FormInputText>
                        <FormInputText v-model="$v.patient.date_of_birth.$model" identifier="date_of_birth"
                                       :label="$t('patients.date_of_birth')" type="date"
                                       :placeholder="$t('patients.date_of_birth')" :disabled="is_saving"
                                       :has-error="$v.patient.date_of_birth.$error">
                            <template v-slot:errors>
                                <p v-if="!$v.patient.date_of_birth.required">
                                    {{$t('validation.x_is_required',{x: $t('patients.date_of_birth')})}}
                                </p>
                            </template>
                        </FormInputText>
                    </FormGroupThree>
                    <FormGroupThree>
                        <FormInputSelect v-model="$v.patient.gender.$model" identifier="gender"
                                         :label="$t('patients.gender')" :options="genderOptions"
                                         :placeholder="$t('patients.gender')" :disabled="is_saving"
                                         :has-error="$v.patient.gender.$error">
                            <template v-slot:errors>
                                <p v-if="!$v.patient.gender.required">
                                    {{$t('validation.x_is_required',{x: $t('patients.gender')})}}
                                </p>
                            </template>
                        </FormInputSelect>
                        <FormInputSelect v-model="$v.patient.locality.$model" identifier="locality"
                                         :label="$t('patients.locality')"
                                         :display-custom-label="(row) => row.attributes.name" track-by="id"
                                         :options="localityOptions" :placeholder="$t('patients.locality')"
                                         :disabled="is_saving" :has-error="$v.patient.locality.$error">
                            <template v-slot:errors>
                                <p v-if="!$v.patient.locality.required">
                                    {{$t('validation.x_is_required',{x: $t('patients.locality')})}}
                                </p>
                            </template>
                        </FormInputSelect>
                        <FormInputSelect v-model="$v.patient.third_party.$model" identifier="third_party"
                                         :label="$t('patients.third_party')" :options="thirdPartyOptions"
                                         :placeholder="$t('patients.third_party')" :disabled="is_saving"
                                         :has-error="$v.patient.third_party.$error">
                            <template v-slot:errors>
                                <p v-if="!$v.patient.third_party.required">
                                    {{$t('validation.x_is_required',{x: $t('patients.third_party')})}}
                                </p>
                            </template>
                        </FormInputSelect>
                    </FormGroupThree>
                </div>
            </Form>
            <Form class="form" @submit="save" :disabled="is_saving">
                <SectionHeader :title="$t('patients.other_details')"></SectionHeader>
                <div class="form-body">
                    <FormGroupThree>
                        <FormInputSelect v-model="$v.patient.ward.$model" identifier="ward"
                                         :label="$t('patients.ward')" :options="wardOptions"
                                         :display-custom-label="(row) => row.attributes.name" track-by="id"
                                         :placeholder="$t('patients.ward')" :disabled="is_saving"
                                         :has-error="$v.patient.ward.$error">
                            <template v-slot:errors>
                                <p v-if="!$v.patient.ward.required">
                                    {{$t('validation.x_is_required',{x: $t('patients.ward')})}}
                                </p>
                            </template>
                        </FormInputSelect>
                        <FormInputText v-model="$v.patient.admitted_on.$model"
                                       identifier="admitted_on"
                                       :label="$t('patients.date_of_admission_short')" type="date"
                                       :placeholder="$t('patients.date_of_admission_short')" :disabled="is_saving"
                                       :has-error="$v.patient.admitted_on.$error">
                            <template v-slot:errors>
                                <p v-if="!$v.patient.admitted_on.required">
                                    {{$t('validation.x_is_required',{x: $t('patients.date_of_admission')})}}
                                </p>
                            </template>
                        </FormInputText>
                        <FormInputText v-model="$v.patient.admitted_from.$model"
                                       identifier="admitted_from"
                                       :label="$t('patients.admitted_from')"
                                       :placeholder="$t('patients.admitted_from')" :disabled="is_saving"
                                       :has-error="$v.patient.admitted_from.$error">
                            <template v-slot:errors>
                                <p v-if="!$v.patient.admitted_from.required">
                                    {{$t('validation.x_is_required',{x: $t('patients.admitted_from')})}}
                                </p>
                            </template>
                        </FormInputText>
                    </FormGroupThree>
                    <FormGroupThree>
                        <FormInputSelect v-model="$v.patient.media_permission.$model" identifier="media_permission"
                                         :label="$t('patients.media')" :options="mediaPermissionOptions"
                                         :placeholder="$t('patients.media')" :disabled="is_saving"
                                         :has-error="$v.patient.media_permission.$error">
                            <template v-slot:errors>
                                <p v-if="!$v.patient.media_permission.required">
                                    {{$t('validation.x_is_required',{x: $t('patients.media')})}}
                                </p>
                            </template>
                        </FormInputSelect>
                        <FormInputText v-model="$v.patient.max_daily_bookings.$model"
                                       identifier="max_daily_bookings" type="number"
                                       :label="$t('patients.max_daily_bookings')"
                                       :placeholder="$t('patients.max_daily_bookings')" :disabled="is_saving"
                                       :has-error="$v.patient.max_daily_bookings.$error">
                            <template v-slot:errors>
                                <p v-if="!$v.patient.max_daily_bookings.required">
                                    {{$t('validation.x_is_required',{x: $t('patients.max_daily_bookings')})}}
                                </p>
                                <p v-else-if="!$v.patient.max_daily_bookings.numeric">
                                    {{$t('validation.please_enter_number')}}
                                </p>
                            </template>
                        </FormInputText>
                        <FormInputText v-model="$v.patient.max_weekly_bookings.$model"
                                       identifier="max_weekly_bookings" type="number"
                                       :label="$t('patients.max_weekly_bookings')"
                                       :placeholder="$t('patients.max_weekly_bookings')" :disabled="is_saving"
                                       :has-error="$v.patient.max_weekly_bookings.$error">
                          <template v-slot:errors>
                            <p v-if="!$v.patient.max_weekly_bookings.required">
                              {{$t('validation.x_is_required',{x: $t('patients.max_weekly_bookings')})}}
                            </p>
                            <p v-else-if="!$v.patient.max_weekly_bookings.numeric">
                              {{$t('validation.please_enter_number')}}
                            </p>
                          </template>
                        </FormInputText>
                    </FormGroupThree>
                </div>
            </Form>
            <Form class="form" @submit="save" :disabled="is_saving">
                <SectionHeader :title="$t('patients.next_of_kin_details')"></SectionHeader>
                <div class="form-body next-of-kin">
                    <FormGroupThree v-for="(next_of_kin, i) in $v.patient.next_of_kin.$each.$iter" :key="next_of_kin.key" class="next-of-kin-row-group">
                        <FormInputText v-model="next_of_kin.name.$model" identifier="next_of_kin_name"
                                       :label="$t('patients.name')"
                                       :placeholder="$t('patients.name')" :disabled="is_saving"
                                       :has-error="next_of_kin.name.$error">
                            <template v-slot:errors>
                                <p v-if="!next_of_kin.name.required">
                                    {{$t('validation.x_is_required',{x: $t('patients.name')})}}
                                </p>
                            </template>
                        </FormInputText>
                        <FormInputText v-model="next_of_kin.phone.$model" identifier="next_of_kin_phone"
                                       :label="$t('patients.phone')"
                                       :placeholder="$t('patients.phone')" :disabled="is_saving"
                                       :has-error="next_of_kin.phone.$error">
                            <template v-slot:errors>
                                <p v-if="!next_of_kin.phone.required">
                                    {{$t('validation.x_is_required',{x: $t('patients.phone')})}}
                                </p>
                            </template>
                        </FormInputText>
                        <FormInputText v-model="next_of_kin.id_number.$model" identifier="next_of_kin_id_number"
                                       :label="$t('patients.id_number')"
                                       :placeholder="$t('patients.id_number')" :disabled="is_saving"
                                       :has-error="next_of_kin.id_number.$error">
                          <template v-slot:errors>
                            <p v-if="!next_of_kin.id_number.required">
                              {{$t('validation.x_is_required',{x: $t('patients.id_number')})}}
                            </p>
                          </template>
                        </FormInputText>
                        <Button className="--secondary --outline --mini" :onclick="() => removeNextOfKin(i)"
                                class="button-remove">
                          <font-awesome-icon :icon="['far','times']"/>
                        </Button>
                    </FormGroupThree>
                </div>

              <div class="row-add-container" v-if="$v.patient.next_of_kin.$model.length < 3">
                <Button className="--primary --outline --small" :onclick="addNextOfKin">
                  {{$t('patients.add_next_of_kin')}}
                </Button>
              </div>
            </Form>
        </main>
    </div>
</template>

<script>
    import Headbar from "../../components/headbar/Headbar";
    import Form from "../../components/form/Form";
    import SectionHeader from "../../components/SectionHeader";
    import Button from "../../components/Button";
    import FormInputText from "../../components/form/FormInputText";
    import FormInputSelect from "../../components/form/FormInputSelect";
    import {required, numeric} from 'vuelidate/lib/validators'
    import FormGroupThree from "../../components/form/FormGroupThree";

    export default {
        name: "patients-create-page",
        components: {FormGroupThree, FormInputText, FormInputSelect, Button, SectionHeader, Form, Headbar},
        data () {
            return {
                patient: {
                    first_name: null,
                    last_name: null,
                    id_number: null,
                    date_of_birth: null,
                    gender: null,
                    locality: null,
                    next_of_kin: [],
                    third_party: null,
                    ward: null,
                    admitted_on: null,
                    admitted_from: null,
                    media_permission: null,
                    max_daily_bookings: null,
                    max_weekly_bookings: null,
                },
                genderOptions: [
                    'male', 'female'
                ],
                localityOptions: [],
                thirdPartyOptions: [
                    'yes', 'no'
                ],
                wardOptions: [],
                mediaPermissionOptions: [
                    'yes', 'no'
                ],
                is_saving: false,
                is_loading_ward_options: false,
                is_loading_locality_options: false,
            }
        },
        validations: {
            patient: {
                first_name: {
                    required
                },
                last_name: {
                  required
                },
                id_number: {
                    required
                },
                date_of_birth: {
                    required
                },
                gender: {
                    required
                },
                locality: {
                    required
                },
                next_of_kin: {
                  $each: {
                    name: {required},
                    phone: {required},
                    id_number: {required}
                  }
                },
                third_party: {
                    required
                },
                ward: {
                    required
                },
                admitted_on: {
                    required
                },
                admitted_from: {
                    required
                },
                media_permission: {
                    required
                },
                max_daily_bookings: {
                    required, numeric
                },
                max_weekly_bookings: {
                  required, numeric
                }
            }
        },
        methods: {
            removeNextOfKin(index) {
              index = parseInt(index);
              if (index >= this.$v.patient.next_of_kin.$model.length)
                return;

              this.$v.patient.next_of_kin.$model.splice(index, 1);
            },
            addNextOfKin() {
              this.$v.patient.next_of_kin.$touch();

              if (!this.$v.patient.next_of_kin.$anyError && this.$v.patient.next_of_kin.$model.length < 3)
                this.$v.patient.next_of_kin.$model.push({
                  key: this.$v.patient.next_of_kin.$model.length + 1,
                  name: null,
                  phone: null,
                  id_number: null,
                });
            },
            retrieveWardOptions() {
                this.is_loading_ward_options = false;
                this.$axios.get('wards/list')
                    .then(({data}) => {
                        this.is_loading_ward_options = false;
                        this.wardOptions = data.data;
                    })
                    .catch(e => {
                        this.is_loading_ward_options = false;

                        this.$notify({
                            title: this.$t('error'),
                            text: this.$larerror(e.response.data, this.$t('wards.error_retrieve')),
                            type: 'error',
                        });
                    });
            },
            retrieveLocalityOptions() {
                this.is_loading_locality_options = false;
                this.$axios.get('localities/list')
                    .then(({data}) => {
                        this.is_loading_locality_options = false;
                        this.localityOptions = data.data;
                    })
                    .catch(e => {
                        this.is_loading_locality_options = false;

                        this.$notify({
                            title: this.$t('error'),
                            text: this.$larerror(e.response.data, this.$t('localities.error_retrieve')),
                            type: 'error',
                        });
                    });
            },
            save() {
                this.$v.patient.$touch();
                if (this.$v.patient.$anyError || this.is_saving)
                    return;

                this.is_saving = true;

                const payload = Object.assign({}, this.$v.patient.$model);
                payload.media_permission = payload.media_permission === 'yes';
                payload.ward_id = payload.ward.id;
                payload.locality_id = payload.locality.id;
                payload.next_of_kin = payload.next_of_kin.map(n=>({name: n.name, phone: n.phone, id_number: n.id_number}))

                delete payload.ward;
                delete payload.locality;

                this.$axios.post('patients', payload).then(({data}) => {
                    this.$notify({
                        text: this.$t('patients.success_created'),
                        type: 'success',
                    });

                    this.is_saving = false;

                    this.$router.push({name: 'patients-index'});
                }).catch(e => {
                    this.is_saving = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data.errors, this.$t('patients.error_create')),
                        type: 'error',
                    });
                });
            }
        },

        mounted() {
            this.retrieveWardOptions();
            this.retrieveLocalityOptions();
        },

        head() {
            return {
                title: {
                    inner: this.$t('patients.add_patient')
                },
            }
        }
    }
</script>

<style lang="scss" scoped>
    .page-container {
        main {
            @apply flex flex-row flex-wrap;

            .form {
                @apply mb-8;

                width: calc(50% - 1rem);

                &:nth-child(odd) {
                    @apply mr-4;
                }

                &:nth-child(even) {
                    @apply ml-4;
                }

                .form-body {
                    @apply px-8 py-7;

                  &.next-of-kin{
                    .next-of-kin-row-group {
                      .input-group {
                        @apply w-auto flex-1;
                      }

                      .button-remove {
                        @apply flex-none self-start mt-9;
                      }
                    }
                  }
                }

                .row-add-container {
                  @apply px-8 pb-8 mt-auto;
                }
            }
        }
    }
</style>