<template>
    <div class="page-container">
        <Headbar>
            <template v-slot:left>
                <h1>{{ $t('bookings.add_booking') }}</h1>
            </template>
            <template v-slot:right>
                <Button className="--primary --outline --small" :class="{spinner: is_saving}" :onclick="save"
                        :disabled="is_ward_closed">
                    {{ $t('save') }}
                </Button>
            </template>
        </Headbar>
        <main>
            <div class="form-area">
                <Form class="form" @submit="save" :disabled="is_saving">
                    <SectionHeader :title="$t('bookings.booking_details')"></SectionHeader>
                    <div class="form-body">
                        <FormGroupTwo class="booking-details-row">
                            <FormInputSelect v-model="$v.ward.$model" identifier="ward"
                                             :label="$t('bookings.ward')" :options="wardOptions"
                                             :placeholder="$t('bookings.ward')" :disabled="is_saving"
                                             :has-error="$v.ward.$error || is_ward_closed" @updated="selectedWard"
                                             :display-custom-label="(row) => row.attributes.name" track-by="id">
                                <template v-slot:errors>
                                    <p v-if="!$v.ward.required">
                                        {{ $t('validation.x_is_required', {x: $t('bookings.ward')}) }}
                                    </p>
                                    <p v-else-if="is_ward_closed">
                                        {{ $t('wards.error_closed') }}
                                    </p>
                                </template>
                            </FormInputSelect>
                            <FormInputSelect v-model="$v.patient.$model" identifier="patient"
                                             :label="$t('bookings.patient')" :options="patientOptions"
                                             :placeholder="$t('bookings.patient')" :disabled="is_saving || !ward"
                                             :has-error="$v.patient.$error"
                                             @updated="selectedPatient"
                                             :display-custom-label="(row) => `${row.attributes.last_name} ${row.attributes.first_name} - ${row.attributes.id_number}`"
                                             track-by="id">
                                <template v-slot:errors>
                                    <p v-if="!$v.patient.required">
                                        {{ $t('validation.x_is_required', {x: $t('bookings.patient')}) }}
                                    </p>
                                </template>
                            </FormInputSelect>
                        </FormGroupTwo>
                        <FormGroupTwo v-for="(booking, i) in $v.bookings.$each.$iter" :key="booking.key.$model"
                                      class="booking-row-group">
                            <FormInputDateTime v-model="booking.date.$model" identifier="date"
                                               :label="$t('bookings.date')" :only-date="true" @input="selectedDate(i)"
                                               formatted="Do MMM YYYY" :placeholder="$t('bookings.date')"
                                               :disabled="is_saving" :has-error="booking.date.$error"
                                               output-format="YYYY-MM-DD"></FormInputDateTime>
                            <FormInputSelect v-model="booking.timeslot.$model" identifier="timeslot"
                                             :label="$t('bookings.timeslot')" :options="timeslotOptions"
                                             :placeholder="$t('bookings.timeslot')"
                                             :disabled="is_saving || !patient || !booking.date"
                                             :has-error="booking.timeslot.$error"
                                             :display-custom-label="(row) => `${row.from} - ${row.to}`"
                                             track-by="id">
                                <template v-slot:errors>
                                    <p v-if="!booking.timeslot.required">
                                        {{ $t('validation.x_is_required', {x: $t('bookings.timeslot')}) }}
                                    </p>
                                </template>
                            </FormInputSelect>
                            <Button className="--secondary --outline --mini" :onclick="() => removeBooking(i)"
                                    class="button-remove" :class="{only: $v.bookings.$model.length === 1}"
                                    :disabled="$v.bookings.$model.length === 1">
                                <font-awesome-icon :icon="['far','times']"/>
                            </Button>
                        </FormGroupTwo>
                    </div>

                    <div class="row-add-container">
                        <Button className="--primary --outline --small" :onclick="addBooking"
                                :disabled="!$v.ward.$model || !$v.patient.$model || $v.ward.$error || $v.patient.$error">
                            {{ $t('bookings.add_booking') }}
                        </Button>
                    </div>
                </Form>
                <Form class="form" @submit="save" :disabled="is_saving">
                    <SectionHeader :title="$t('bookings.visitor_details')"></SectionHeader>
                    <div class="form-body">
                        <div class="input-group multiselect-input" :class="{error: $v.selected_visitors.$error}">
                            <label for="visitor">{{$t('bookings.registered_visitors')}}</label>
                            <multiselect ref="visitors-multiselect" v-model="$v.selected_visitors.$model" identifier="visitor" :multiple="true"
                                          :label="$t('bookings.registered_visitors')" :options="visitorOptions"
                                          :placeholder="$t('bookings.registered_visitors')" :showLabels="false"
                                          :disabled="is_saving || is_loading_visitor_options || !patient"
                                          :has-error="$v.selected_visitors.$error" :internal-search="false" @search-change="searchVisitors" :preserveSearch="true"
                                          :custom-label="(row) => `${row.attributes.name} - ${row.attributes.id_number} - ${row.attributes.phone}`"
                                         track-by="id">
                            </multiselect>
                            <div class="form-errors" v-if="$v.selected_visitors.$error">
                                <p v-if="!$v.selected_visitors.required">{{$t('validation.x_is_required', {x: $t('bookings.visitors')})}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="border-div"></div>
                    <div class="form-body">
                        <FormGroupThree v-for="(visitor, i) in $v.visitors.$each.$iter" :key="visitor.key.$model"
                                        class="visitor-row-group">
                            <FormInputText v-model="visitor.name.$model" :identifier="`visitor-${+i}-name`"
                                           :label="$t('bookings.visitor_x_name', {x: +i+1})"
                                           :placeholder="$t('bookings.name')" :disabled="is_saving"
                                           :has-error="visitor.name.$error">
                                <template v-slot:errors>
                                    <p v-if="!visitor.name.required || visitors == null">
                                        {{ $t('validation.x_is_required', {x: $t('bookings.name')}) }}
                                    </p>
                                </template>
                            </FormInputText>
                            <FormInputText v-model="visitor.id_number.$model"
                                           :identifier="`visitor-${+i}-id_number`"
                                           :label="$t('bookings.id_number')"
                                           :placeholder="$t('bookings.id_number')" :disabled="is_saving"
                                           :has-error="visitor.id_number.$error">
                                <template v-slot:errors>
                                    <p v-if="!visitor.id_number.required || visitors == null">
                                        {{ $t('validation.x_is_required', {x: $t('bookings.id_number')}) }}
                                    </p>
                                </template>
                            </FormInputText>
                            <FormInputText v-model="visitor.phone.$model" :identifier="`visitor-${+i}-phone`"
                                           :label="$t('bookings.phone')"
                                           :placeholder="$t('bookings.phone')" :disabled="is_saving"
                                           :has-error="visitor.phone.$error">
                                <template v-slot:errors>
                                    <p v-if="!visitor.phone.required || visitors != null">
                                        {{ $t('validation.x_is_required', {x: $t('bookings.phone')}) }}
                                    </p>
                                </template>
                            </FormInputText>
                            <Button className="--secondary --outline --mini" :onclick="() => removeVisitor(i)"
                                    class="button-remove" :class="{only: $v.visitors.$model.length === 1}"
                                    :disabled="$v.visitors.$model.length === 1">
                                <font-awesome-icon :icon="['far','times']"/>
                            </Button>
                        </FormGroupThree>
                    </div>

                    <div class="row-add-container">
                        <Button className="--primary --outline --small" :onclick="addVisitor"
                                :disabled="!this.visitors[this.visitors.length-1].name || !this.visitors[this.visitors.length-1].id_number || !this.visitors[this.visitors.length-1].phone">
                            {{ $t('bookings.add_visitor') }}
                        </Button>
                    </div>
                </Form>
            </div>
            <div class="divider"></div>
            <div class="table-area">
                <vue-good-table
                    mode="remote"
                    styleClass="vgt-table striped vgt-custom"
                    :columns="columns"
                    :rows="ward_bookings"
                    :isLoading.sync="is_loading_ward_bookings"
                    :search-options="{
                enabled: false,
            }"
                    :pagination-options="{
                enabled: true,
                mode: 'records',
                dropdownAllowAll: false,
                perPage: 15,
                perPageDropdownEnabled: false,
                rowsPerPageLabel: $t('x_per_page', {x: $t('bookings.bookings')}),
            }"
                    :totalRows="totalRecords"
                    @on-page-change="onPageChange"
                    @on-sort-change="onSortChange">
                    <template slot="table-row" slot-scope="props">
                        <div v-if="props.column.field === 'date'">
                            <p>{{ $moment.utc(props.row.attributes.from).format('DD/MM/YYYY') }}</p>
                        </div>
                        <div v-if="props.column.field === 'time'">
                            <p>{{ $moment.utc(props.row.attributes.from).format('HH:mm') }} -
                                {{ $moment.utc(props.row.attributes.to).format('HH:mm') }}</p>
                        </div>
                        <div v-if="props.column.field === 'visitor_1_name'">
                            <p v-if="props.row.relationships && props.row.relationships.visitors && props.row.relationships.visitors.data.length >= 1">
                                {{ props.row.relationships.visitors.data[0].attributes.name }}
                            </p>
                        </div>
                        <div v-else-if="props.column.field === 'visitor_1_id'">
                            <p v-if="props.row.relationships && props.row.relationships.visitors && props.row.relationships.visitors.data.length >= 1">
                                {{ props.row.relationships.visitors.data[0].id }}
                            </p>
                        </div>
                        <div v-else-if="props.column.field === 'visitor_2_name'">
                            <p v-if="props.row.relationships && props.row.relationships.visitors && props.row.relationships.visitors.data.length >= 2">
                                {{ props.row.relationships.visitors.data[1].attributes.name }}
                            </p>
                        </div>
                        <div v-else-if="props.column.field === 'visitor_2_id'">
                            <p v-if="props.row.relationships && props.row.relationships.visitors && props.row.relationships.visitors.data.length >= 2">
                                {{ props.row.relationships.visitors.data[1].id }}
                            </p>
                        </div>
                        <span v-else>
                      {{ props.formattedRow[props.column.field] }}
                    </span>
                    </template>
                </vue-good-table>
            </div>
        </main>
    </div>
</template>

<script>
import Headbar from "../../components/headbar/Headbar";
import Form from "../../components/form/Form";
import SectionHeader from "../../components/SectionHeader";
import FormInputText from "../../components/form/FormInputText";
import {required, requiredIf} from 'vuelidate/lib/validators'
import FormGroupTwo from "../../components/form/FormGroupTwo";
import FormGroupThree from "../../components/form/FormGroupThree";
import FormInputSelect from "../../components/form/FormInputSelect";
import FormInputDateTime from "../../components/form/FormInputDateTime";
import Button from "../../components/Button";
import _ from "lodash";

export default {
    name: "bookings-create-page",
    components: {
        Button,
        FormGroupTwo,
        FormInputDateTime,
        FormInputSelect,
        FormGroupThree,
        FormInputText,
        SectionHeader,
        Form,
        Headbar
    },
    data() {
        return {
            ward: null,
            patient: null,
            selected_visitors: [],
            visitors: [
                {key: 1, name: null, id_number: null, phone: null},
            ],
            bookings: [
                {
                    key: 1, date: null, timeslot: null,
                }
            ],
            lastDateChosen: null,
            slots: null,
            columns: [
                {
                    label: this.$t('bookings.id'),
                    field: 'id',
                    sortable: false,
                },
                {
                    label: this.$t('bookings.date'),
                    field: 'date',
                    sortable: false,
                },
                {
                    label: this.$t('bookings.ward'),
                    field: 'relationships.patient.data.attributes.ward',
                    sortable: false,
                },
                {
                    label: this.$t('bookings.full_name'),
                    field: row => `${row.relationships.patient.data.attributes.first_name} ${row.relationships.patient.data.attributes.last_name}`,
                    sortable: false,
                },
                {
                    label: this.$t('bookings.time'),
                    field: 'time',
                    sortable: false,
                },
                {
                    label: this.$t('bookings.visitor_x_name', {x: 1}),
                    field: 'visitor_1_name',
                    sortable: false,
                },
                {
                    label: this.$t('bookings.visitor_x_id', {x: 1}),
                    field: 'visitor_1_id',
                    sortable: false,
                },
                {
                    label: this.$t('bookings.visitor_x_name', {x: 2}),
                    field: 'visitor_2_name',
                    sortable: false,
                },
                {
                    label: this.$t('bookings.visitor_x_id', {x: 2}),
                    field: 'visitor_2_id',
                    sortable: false,
                },
            ],
            ward_bookings: [],
            totalRecords: null,
            serverParams: {},
            is_saving: false,
            is_loading_ward_options: false,
            is_loading_patient_options: false,
            is_loading_timeslot_options: false,
            is_loading_visitor_options: false,
            is_loading_calendar: false,
            is_loading_ward_bookings: false,
            is_ward_closed: false,
            patientOptions: [],
            wardOptions: [],
            timeslotOptions: [],
            visitorOptions: [],
            visitor_exists: false
        }
    },
    validations: {
        ward: {required},
        patient: {required},
        selected_visitors: {required: requiredIf(function() {
                return !(this.visitors[0].name || this.visitors[0].id_number || this.visitors[0].phone);
            })
        },
        visitors: {
            $each: {
                key: {},
                name: {
                    required: requiredIf(function(visitor) {
                        return !this.selected_visitors.length || this.visitors.length > 1 || visitor.id_number || visitor.phone;
                    })
                },
                id_number: {
                    required: requiredIf(function(visitor) {
                        return !this.selected_visitors.length || this.visitors.length > 1 || visitor.name || visitor.phone;
                    })
                },
                phone: {
                    required: requiredIf(function(visitor) {
                        return !this.selected_visitors.length || this.visitors.length > 1 || visitor.name || visitor.id_number;
                    })
                },
            }
        },
        bookings: {
            $each: {
                key: {},
                date: {required},
                timeslot: {required},
            }
        }
    },
    methods: {
        searchVisitors: _.debounce (function (searchQuery, id) {
            if(!searchQuery) return;

            this.is_loading_visitor_options = true;

            let params = {term: searchQuery};

            this.$axios.get(`visitors/list`, {params: params})
                .then(({data}) => {
                    this.visitorOptions = data.data;
                    this.is_loading_visitor_options = false;

                    this.$refs['visitors-multiselect'].isOpen = true;
                })
                .catch(e => {
                    this.is_loading_visitor_options = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('visitors.error_retrieve')),
                        type: 'error',
                    });
                });
        }, 500),
        // TODO remove unused functionality
        removeVisitor(index) {
            index = parseInt(index);
            if (index >= this.$v.visitors.$model.length)
                return;

            this.$v.visitors.$model.splice(index, 1);
        },
        addVisitor() {
            this.$v.visitors.$touch();

            if (!this.$v.visitors.$anyError)
                this.$v.visitors.$model.push({
                    key: this.$v.visitors.$model.length + 1,
                    name: null,
                    id_number: null,
                    phone: null
                });
        },
        removeBooking(index) {
            index = parseInt(index);
            if (index >= this.$v.bookings.$model.length)
                return;

            this.$v.bookings.$model.splice(index, 1);
        },
        addBooking() {
            this.$v.bookings.$touch();

            if (!this.$v.bookings.$anyError)
                this.$v.bookings.$model.push({
                    key: this.$v.bookings.$model.length + 1,
                    date: null,
                    timeslot: null,
                });
        },
        selectedWard() {
            this.is_ward_closed = false;
            this.patient = null;
            this.bookings = this.bookings.map(b => ({...b, timeslot: null}));

            if (this.ward.attributes.is_closed === 1) {
                this.is_ward_closed = true;
            }

            this.retrieveWardBookings();
            this.retrievePatientOptions();
        },
        selectedPatient() {
            this.bookings = this.bookings.map(b => ({...b, timeslot: null}));

            this.retrieveWardBookings();
            this.retrievePatientTimeslotOptions();
        },
        selectedDate(i) {
            this.lastDateChosen = this.bookings[i].date;
            this.bookings[i].timeslot = null;

            this.retrieveWardBookings();
            this.retrievePatientTimeslotOptions();
        },
        retrieveWardOptions() {
            this.is_loading_ward_options = false;
            this.$axios.get('wards/list')
                .then(({data}) => {
                    this.wardOptions = data.data;
                    this.is_loading_ward_options = false;
                })
                .catch(e => {
                    this.is_loading_ward_options = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('wards.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        retrievePatientOptions() {
            if (!this.ward)
                return;

            this.is_loading_patient_options = false;
            this.$axios.get(`wards/${this.ward.id}/patients`)
                .then(({data}) => {
                    this.patientOptions = data.data;
                    this.is_loading_patient_options = false;
                })
                .catch(e => {
                    this.is_loading_patient_options = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('patients.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        retrievePatientTimeslotOptions() {
            if (!this.patient || !this.lastDateChosen)
                return;

            this.is_loading_timeslot_options = false;
            this.$axios.get(`patients/${this.patient.id}/availability`, {params: {date: this.lastDateChosen}})
                .then(({data}) => {
                    this.timeslotOptions = data.map(d => ({
                        id: d.id,
                        from: this.$moment(d.from, 'HH:mm:ss').format('HH:mm'),
                        to: this.$moment(d.to, 'HH:mm:ss').format('HH:mm'),
                        '$isDisabled': d.status.toLocaleLowerCase() !== 'available'
                    }));
                    this.is_loading_timeslot_options = false;
                })
                .catch(e => {
                    this.is_loading_timeslot_options = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('bookings.error_retrieve_timeslots')),
                        type: 'error',
                    });
                });
        },
        async getVisitorIds() {
            const ids = [];

            for (let x = 0; x < this.visitors.length; x++) {
                if(!this.visitors[x].name || !this.visitors[x].id_number || !this.visitors[x].phone) break;

                const v = this.visitors[x];

                let exists = false;

                const filters = [
                    {
                        filter_by: 'id_number',
                        filter_operator: '=',
                        filter_value: v.id_number
                    }
                ];

                const encodedFilters = filters.map(f => btoa(JSON.stringify(f)));

                // check if visitor exists
                await this.$axios.get('visitors', {params: {filters: encodedFilters}})
                    .then(async ({data}) => {
                        if (data.data.length)
                            exists = data.data[0];

                        if (exists) {
                            this.$notify({
                                title: this.$t('error'),
                                text: this.$t('bookings.user_with_id_x_already_exists', {x: v.id_number}),
                                type: 'error',
                            });

                            this.visitor_exists = true;
                        } else {
                            // create visitor
                            await this.$axios.post(`visitors`, v).then(({data}) => {
                                ids.push(data.data.id);
                            });
                        }
                    });
            }

            return ids;
        },
        async save() {
            this.$v.$touch();
            if (this.$v.$anyError || this.is_saving)
                return;

            this.visitor_exists = false;
            this.is_saving = true;

            let visitor_ids = [];

            //array of new visitors
            if(this.visitors.length && this.visitors[0].name)
                visitor_ids = await this.getVisitorIds();

            //array of associated visitors
            if(this.selected_visitors.length) {
                this.selected_visitors.map((visitor) => visitor.id).forEach(id => {visitor_ids.push(id)});
            }

            if(this.visitor_exists) {
                this.is_saving = false;
                return;
            }

            for (const booking of this.$v.bookings.$model) {
                if (booking.$error)
                    continue;

                const payload = {};
                payload.from = `${booking.date} ${this.$moment(booking.timeslot.from, 'HH:mm').format('HH:mm:ss')}`;
                payload.to = `${booking.date} ${this.$moment(booking.timeslot.to, 'HH:mm').format('HH:mm:ss')}`;
                payload.visitors = visitor_ids;

                await this.$axios.post(`patients/${this.patient.id}/book`, payload).then(({data}) => {

                }).catch(e => {
                    this.is_saving = false;
                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data.errors, this.$t('bookings.error_create')),
                        type: 'error',
                    });
                });
            }

            this.is_saving = false;

            this.$router.push({name: 'bookings-index'});

            this.$notify({
                text: this.$t('bookings.success_created'),
                type: 'success',
            });

        },
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
            this.updateParams({page: params.currentPage});
            this.retrieveWardBookings();
        },
        onSortChange(params) {
            this.updateParams({sort: params});
            this.retrieveWardBookings();
        },
        removeParam(param) {
            this.$delete(this.serverParams, param);
        },
        retrieveWardBookings() {
            this.ward_bookings = [];
            if (!this.ward || !this.lastDateChosen)
                return;

            this.is_loading_ward_bookings = true;

            const filters = [
                {
                    filter_by: 'from',
                    filter_operator: ">=",
                    filter_value: `${this.lastDateChosen} 00:00:00`
                }, {
                    filter_by: 'to',
                    filter_operator: "<=",
                    filter_value: `${this.lastDateChosen} 23:59:59`
                }];

            // filter by patient
            if (this.patient)
                filters.push({
                    filter_by: 'patient_id',
                    filter_operator: "=",
                    filter_value: this.patient.id
                })

            const encodedFilters = filters.map(f => btoa(JSON.stringify(f)));

            this.$axios.get(`wards/${this.ward.id}/bookings`, {params: {filters: encodedFilters}})
                .then(({data}) => {
                    this.ward_bookings = data.data;
                    this.totalRecords = data.meta.total;
                    this.is_loading_ward_bookings = false;
                })
                .catch(e => {
                    this.is_loading_ward_bookings = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('bookings.error_retrieve')),
                        type: 'error',
                    });
                });
        }
    },
    mounted() {
        this.retrieveWardOptions();
    },
    head() {
        return {
            title: {
                inner: this.$t('bookings.add_booking')
            },
        }
    }
}
</script>

<style lang="scss" scoped>
.page-container {
    main {
        @apply px-0;

        .form-area {
            @apply flex flex-row flex-wrap px-9;

            .form {
                @apply mb-8;

                width: calc(50% - 1rem);

                &:nth-child(odd) {
                    @apply mr-4;
                }

                &:nth-child(even) {
                    @apply ml-4;
                }

                .form-body {
                    @apply px-8 py-7;

                    .booking-details-row {
                        @apply pr-7;
                    }

                    .booking-row-group {
                        .input-group {
                            @apply w-auto flex-1;
                        }

                        .button-remove {
                            @apply flex-none self-start mt-9;

                            &.only {
                                @apply opacity-0 cursor-default;
                            }
                        }
                    }

                    .visitor-row-group {
                        .input-group {
                            @apply w-auto flex-1;
                        }

                        .button-remove {
                            @apply flex-none self-start mt-9;

                            &.only {
                                @apply opacity-0 cursor-default;
                            }
                        }
                    }
                }

                .row-add-container {
                    @apply px-8 pb-8 mt-auto;
                }

                .border-div {
                    @apply border-t-2 border-grey-light w-11/12 mx-auto;
                }
            }
        }

        .divider {
            @apply w-full border-b-2 border-primary;
        }

        .table-area {
            @apply px-9 py-7;
        }
    }

    .input-group  {
        &.multiselect-input {
            @apply w-full flex flex-col items-start mb-5;

            label {
                @apply text-sm text-black font-bold mb-3;
            }

            &.error {
                .input {
                    @apply border-negative;
                }
            }

            & > .form-errors {
                @apply mt-3 mb-1;

                p {
                    @apply text-negative italic text-sm py-1 ml-4;
                }
            }
        }
    }
}
</style>