<template>
  <div class="discharged-modal">
    <ModalContainer :title="$t('patients.discharge_patient')" identifier="discharged-modal" :closable="true">
      <Form class="form" @submit="save" :disabled="is_saving">
        <FormInputDateTime v-model="$v.discharged_on.$model" identifier="discharged_on"
                           :label="$t('patients.discharged_on')" :only-date="true"
                           formatted="Do MMM YYYY" :placeholder="$t('patients.discharged_on')"
                           :disabled="is_saving" :has-error="$v.discharged_on.$error"
                           output-format="YYYY-MM-DD 00:00:00"></FormInputDateTime>
        <Button type="submit" className="--primary --small" :class="{spinner: is_saving}">
          {{ $t('patients.discharge_patient') }}
        </Button>
      </Form>
    </ModalContainer>
  </div>
</template>

<script>
import ModalContainer from "../modal/ModalContainer";
import Button from "@/components/Button.vue";
import {required} from 'vuelidate/lib/validators'
import Form from "@/components/form/Form.vue";
import FormInputDateTime from "@/components/form/FormInputDateTime.vue";
import FormInputCheck from "@/components/form/FormInputCheck.vue";

export default {
  name: "DischargedModal",
  components: {Form, FormInputCheck, FormInputDateTime, ModalContainer, Button},
  data() {
    return {
      discharged_on: null,
      is_saving: false,
    }
  },
  validations: {
    discharged_on: {
      required
    },
  },
  methods: {
    close(status) {
      this.$modal.hide('discharged-modal', status);
    },
    save() {
      this.$v.$touch();
      if (this.$v.$anyError || this.is_saving)
        return;

      this.is_saving = true;

      this.$axios.put(`patients/${this.$route.params.id}/discharge`, {
        discharged_on:this.discharged_on,
      }).then(({data}) => {
        this.$notify({
          text: this.$t('patients.success_updated'),
          type: 'success',
        });

        this.is_saving = false;
        this.close(true);
      }).catch(e => {
        this.is_saving = false;

        this.$notify({
          title: this.$t('error'),
          text: this.$larerror(e.response.data.errors, this.$t('patients.error_discharged')),
          type: 'error',
        });
      });
    },
  },
}
</script>

<style lang="scss" scoped>
.discharged-modal{
}
</style>