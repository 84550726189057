<template>
    <div class="page-container">
        <Headbar>
            <template v-slot:left>
                <Select v-if="selected_report && selected_report.identifier === 'patient_data'" v-model="fields" :options="fieldOptions"
                        :multiple="true" label="name"
                        :placeholder="$t('reports.select_fields')" trackBy="column" :disabled="is_generating"
                        className="filter"/>

                <Form class="date-form"
                      v-if="selected_report && (selected_report.identifier === 'ward_bookings' || selected_report.identifier === 'bookings' || selected_report.identifier === 'visitors')"
                      @submit="()=>false" :disabled="is_generating">
                    <FormInputDateTime className="filter" v-model="$v.date.from.$model" identifier="date_from"
                                       :inline-input="true" :label="$t('reports.date_from')" :only-date="true"
                                       formatted="Do MMM YYYY" :placeholder="$t('reports.date_from')"
                                       :disabled="is_generating" :has-error="$v.date.from.$error"
                                       output-format="DD-MM-YYYY"></FormInputDateTime>
                    <FormInputDateTime className="filter" v-model="$v.date.to.$model" identifier="date_to"
                                       :inline-input="true" :label="$t('reports.date_to')" :only-date="true"
                                       formatted="Do MMM YYYY" :placeholder="$t('reports.date_to')"
                                       :disabled="is_generating" :has-error="$v.date.to.$error"
                                       output-format="DD-MM-YYYY"></FormInputDateTime>
                </Form>
            </template>
            <template v-slot:right>
                <div v-if="selected_report && selected_report.identifier === 'visitors'" class="visitors-check">
                    <p>{{$t('reports.attended')}}</p>
                    <FormInputCheck class="p-visitors" v-model="visitors_attended"/>
                </div>
                <div v-if="selected_report && selected_report.identifier === 'visitors'" class="visitors-check">
                    <p>{{$t('reports.didnt_attend')}}</p>
                    <FormInputCheck class="p-visitors" v-model="visitors_not_attended"/>
                </div>

                <Select v-if="selected_report && (selected_report.identifier === 'ward_bookings' || selected_report.identifier === 'visitors')" v-model="ward"
                        :options="wardOptions"
                        :placeholder="$t('wards.select_ward')" :custom-label="(row) => row.attributes.name"
                        track-by="id" :disabled="is_generating" className="filter"/>
            </template>
        </Headbar>
        <main>
            <div class="reports-row">
                <button v-for="report in reportOptions" v-if="$store.getters.hasAnyRole(report.need_roles)"
                        class="report-button-wrapper"
                        :class="{selected: selected_report && report.identifier === selected_report.identifier}"
                        @click="selectReport(report)">
                    <div class="report-button">
                        <span>{{ report.title }}</span>
                    </div>
                </button>
            </div>
            <div class="export-row">
                <h3>{{ $t('reports.export_report') }}</h3>

                <div class="row">
                    <button v-for="type in selected_report && selected_report.typeOptions" class="type-button-wrapper"
                            :class="{spinner: is_generating && type.name === selected_type.name}"
                            @click="selectType(type)">
                        <div class="type-button">
                            <font-awesome-icon :icon="[type.icon.type, type.icon.name]"/>
                            <span>{{ type.name }}</span>
                        </div>
                    </button>
                </div>
            </div>

            <vue-html2pdf v-if="bookings_metadata && bookings_data"
                          :float-layout="true"
                          :enable-download="true"
                          filename="Bookings"
                          :pdf-quality="1.5"
                          :manual-pagination="true"
                          pdf-format="a4"
                          pdf-orientation="landscape"
                          pdf-content-width="100%"
                          @beforeDownload="beforeDownload($event)"
                          @hasDownloaded="hasDownloaded($event)"
                          ref="html2Pdf1">

                <section slot="pdf-content" class="pdf-content">
                    <div class="pdf-content-container">
                        <div v-for="(ward, wardIndex) in modified_bookings_data" v-if="wardIndex <= 6">
                            <div v-for="(recordArray, recordArrayIndex) in ward.records" :key="recordArrayIndex">
                                <div class="pdf-content-header">
                                    <p>{{ bookings_metadata.date_exported }}</p>
                                    <p>{{ bookings_metadata.exported_at }}</p>
                                    <p>{{ bookings_metadata.exported_by }}</p>
                                    <p>Ward:{{ ward.name }}</p>
                                </div>
                                <vue-good-table
                                    styleClass="vgt-custom-bookings-pdf"
                                    :columns="bookingsColumns"
                                    :rows="recordArray"
                                    :search-options="{enabled: false,}"
                                    :pagination-options="{enabled: true, mode: 'records',perPage: 100,}"
                                    :sort-options="{ enabled: true, multipleColumns: true,}">
                                    <template slot="table-row" slot-scope="props">
                                        <div v-if="props.column.field === 'visitor_details'">
                                            <p v-for="visitor in props.row.visitor_details_split">{{ visitor }}</p>
                                        </div>
                                    </template>
                                </vue-good-table>
                                <div v-if="wardIndex+1 !== modified_bookings_data.length" class="html2pdf__page-break"/>
                            </div>
                        </div>
                    </div>
                </section>
            </vue-html2pdf>

            <vue-html2pdf v-if="bookings_metadata && bookings_data"
                          :float-layout="true"
                          :enable-download="true"
                          filename="Bookings"
                          :pdf-quality="1.5"
                          :manual-pagination="true"
                          pdf-format="a4"
                          pdf-orientation="landscape"
                          pdf-content-width="100%"
                          @beforeDownload="beforeDownload($event)"
                          @hasDownloaded="hasDownloaded($event)"
                          ref="html2Pdf2">

                <section slot="pdf-content" class="pdf-content">
                    <div class="pdf-content-container">
                        <div v-for="(ward, wardIndex) in modified_bookings_data" v-if="wardIndex > 6 && wardIndex <= 13">
                            <div v-for="(recordArray, recordArrayIndex) in ward.records" :key="recordArrayIndex">
                                <div class="pdf-content-header">
                                    <p>{{ bookings_metadata.date_exported }}</p>
                                    <p>{{ bookings_metadata.exported_at }}</p>
                                    <p>{{ bookings_metadata.exported_by }}</p>
                                    <p>Ward:{{ ward.name }}</p>
                                </div>
                                <vue-good-table
                                    styleClass="vgt-custom-bookings-pdf"
                                    :columns="bookingsColumns"
                                    :rows="recordArray"
                                    :search-options="{enabled: false,}"
                                    :pagination-options="{enabled: true, mode: 'records',perPage: 100,}"
                                    :sort-options="{ enabled: true, multipleColumns: true,}">
                                    <template slot="table-row" slot-scope="props">
                                        <div v-if="props.column.field === 'visitor_details'">
                                            <p v-for="visitor in props.row.visitor_details_split">{{ visitor }}</p>
                                        </div>
                                    </template>
                                </vue-good-table>
                                <div v-if="wardIndex+1 !== modified_bookings_data.length" class="html2pdf__page-break"/>
                            </div>
                        </div>
                    </div>
                </section>
            </vue-html2pdf>

            <vue-html2pdf v-if="bookings_metadata && bookings_data"
                          :float-layout="true"
                          :enable-download="true"
                          filename="Bookings"
                          :pdf-quality="1.5"
                          :manual-pagination="true"
                          pdf-format="a4"
                          pdf-orientation="landscape"
                          pdf-content-width="100%"
                          @beforeDownload="beforeDownload($event)"
                          @hasDownloaded="hasDownloaded($event)"
                          ref="html2Pdf3">

                <section slot="pdf-content" class="pdf-content">
                    <div class="pdf-content-container">
                        <div v-for="(ward, wardIndex) in modified_bookings_data" v-if="wardIndex > 13 && wardIndex <= 20">
                            <div v-for="(recordArray, recordArrayIndex) in ward.records" :key="recordArrayIndex">
                                <div class="pdf-content-header">
                                    <p>{{ bookings_metadata.date_exported }}</p>
                                    <p>{{ bookings_metadata.exported_at }}</p>
                                    <p>{{ bookings_metadata.exported_by }}</p>
                                    <p>Ward:{{ ward.name }}</p>
                                </div>
                                <vue-good-table
                                    styleClass="vgt-custom-bookings-pdf"
                                    :columns="bookingsColumns"
                                    :rows="recordArray"
                                    :search-options="{enabled: false,}"
                                    :pagination-options="{enabled: true, mode: 'records',perPage: 100,}"
                                    :sort-options="{ enabled: true, multipleColumns: true,}">
                                    <template slot="table-row" slot-scope="props">
                                        <div v-if="props.column.field === 'visitor_details'">
                                            <p v-for="visitor in props.row.visitor_details_split">{{ visitor }}</p>
                                        </div>
                                    </template>
                                </vue-good-table>
                                <div v-if="wardIndex+1 !== modified_bookings_data.length" class="html2pdf__page-break"/>
                            </div>
                        </div>
                    </div>
                </section>
            </vue-html2pdf>

            <vue-html2pdf v-if="bookings_metadata && bookings_data"
                          :float-layout="true"
                          :enable-download="true"
                          filename="Bookings"
                          :pdf-quality="1.5"
                          :manual-pagination="true"
                          pdf-format="a4"
                          pdf-orientation="landscape"
                          pdf-content-width="100%"
                          @beforeDownload="beforeDownload($event)"
                          @hasDownloaded="hasDownloaded($event)"
                          ref="html2Pdf4">

                <section slot="pdf-content" class="pdf-content">
                    <div class="pdf-content-container">
                        <div v-for="(ward, wardIndex) in modified_bookings_data" v-if="wardIndex > 20 && wardIndex <= 27">
                            <div v-for="(recordArray, recordArrayIndex) in ward.records" :key="recordArrayIndex">
                                <div class="pdf-content-header">
                                    <p>{{ bookings_metadata.date_exported }}</p>
                                    <p>{{ bookings_metadata.exported_at }}</p>
                                    <p>{{ bookings_metadata.exported_by }}</p>
                                    <p>Ward:{{ ward.name }}</p>
                                </div>
                                <vue-good-table
                                    styleClass="vgt-custom-bookings-pdf"
                                    :columns="bookingsColumns"
                                    :rows="recordArray"
                                    :search-options="{enabled: false,}"
                                    :pagination-options="{enabled: true, mode: 'records',perPage: 100,}"
                                    :sort-options="{ enabled: true, multipleColumns: true,}">
                                    <template slot="table-row" slot-scope="props">
                                        <div v-if="props.column.field === 'visitor_details'">
                                            <p v-for="visitor in props.row.visitor_details_split">{{ visitor }}</p>
                                        </div>
                                    </template>
                                </vue-good-table>
                                <div v-if="wardIndex+1 !== modified_bookings_data.length" class="html2pdf__page-break"/>
                            </div>
                        </div>
                    </div>
                </section>
            </vue-html2pdf>

            <vue-html2pdf v-if="bookings_metadata && bookings_data"
                          :float-layout="true"
                          :enable-download="true"
                          filename="Bookings"
                          :pdf-quality="1.5"
                          :manual-pagination="true"
                          pdf-format="a4"
                          pdf-orientation="landscape"
                          pdf-content-width="100%"
                          @beforeDownload="beforeDownload($event)"
                          @hasDownloaded="hasDownloaded($event)"
                          ref="html2Pdf5">

                <section slot="pdf-content" class="pdf-content">
                    <div class="pdf-content-container">
                        <div v-for="(ward, wardIndex) in modified_bookings_data" v-if="wardIndex > 27 && wardIndex <= 34">
                            <div v-for="(recordArray, recordArrayIndex) in ward.records" :key="recordArrayIndex">
                                <div class="pdf-content-header">
                                    <p>{{ bookings_metadata.date_exported }}</p>
                                    <p>{{ bookings_metadata.exported_at }}</p>
                                    <p>{{ bookings_metadata.exported_by }}</p>
                                    <p>Ward:{{ ward.name }}</p>
                                </div>
                                <vue-good-table
                                    styleClass="vgt-custom-bookings-pdf"
                                    :columns="bookingsColumns"
                                    :rows="recordArray"
                                    :search-options="{enabled: false,}"
                                    :pagination-options="{enabled: true, mode: 'records',perPage: 100,}"
                                    :sort-options="{ enabled: true, multipleColumns: true,}">
                                    <template slot="table-row" slot-scope="props">
                                        <div v-if="props.column.field === 'visitor_details'">
                                            <p v-for="visitor in props.row.visitor_details_split">{{ visitor }}</p>
                                        </div>
                                    </template>
                                </vue-good-table>
                                <div v-if="wardIndex+1 !== modified_bookings_data.length" class="html2pdf__page-break"/>
                            </div>
                        </div>
                    </div>
                </section>
            </vue-html2pdf>

            <vue-html2pdf v-if="bookings_metadata && bookings_data"
                          :float-layout="true"
                          :enable-download="true"
                          filename="Bookings"
                          :pdf-quality="1.5"
                          :manual-pagination="true"
                          pdf-format="a4"
                          pdf-orientation="landscape"
                          pdf-content-width="100%"
                          @beforeDownload="beforeDownload($event)"
                          @hasDownloaded="hasDownloaded($event)"
                          ref="html2Pdf6">

                <section slot="pdf-content" class="pdf-content">
                    <div class="pdf-content-container">
                        <div v-for="(ward, wardIndex) in modified_bookings_data" v-if="wardIndex > 34 && wardIndex <= 41">
                            <div v-for="(recordArray, recordArrayIndex) in ward.records" :key="recordArrayIndex">
                                <div class="pdf-content-header">
                                    <p>{{ bookings_metadata.date_exported }}</p>
                                    <p>{{ bookings_metadata.exported_at }}</p>
                                    <p>{{ bookings_metadata.exported_by }}</p>
                                    <p>Ward:{{ ward.name }}</p>
                                </div>
                                <vue-good-table
                                    styleClass="vgt-custom-bookings-pdf"
                                    :columns="bookingsColumns"
                                    :rows="recordArray"
                                    :search-options="{enabled: false,}"
                                    :pagination-options="{enabled: true, mode: 'records',perPage: 100,}"
                                    :sort-options="{ enabled: true, multipleColumns: true,}">
                                    <template slot="table-row" slot-scope="props">
                                        <div v-if="props.column.field === 'visitor_details'">
                                            <p v-for="visitor in props.row.visitor_details_split">{{ visitor }}</p>
                                        </div>
                                    </template>
                                </vue-good-table>
                                <div v-if="wardIndex+1 !== modified_bookings_data.length" class="html2pdf__page-break"/>
                            </div>
                        </div>
                    </div>
                </section>
            </vue-html2pdf>

            <vue-html2pdf v-if="bookings_metadata && bookings_data"
                          :float-layout="true"
                          :enable-download="true"
                          filename="Bookings"
                          :pdf-quality="1.5"
                          :manual-pagination="true"
                          pdf-format="a4"
                          pdf-orientation="landscape"
                          pdf-content-width="100%"
                          @beforeDownload="beforeDownload($event)"
                          @hasDownloaded="hasDownloaded($event)"
                          ref="html2Pdf7">

                <section slot="pdf-content" class="pdf-content">
                    <div class="pdf-content-container">
                        <div v-for="(ward, wardIndex) in modified_bookings_data" v-if="wardIndex > 41 && wardIndex <= 48">
                            <div v-for="(recordArray, recordArrayIndex) in ward.records" :key="recordArrayIndex">
                                <div class="pdf-content-header">
                                    <p>{{ bookings_metadata.date_exported }}</p>
                                    <p>{{ bookings_metadata.exported_at }}</p>
                                    <p>{{ bookings_metadata.exported_by }}</p>
                                    <p>Ward:{{ ward.name }}</p>
                                </div>
                                <vue-good-table
                                    styleClass="vgt-custom-bookings-pdf"
                                    :columns="bookingsColumns"
                                    :rows="recordArray"
                                    :search-options="{enabled: false,}"
                                    :pagination-options="{enabled: true, mode: 'records',perPage: 100,}"
                                    :sort-options="{ enabled: true, multipleColumns: true,}">
                                    <template slot="table-row" slot-scope="props">
                                        <div v-if="props.column.field === 'visitor_details'">
                                            <p v-for="visitor in props.row.visitor_details_split">{{ visitor }}</p>
                                        </div>
                                    </template>
                                </vue-good-table>
                                <div v-if="wardIndex+1 !== modified_bookings_data.length" class="html2pdf__page-break"/>
                            </div>
                        </div>
                    </div>
                </section>
            </vue-html2pdf>

            <vue-html2pdf v-if="bookings_metadata && bookings_data"
                          :float-layout="true"
                          :enable-download="true"
                          filename="Bookings"
                          :pdf-quality="1.5"
                          :manual-pagination="true"
                          pdf-format="a4"
                          pdf-orientation="landscape"
                          pdf-content-width="100%"
                          @beforeDownload="beforeDownload($event)"
                          @hasDownloaded="hasDownloaded($event)"
                          ref="html2Pdf8">

                <section slot="pdf-content" class="pdf-content">
                    <div class="pdf-content-container">
                        <div v-for="(ward, wardIndex) in modified_bookings_data" v-if="wardIndex > 48">
                            <div v-for="(recordArray, recordArrayIndex) in ward.records" :key="recordArrayIndex">
                                <div class="pdf-content-header">
                                    <p>{{ bookings_metadata.date_exported }}</p>
                                    <p>{{ bookings_metadata.exported_at }}</p>
                                    <p>{{ bookings_metadata.exported_by }}</p>
                                    <p>Ward:{{ ward.name }}</p>
                                </div>
                                <vue-good-table
                                    styleClass="vgt-custom-bookings-pdf"
                                    :columns="bookingsColumns"
                                    :rows="recordArray"
                                    :search-options="{enabled: false,}"
                                    :pagination-options="{enabled: true, mode: 'records',perPage: 100,}"
                                    :sort-options="{ enabled: true, multipleColumns: true,}">
                                    <template slot="table-row" slot-scope="props">
                                        <div v-if="props.column.field === 'visitor_details'">
                                            <p v-for="visitor in props.row.visitor_details_split">{{ visitor }}</p>
                                        </div>
                                    </template>
                                </vue-good-table>
                                <div v-if="wardIndex+1 !== modified_bookings_data.length" class="html2pdf__page-break"/>
                            </div>
                        </div>
                    </div>
                </section>
            </vue-html2pdf>

            <vue-html2pdf v-if="visitors_bookings_data"
                          :float-layout="true"
                          :enable-download="true"
                          filename="Visitors Report"
                          :pdf-quality="2"
                          :manual-pagination="true"
                          pdf-format="a4"
                          pdf-orientation="landscape"
                          pdf-content-width="100%"
                          @beforeDownload="beforeDownload($event)"
                          @hasDownloaded="hasDownloaded($event)"
                          ref="html2Pdf">

                <section slot="pdf-content" class="pdf-content">
                    <div class="pdf-content-container">
                        <div v-for="array in visitors_modified_bookings_data">
                            <div class="pdf-content-header">
                                <p>{{ visitors_bookings_metadata.date_exported }}</p>
                                <p>{{ visitors_bookings_metadata.exported_at }}</p>
                                <p>{{ visitors_bookings_metadata.exported_by }}</p>
                                <p v-if="ward">Ward: {{ ward.attributes.name }}</p>
                                <p v-if="visitors_attended && !visitors_not_attended">Attendance: Attended</p>
                                <p v-if="visitors_not_attended && !visitors_attended">Attendance: Not attended</p>
                                <p v-if="visitors_attended && visitors_not_attended">Attendance: Attended / Not attended</p>
                            </div>
                            <vue-good-table
                                styleClass="vgt-custom-bookings-pdf"
                                :columns="visitorsColumns"
                                :rows="array"
                                :search-options="{enabled: false,}"
                                :pagination-options="{enabled: true, mode: 'records',perPage: 100,}"
                                :sort-options="{ enabled: true, multipleColumns: true,}">
                            </vue-good-table>
                            <div class="html2pdf__page-break"/>
                        </div>
                    </div>
                </section>
            </vue-html2pdf>
        </main>
    </div>
</template>

<script>
import Headbar from "../../components/headbar/Headbar";
import FormInputDateTime from "../../components/form/FormInputDateTime";
import FormInputCheck from "../../components/form/FormInputCheck";
import Form from "../../components/form/Form";
import {required} from 'vuelidate/lib/validators'
import Select from "../../components/form/Select";
import VueHtml2pdf from 'vue-html2pdf'

export default {
    name: "Index",
    components: {Select, Form, FormInputDateTime, FormInputCheck,  Headbar, VueHtml2pdf},
    computed: {
        bookingReportFiles() {
            if(this.modified_bookings_data && this.modified_bookings_data.length > 500) {
                return 2;
            } else if(this.modified_bookings_data && this.modified_bookings_data.length > 1000) {
                return 3;
            } else if(this.modified_bookings_data && this.modified_bookings_data.length > 1000) {
                return 4;
            }
        }
    },
    data: function () {
        return {
            date: {
                from: null,
                to: null,
            },
            modified_bookings_data: [],
            bookings_data: null,
            bookings_metadata: null,
            visitors_bookings_data: null,
            visitors_bookings_metadata: null,
            visitors_modified_bookings_data: [],
            selected_report: null,
            selected_type: null,
            ward: null,
            visitors_attended: false,
            visitors_not_attended: false,
            wardOptions: [],
            fields: [],
            fieldOptions: [],
            page_break_limit: 25,
            visitors_page_break_limit: 25,
            reportOptions: [
                {
                    identifier: 'patient_data',
                    title: this.$t('reports.patients_data'),
                    need_roles: ['medical_records', 'customer_care', 'management', 'administrator'],
                    typeOptions: [
                        {
                            name: 'pdf',
                            icon: {
                                type: 'fal',
                                name: 'file-pdf',
                            }
                        },
                        {
                            name: 'excel',
                            icon: {
                                type: 'fal',
                                name: 'file-excel',
                            }
                        }
                    ]
                },
                {
                    identifier: 'bookings',
                    title: this.$t('reports.bookings'),
                    need_roles: ['customer_care', 'visitors_booking', 'management', 'administrator'],
                    typeOptions: [
                        {
                            name: 'pdf',
                            icon: {
                                type: 'fal',
                                name: 'file-pdf',
                            }
                        }
                    ]
                },
                {
                    identifier: 'ward_bookings',
                    title: this.$t('reports.ward_bookings'),
                    need_roles: ['customer_care', 'visitors_booking', 'management', 'administrator'],
                    typeOptions: [
                        {
                            name: 'pdf',
                            icon: {
                                type: 'fal',
                                name: 'file-pdf',
                            }
                        },
                        {
                            name: 'excel',
                            icon: {
                                type: 'fal',
                                name: 'file-excel',
                            }
                        }
                    ]
                },
                {
                    identifier: 'visitors',
                    title: this.$t('reports.visitors'),
                    need_roles: ['customer_care', 'visitors_booking', 'management', 'administrator'],
                    typeOptions: [
                        {
                            name: 'pdf',
                            icon: {
                                type: 'fal',
                                name: 'file-pdf',
                            }
                        }
                    ]
                },
            ],
            is_generating: false,
            is_loading_wards: false,
            is_loading_fields: false,
            bookingsColumns: [
                {
                    label: this.$t('bookings.date'),
                    field: 'date',
                    sortable: false,
                },
                {
                    label: this.$t('bookings.time'),
                    field: 'time',
                    sortable: false,
                },
                {
                    label: this.$t('bookings.patient'),
                    field: 'patient',
                    sortable: false,
                },
                {
                    label: this.$t('bookings.id_number'),
                    field: 'patient_id_number',
                    sortable: false,
                },
                {
                    label: this.$t('bookings.ward'),
                    field: 'ward',
                    sortable: false,

                },
                {
                    label: this.$t('bookings.visitors_details'),
                    field: 'visitor_details',
                    sortable: false,
                },
            ],
            visitorsColumns: [
                {
                    label: this.$t('bookings.date'),
                    field: 'booking_date',
                    sortable: false,
                },
                {
                    label: this.$t('bookings.time'),
                    field: 'time',
                    sortable: false,
                },
                {
                    label: this.$t('bookings.name'),
                    field: 'name',
                    sortable: false,
                },
                {
                    label: this.$t('bookings.id_number'),
                    field: 'id_number',
                    sortable: false,
                },
                {
                    label: this.$t('bookings.phone'),
                    field: 'phone',
                    sortable: false,
                },
                {
                    label: this.$t('bookings.patient'),
                    field: 'patient',
                    sortable: false,
                },
                {
                    label: this.$t('bookings.ward'),
                    field: 'ward',
                    sortable: false,

                },
            ],

        }
    },
    validations: {
        date: {
            from: {required},
            to: {required}
        },
        ward: {required}
    },
    methods: {
        selectReport(report) {
            this.selected_report = report;
        },
        selectType(type) {
            this.selected_type = type;
            this.requestReport();
        },
        selectedAttended(attended) {
            if (attended === 'attended'){
                this.visitors_attended = !this.visitors_attended;
                return;
            }

            if (attended === 'didnt_attend'){
                this.visitors_not_attended = !this.visitors_attended;
                return;
            }
        },
        requestReport() {
            if (this.selected_report === null || this.selected_type === null)
                return;

            let acceptHeader = null;

            if (this.selected_type.name === 'pdf') {
                acceptHeader = 'application/pdf';
            } else if (this.selected_type.name === 'excel') {
                acceptHeader = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            }

            if (this.selected_report.identifier === 'ward_bookings') {
                if (!this.ward || !this.date.from || !this.date.to) {
                    this.$notify({
                        text: this.$t('reports.select_date_and_ward'),
                        type: 'error',
                    });

                    return;
                }

                this.is_generating = true;
                this.$axios.post(`wards/${this.ward.id}/ward-bookings`, {
                    date_from: this.date.from,
                    date_to: this.date.to,
                    file_type: this.selected_type.name,
                }, {
                    responseType: 'arraybuffer',
                    headers: {
                        'Accept': acceptHeader
                    }
                }).then(({data}) => {
                    this.blobToFile(data, 'Ward bookings', acceptHeader);
                    this.is_generating = false;
                }).catch(e => {
                    this.is_generating = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data.errors, this.$t('reports.error_generate')),
                        type: 'error',
                    });
                });
            } else if (this.selected_report.identifier === 'bookings') {
                if (!this.date.from || !this.date.to) {
                    this.$notify({
                        text: this.$t('reports.select_date'),
                        type: 'error',
                    });
                    return;
                }

                if (this.selected_type.name === 'pdf') {
                    this.generateBookingsPdf();
                    return;
                }

                this.is_generating = true;
                this.$axios.post(`bookings/bookings`, {
                    date_from: this.date.from,
                    date_to: this.date.to,
                    file_type: this.selected_type.name,
                }, {
                    responseType: 'arraybuffer',
                    headers: {
                        'Accept': acceptHeader
                    }
                }).then(({data}) => {
                    this.blobToFile(data, 'bookings', acceptHeader);
                    this.is_generating = false;
                }).catch(e => {
                    this.is_generating = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data.errors, this.$t('reports.error_generate')),
                        type: 'error',
                    });
                });
            } else if (this.selected_report.identifier === 'patient_data') {
                if (this.fields.length === 0) {
                    this.$notify({
                        text: this.$t('reports.error_fields'),
                        type: 'error',
                    });

                    return;
                }

                this.is_generating = true;
                this.$axios.post(`patients/patients-data`, {
                    filters: {
                        filters: this.fields.map(f => f.column),
                    },
                    file_type: this.selected_type.name,
                }, {
                    responseType: 'arraybuffer',
                    headers: {
                        'Accept': acceptHeader
                    }
                }).then(({data}) => {
                    this.blobToFile(data, 'patient data', acceptHeader);
                    this.is_generating = false;
                }).catch(e => {
                    this.is_generating = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data.errors, this.$t('reports.error_generate')),
                        type: 'error',
                    });
                });
            } else if (this.selected_report.identifier === 'visitors') {
                if (!this.date.from || !this.date.to) {
                    this.$notify({
                        text: this.$t('reports.select_date'),
                        type: 'error',
                    });
                    return;
                }

                if (!this.visitors_attended && !this.visitors_not_attended) {
                    this.$notify({
                        text: this.$t('reports.select_visitors_attendance'),
                        type: 'error',
                    });
                    return;
                }

                if(!this.ward) {
                    this.$notify({
                        text: this.$t('reports.select_ward'),
                        type: 'error',
                    });
                    return;
                }

                if (this.selected_type.name === 'pdf') {
                    this.generateVisitorsPdf();
                    return;
                }
            }
        },
        async generateBookingsPdf() {
            this.is_generating = true;
            this.$axios.post(`bookings/bookings`, {
                    date_from: this.date.from,
                    date_to: this.date.to,
                    file_type: this.selected_type.name
                }
            ).then(async ({data}) => {
                let bookingsPromise = new Promise((resolve, reject) => {
                    this.bookings_data = data.bookings_data;
                    this.bookings_metadata = data.meta_data;
                    this.modified_bookings_data = []

                    if(this.bookings_data.length === 0) {
                        this.$notify({
                            text: this.$t('reports.no_data_found'),
                            type: 'error',
                        });
                        return;
                    }

                    for (const [key, value] of Object.entries(this.bookings_data)) {
                        let visitorSplitCount = 0;
                        let tempBookingSplitArray = [];
                        let currentBookings = [];

                        value.forEach(booking => {
                            if ((visitorSplitCount + booking.visitor_details.split(',').length) >= this.page_break_limit) {
                                visitorSplitCount = 0;
                                tempBookingSplitArray.push(currentBookings)
                                currentBookings = []
                            }
                            booking['visitor_details_split'] = booking.visitor_details.split(',')
                            visitorSplitCount += booking['visitor_details_split'].length
                            currentBookings.push(booking)
                        })

                        if (currentBookings.length > 0)
                            tempBookingSplitArray.push(currentBookings)

                        this.modified_bookings_data.push({
                            name: key,
                            data: value,
                            records: tempBookingSplitArray,
                        })
                    }
                    resolve('Done')

                })

                bookingsPromise.then(() => {
                    this.$refs.html2Pdf1.generatePdf()
                    this.$refs.html2Pdf2.generatePdf()
                    this.$refs.html2Pdf3.generatePdf()
                    this.$refs.html2Pdf4.generatePdf()
                    this.$refs.html2Pdf5.generatePdf()
                    this.$refs.html2Pdf6.generatePdf()
                    this.$refs.html2Pdf7.generatePdf()
                    // this.$refs.html2Pdf8.generatePdf()
                })
                this.is_generating = false;
            }).catch(e => {
                this.is_generating = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('reports.error_generate')),
                    type: 'error',
                });
            });

        },
        async generateVisitorsPdf() {
            this.is_generating = true;

            let payload = {
                date_from: this.date.from,
                date_to: this.date.to,
                file_type: this.selected_type.name
            }

            if(this.ward) {
                payload.ward_id = this.ward.id;
            } else {
                payload.ward_id = null;
            }

            this.$axios.post(`bookings/visitors-bookings`, payload
            ).then(async ({data}) => {
                let visitorsBookingsPromise = new Promise((resolve, reject) => {

                    this.visitors_bookings_data = data.visitors_data;
                    this.visitors_bookings_metadata = data.meta_data;

                    if (this.visitors_attended && !this.visitors_not_attended && this.visitors_bookings_data) {
                        this.visitors_bookings_data = this.visitors_bookings_data.filter(visitor => visitor.has_visited);
                    } else if (this.visitors_not_attended && !this.visitors_attended && this.visitors_bookings_data) {
                        this.visitors_bookings_data = this.visitors_bookings_data.filter(visitor => !visitor.has_visited);
                    }

                    if(this.visitors_bookings_data.length === 0) {
                        this.$notify({
                            text: this.$t('reports.no_data_found'),
                            type: 'error',
                        });
                        return;
                    }

                    this.visitors_modified_bookings_data = [];

                    let temp_array = [];

                    this.visitors_bookings_data.forEach((visitor, index) => {
                        temp_array.push(visitor);

                        if(temp_array.length >= this.visitors_page_break_limit) {
                            this.visitors_modified_bookings_data.push(temp_array);
                            temp_array = [];
                        }

                        if(index === this.visitors_bookings_data.length-1) {
                            this.visitors_modified_bookings_data.push(temp_array);
                        }
                    });

                    resolve('Done');
                })

                visitorsBookingsPromise.then(() => {
                    this.$refs.html2Pdf.generatePdf()
                });

                this.is_generating = false;
            }).catch(e => {
                this.is_generating = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('reports.error_generate')),
                    type: 'error',
                });
            });

        },
        async beforeDownload() {
            this.is_generating = true;
        },

        async hasDownloaded() {
            this.is_generating = false;
        },

        blobToFile(data, filename, fileType) {
            const blob = new Blob([data], {type: fileType});
            const url = window.URL.createObjectURL(blob);

            const tempLink = document.createElement('a');
            tempLink.style.display = 'none';
            tempLink.href = url;
            tempLink.setAttribute('download', filename);
            if (typeof tempLink.download === 'undefined') {
                tempLink.setAttribute('target', '_blank');
            }

            document.body.appendChild(tempLink);
            tempLink.click();
            document.body.removeChild(tempLink);
        },
        retrieveWards() {
            this.is_loading_wards = false;
            this.$axios.get('wards/list')
                .then(({data}) => {
                    this.is_loading_wards = false;
                    this.wardOptions = data.data;
                })
                .catch(e => {
                    this.is_loading_wards = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('wards.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        retrieveFields() {
            this.is_loading_fields = false;
            this.$axios.get('patients/list-patient-fields')
                .then(({data}) => {
                    this.is_loading_fields = false;
                    this.fieldOptions = data;
                    this.fields = this.fieldOptions;
                })
                .catch(e => {
                    this.is_loading_fields = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('patients.error_retrieve_fields')),
                        type: 'error',
                    });
                });
        },
    },

    mounted() {
        this.retrieveWards();

        this.retrieveFields();

        this.selected_report = this.reportOptions[0];
    },

    head() {
        return {
            title: {
                inner: this.$t('nav.reports')
            },
        }
    }
}
</script>

<style lang="scss" scoped>
.page-container {
    .visitors-check {
        @apply flex flex-row gap-x-4 text-sm w-32 h-full items-center mx-4;
    }
    .date-form {
        @apply flex flex-row bg-transparent;

        & > * {
            @apply mx-3;

            &:first-child {
                @apply ml-0;
            }

            &:last-child {
                @apply mr-0;
            }
        }

        .input-group {
            @apply mb-0;
        }
    }

    main {
        .reports-row {
            @apply flex flex-row flex-wrap -mx-2 mb-24;

            button.report-button-wrapper {
                @apply p-2 w-1/5;

                .report-button {
                    @apply bg-grey-light rounded-xl p-8;

                    span {
                        @apply text-black font-bold text-xl;
                    }
                }

                &:active, &:focus {
                    @apply outline-none;
                }

                &:hover, &:focus {
                    .report-button {
                        @apply bg-primary-over;
                    }
                }

                &.selected {
                    .report-button {
                        @apply bg-primary;
                    }
                }
            }
        }

        .export-row {
            @apply flex flex-col items-center;

            h3 {
                @apply text-black font-bold text-center text-2xl;
            }

            .row {
                @apply mt-10 flex flex-row justify-center flex-wrap -mx-2;

                button.type-button-wrapper {
                    @apply p-4;

                    .type-button {
                        @apply bg-grey-light rounded-xl p-4 flex flex-row items-center;

                        svg {
                            @apply text-black text-5xl;
                        }

                        span {
                            @apply text-black font-bold text-xl ml-4;
                        }
                    }

                    &:active, &:focus {
                        @apply outline-none;
                    }

                    &:hover, &:focus {
                        .type-button {
                            @apply bg-primary-over;
                        }
                    }

                    &.selected {
                        .type-button {
                            @apply bg-primary;
                        }
                    }
                }
            }
        }
    }
}

.pdf-content-container {
    @apply px-8;

    ::v-deep .vgt-clearfix {
        display: none !important;
    }

    .pdf-content-header {
        @apply font-bold mb-2 text-sm;
    }

    .pdf-content-table-content {
        font-size: 0.625rem;
        line-height: 0.75rem;

        th {
            @apply mb-2 px-2;
        }

        td {
            @apply border-t border-b border-black px-2;
        }

        .text-content {
            white-space: pre-wrap;
            word-wrap: break-word;
            font-family: inherit;

            &.header {
                @apply font-bold text-left;
            }
        }
    }
}
</style>