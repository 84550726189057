<template>
  <div class="ward-max-weekly-bookings-modal">
    <ModalContainer :title="$t('wards.update_max_weekly_bookings')" identifier="ward-max-weekly-bookings-modal"
                    :closable="true">
      <Form class="form" @submit="save" :disabled="is_saving">
        <FormInputText v-model="$v.max_weekly_bookings.$model"
                       identifier="max_weekly_bookings" type="number"
                       :label="$t('patients.max_weekly_bookings')"
                       :placeholder="$t('patients.max_weekly_bookings')" :disabled="is_saving"
                       :has-error="$v.max_weekly_bookings.$error">
          <template v-slot:errors>
            <p v-if="!$v.max_weekly_bookings.required">
              {{ $t('validation.x_is_required', {x: $t('patients.max_weekly_bookings')}) }}
            </p>
            <p v-else-if="!$v.max_weekly_bookings.numeric">
              {{ $t('validation.please_enter_number') }}
            </p>
          </template>
        </FormInputText>
        <Button type="submit" className="--primary --small" :class="{spinner: is_saving}">
          {{ $t('save') }}
        </Button>
      </Form>
    </ModalContainer>
  </div>
</template>

<script>
import ModalContainer from "../modal/ModalContainer";
import Form from "../form/Form";
import FormInputText from "../form/FormInputText";
import {numeric, required} from "vuelidate/lib/validators";
import Button from "../Button";

export default {
  name: "WardMaxWeeklyBookingsModal",
  components: {Button, FormInputText, Form, ModalContainer},
  props: {
    ward_id: {
      type: [String, Number],
      required: true
    }
  },
  data() {
    return {
      max_weekly_bookings: null,
      is_saving: false,
    }
  },
  validations: {
    max_weekly_bookings: {
      required, numeric
    },
  },
  methods: {
    close(status) {
      this.$modal.hide('ward-max-weekly-bookings-modal', status);
    },
    save() {
      this.$v.$touch();
      if (this.$v.$anyError || this.is_saving)
        return;

      this.is_saving = true;

      this.$axios.patch(`wards/${this.ward_id}/update-patients`, {
        max_weekly_bookings: this.max_weekly_bookings,
      }).then(({data}) => {
        this.$notify({
          text: this.$t('wards.success_max_weekly_bookings_updated'),
          type: 'success',
        });

        this.is_saving = false;
        this.close(true);
      }).catch(e => {
        this.is_saving = false;

        this.$notify({
          title: this.$t('error'),
          text: this.$larerror(e.response.data.errors, this.$t('wards.error_update_max_weekly_bookings')),
          type: 'error',
        });
      });
    }
  }
}
</script>

<style scoped>

</style>