import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store/index';
import PatientsIndex from "../views/patients/Index";
import PatientsCreate from "../views/patients/Create";
import PatientsUpdate from "../views/patients/Update";
import VisitorsIndex from "../views/visitors/Index";
import WardsIndex from "../views/wards/Index";
import WardsCreate from "../views/wards/Create";
import WardsUpdate from "../views/wards/Update";
import BookingsIndex from "../views/bookings/Index";
import BookingsCreate from "../views/bookings/Create";
import BookingsCreateBulk from "../views/bookings/CreateBulkBooking";
import BookingsUpdate from "../views/bookings/Update";
import UsersIndex from "../views/users/Index";
import RolesIndex from "../views/roles/Index";
import ReportsIndex from "../views/reports/Index";
import Login from "../views/Login";
import NotFound from "../views/NotFound";
import {i18n} from '../locale/i18n';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        redirect: '/bookings'
    },
    {
        path: '/patients',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: '/',
                name: 'patients-index',
                component: PatientsIndex,
                meta: {
                    auth: true,
                    all_permissions: ['read patients'],
                },
            },
            {
                path: 'add',
                name: 'patients-create',
                component: PatientsCreate,
                meta: {
                    auth: true,
                    all_permissions: ['store patients'],
                },
            },
            {
                path: ':id',
                name: 'patients-update',
                component: PatientsUpdate,
                meta: {
                    auth: true,
                    any_permissions: ['read patients', 'update patients'],
                },
            },
        ]
    },
    {
        path: '/visitors',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: '/',
                name: 'visitors-index',
                component: VisitorsIndex,
                meta: {
                    auth: true,
                    only_admin: true,
                },
            },
        ]
    },
    {
        path: '/wards',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: '/',
                name: 'wards-index',
                component: WardsIndex,
                meta: {
                    auth: true,
                    all_permissions: ['read wards'],
                },
            },
            {
                path: 'add',
                name: 'wards-create',
                component: WardsCreate,
                meta: {
                    auth: true,
                    all_permissions: ['store wards'],
                },
            },
            {
                path: ':id',
                name: 'wards-update',
                component: WardsUpdate,
                meta: {
                    auth: true,
                    all_permissions: ['read wards', 'update wards'],
                },
            },
        ]
    },
    {
        path: '/bookings',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: '/',
                name: 'bookings-index',
                component: BookingsIndex,
                meta: {
                    auth: true,
                    all_permissions: ['read bookings'],
                },
            },
            {
                path: 'add',
                name: 'bookings-create',
                component: BookingsCreate,
                meta: {
                    auth: true,
                    all_permissions: ['store bookings'],
                },
            },
            {
                path: 'add-bulk',
                name: 'bookings-create-bulk',
                component: BookingsCreateBulk,
                meta: {
                    auth: true,
                    all_permissions: ['store bookings'],
                },
            },
            {
                path: ':id',
                name: 'bookings-update',
                component: BookingsUpdate,
                meta: {
                    auth: true,
                    all_permissions: ['read bookings', 'update bookings'],
                },
            },
        ]
    },
    {
        path: '/users',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: '/',
                name: 'users-index',
                component: UsersIndex,
                meta: {
                    auth: true,
                    all_permissions: ['read users'],
                },
            },
        ]
    },
    {
        path: '/roles',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: '/',
                name: 'roles-index',
                component: RolesIndex,
                meta: {
                    auth: true,
                    all_permissions: ['read roles'],
                },
            },
        ]
    },
    {
        path: '/reports',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: '/',
                name: 'reports-index',
                component: ReportsIndex,
                meta: {
                    auth: true,
                },
            },
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            auth: 'guest',
        }
    },
    {
        path: '*',
        name: 'notfound',
        component: NotFound,
        meta: {
            auth: false
        }
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});


router.beforeEach(async (to, from, next) => {
    window.scrollTo(0, 0);

    if (!to.meta)
        next();

    if(to.path === '/bookings') {
        if(!store.getters.hasPermission('read bookings'))
            return next({path: '/patients'})
    }

    // check auth
    if (to.meta.auth) {
        const routeAuth = to.meta.auth;

        if (routeAuth === true) {
            // user must be logged in
            if (!store.getters.token) {
                Vue.notify({
                    type: 'error',
                    text: i18n.t('auth.unauthenticated')
                });

                return next({name: 'login'});
            }
        } else if (routeAuth.toLowerCase() === 'guest') {
            // user must be logged out
            if (store.getters.token)
                return next({path: '/'});
        }
    }

    // check role
    if (to.meta.only_admin) {
        // check that the user has admin role
        if (!store.getters.hasRole('administrator')) {
            Vue.notify({
                type: 'error',
                text: i18n.t('auth.unauthorized')
            });

            return next({path: '/'});
        }
    }

    // check permission
    if (to.meta.any_permissions) {
        // check that the user has some of the required permissions

        if (!store.getters.hasAnyPermission(to.meta.any_permissions)) {
            Vue.notify({
                type: 'error',
                text: i18n.t('auth.unauthorized')
            });

            return next({path: '/'});
        }
    }

    // check permission
    if (to.meta.all_permissions) {
        // check that the user has all the required permissions

        let permissions = to.meta.all_permissions;
        if (!store.getters.hasAllPermissions(permissions)) {
            Vue.notify({
                type: 'error',
                text: i18n.t('auth.unauthorized')
            });

            return next({path: '/'});
        }
    }

    return next();
});

export default router;
