<template>
    <div class="page-container">
        <Headbar>
            <template v-slot:left>
                <h1 v-if="original">{{ original.attributes.id_number }} - {{ original.attributes.first_name }} {{ original.attributes.last_name }}
                <span v-if="original.attributes.deceased_on">({{$t('patients.deceased')}})</span><span v-if="original.attributes.discharged_on">({{$t('patients.discharged')}})</span></h1>
            </template>
            <template v-slot:right>
                <Button
                    v-if="$store.getters.userRoles()[0].attributes.name === 'administrator' || $store.getters.userRoles()[0].attributes.name === 'medical_records' && is_discharged !== undefined"
                    className="--primary --outline --small"
                    :class="{spinner: is_discharging}" :onclick="toggleDischarge" :disabled="original.attributes.deceased_on">
                    {{ is_discharged ? $t('patients.admit_patient') : $t('patients.discharge_patient') }}
                </Button>
                <Button
                    v-if="$store.getters.userRoles()[0].attributes.name === 'administrator' || $store.getters.userRoles()[0].attributes.name === 'medical_records' && is_deceased !== undefined"
                    className="--primary --outline --small"
                    :class="{spinner: is_deceasing}" :onclick="toggleDecease" :disabled="original.attributes.discharged_on">
                    {{ is_deceased ? $t('patients.revive_patient') : $t('patients.decease_patient') }}
                </Button>
                <Button v-if="$store.getters.hasPermission('update patients')" className="--primary --outline --small"
                        :class="{spinner: is_saving}" :onclick="save">
                    {{ $t('save') }}
                </Button>
            </template>
        </Headbar>
        <main>
            <Form class="form" @submit="save" :disabled="!$store.getters.hasPermission('update patients') || is_saving">
                <SectionHeader :title="$t('patients.patient_details')"></SectionHeader>
                <div class="form-body">
                    <FormGroupThree>
                        <FormInputText v-model="$v.patient.first_name.$model" identifier="name"
                                       :label="$t('patients.first_name')"
                                       :placeholder="$t('patients.first_name')"
                                       :disabled="!$store.getters.hasPermission('update patients') || is_saving"
                                       :has-error="$v.patient.first_name.$error">
                            <template v-slot:errors>
                                <p v-if="!$v.patient.first_name.required">
                                    {{ $t('validation.x_is_required', {x: $t('patients.first_name')}) }}
                                </p>
                            </template>
                        </FormInputText>
                        <FormInputText v-model="$v.patient.last_name.$model" identifier="name"
                                       :label="$t('patients.last_name')"
                                       :placeholder="$t('patients.last_name')"
                                       :disabled="!$store.getters.hasPermission('update patients') || is_saving"
                                       :has-error="$v.patient.last_name.$error">
                            <template v-slot:errors>
                                <p v-if="!$v.patient.last_name.required">
                                    {{ $t('validation.x_is_required', {x: $t('patients.last_name')}) }}
                                </p>
                            </template>
                        </FormInputText>
                        <FormInputText v-model="$v.patient.id_number.$model" identifier="id_number"
                                       :label="$t('patients.id_number')"
                                       :placeholder="$t('patients.id_number')"
                                       :disabled="!$store.getters.hasPermission('update patients') || is_saving"
                                       :has-error="$v.patient.id_number.$error">
                            <template v-slot:errors>
                                <p v-if="!$v.patient.id_number.required">
                                    {{ $t('validation.x_is_required', {x: $t('patients.id_number')}) }}
                                </p>
                            </template>
                        </FormInputText>
                        <FormInputText v-model="$v.patient.date_of_birth.$model" identifier="date_of_birth"
                                       :label="$t('patients.date_of_birth')" type="date"
                                       :placeholder="$t('patients.date_of_birth')"
                                       :disabled="!$store.getters.hasPermission('update patients') || is_saving"
                                       :has-error="$v.patient.date_of_birth.$error">
                            <template v-slot:errors>
                                <p v-if="!$v.patient.date_of_birth.required">
                                    {{ $t('validation.x_is_required', {x: $t('patients.date_of_birth')}) }}
                                </p>
                            </template>
                        </FormInputText>
                    </FormGroupThree>
                    <FormGroupThree>
                        <FormInputSelect v-model="$v.patient.gender.$model" identifier="gender"
                                         :label="$t('patients.gender')" :options="genderOptions"
                                         :placeholder="$t('patients.gender')"
                                         :disabled="!$store.getters.hasPermission('update patients') || is_saving"
                                         :has-error="$v.patient.gender.$error">
                            <template v-slot:errors>
                                <p v-if="!$v.patient.gender.required">
                                    {{ $t('validation.x_is_required', {x: $t('patients.gender')}) }}
                                </p>
                            </template>
                        </FormInputSelect>
                        <FormInputSelect v-model="$v.patient.locality.$model" identifier="locality"
                                         :label="$t('patients.locality')"
                                         :display-custom-label="(row) => row.attributes.name" track-by="id"
                                         :options="localityOptions" :placeholder="$t('patients.locality')"
                                         :disabled="!$store.getters.hasPermission('update patients') || is_saving"
                                         :has-error="$v.patient.locality.$error">
                            <template v-slot:errors>
                                <p v-if="!$v.patient.locality.required">
                                    {{ $t('validation.x_is_required', {x: $t('patients.locality')}) }}
                                </p>
                            </template>
                        </FormInputSelect>
                        <FormInputSelect v-model="$v.patient.third_party.$model" identifier="third_party"
                                         :label="$t('patients.third_party')" :options="thirdPartyOptions"
                                         :placeholder="$t('patients.third_party')"
                                         :disabled="!$store.getters.hasPermission('update patients') || is_saving"
                                         :has-error="$v.patient.third_party.$error">
                            <template v-slot:errors>
                                <p v-if="!$v.patient.third_party.required">
                                    {{ $t('validation.x_is_required', {x: $t('patients.third_party')}) }}
                                </p>
                            </template>
                        </FormInputSelect>
                    </FormGroupThree>
                </div>
            </Form>
            <Form class="form" @submit="save" :disabled="!$store.getters.hasPermission('update patients') || is_saving">
                <SectionHeader :title="$t('patients.other_details')"></SectionHeader>
                <div class="form-body">
                    <FormGroupThree>
                        <FormInputSelect v-model="$v.patient.ward.$model" identifier="ward"
                                         :label="$t('patients.ward')" :options="wardOptions"
                                         :display-custom-label="(row) => row.attributes.name" track-by="id"
                                         :placeholder="$t('patients.ward')"
                                         :disabled="!$store.getters.hasPermission('update patients') || is_saving"
                                         :has-error="$v.patient.ward.$error">
                            <template v-slot:errors>
                                <p v-if="!$v.patient.ward.required">
                                    {{ $t('validation.x_is_required', {x: $t('patients.ward')}) }}
                                </p>
                            </template>
                        </FormInputSelect>
                        <FormInputText v-model="$v.patient.admitted_on.$model"
                                       identifier="admitted_on"
                                       :label="$t('patients.date_of_admission_short')" type="date"
                                       :placeholder="$t('patients.date_of_admission_short')"
                                       :disabled="!$store.getters.hasPermission('update patients') || is_saving"
                                       :has-error="$v.patient.admitted_on.$error">
                            <template v-slot:errors>
                                <p v-if="!$v.patient.admitted_on.required">
                                    {{ $t('validation.x_is_required', {x: $t('patients.date_of_admission')}) }}
                                </p>
                            </template>
                        </FormInputText>
                        <FormInputText v-model="$v.patient.admitted_from.$model"
                                       identifier="admitted_from"
                                       :label="$t('patients.admitted_from')"
                                       :placeholder="$t('patients.admitted_from')"
                                       :disabled="!$store.getters.hasPermission('update patients') || is_saving"
                                       :has-error="$v.patient.admitted_from.$error">
                            <template v-slot:errors>
                                <p v-if="!$v.patient.admitted_from.required">
                                    {{ $t('validation.x_is_required', {x: $t('patients.admitted_from')}) }}
                                </p>
                            </template>
                        </FormInputText>
                    </FormGroupThree>
                    <FormGroupThree>
                        <FormInputSelect v-model="$v.patient.media_permission.$model" identifier="media_permission"
                                         :label="$t('patients.media')" :options="mediaPermissionOptions"
                                         :placeholder="$t('patients.media')"
                                         :disabled="!$store.getters.hasPermission('update patients') || is_saving"
                                         :has-error="$v.patient.media_permission.$error">
                            <template v-slot:errors>
                                <p v-if="!$v.patient.media_permission.required">
                                    {{ $t('validation.x_is_required', {x: $t('patients.media')}) }}
                                </p>
                            </template>
                        </FormInputSelect>
                        <FormInputText v-model="$v.patient.max_daily_bookings.$model"
                                       identifier="max_daily_bookings" type="number"
                                       :label="$t('patients.max_daily_bookings')"
                                       :placeholder="$t('patients.max_daily_bookings')"
                                       :disabled="true"
                                       :has-error="$v.patient.max_daily_bookings.$error">
                            <template v-slot:errors>
                                <p v-if="!$v.patient.max_daily_bookings.required">
                                    {{ $t('validation.x_is_required', {x: $t('patients.max_daily_bookings')}) }}
                                </p>
                                <p v-else-if="!$v.patient.max_daily_bookings.numeric">
                                    {{ $t('validation.please_enter_number') }}
                                </p>
                            </template>
                        </FormInputText>
                        <FormInputText v-model="$v.patient.max_weekly_bookings.$model"
                                       identifier="max_weekly_bookings" type="number"
                                       :label="$t('patients.max_weekly_bookings')"
                                       :placeholder="$t('patients.max_weekly_bookings')"
                                       :disabled="!$store.getters.hasPermission('update patients') || is_saving"
                                       :has-error="$v.patient.max_weekly_bookings.$error">
                            <template v-slot:errors>
                                <p v-if="!$v.patient.max_weekly_bookings.required">
                                    {{ $t('validation.x_is_required', {x: $t('patients.max_weekly_bookings')}) }}
                                </p>
                                <p v-else-if="!$v.patient.max_weekly_bookings.numeric">
                                    {{ $t('validation.please_enter_number') }}
                                </p>
                            </template>
                        </FormInputText>
                    </FormGroupThree>
                </div>
            </Form>
            <Form class="form" @submit="save" :disabled="!$store.getters.hasPermission('update patients') || is_saving">
                <SectionHeader :title="$t('patients.next_of_kin_details')"></SectionHeader>
                <div class="form-body next-of-kin">
                    <FormGroupThree v-for="(next_of_kin, i) in $v.patient.next_of_kin.$each.$iter"
                                    :key="next_of_kin.key" class="next-of-kin-row-group">
                        <FormInputText v-model="next_of_kin.name.$model" identifier="next_of_kin_name"
                                       :label="$t('patients.name')"
                                       :placeholder="$t('patients.name')" :disabled="is_saving"
                                       :has-error="next_of_kin.name.$error">
                            <template v-slot:errors>
                                <p v-if="!next_of_kin.name.required">
                                    {{ $t('validation.x_is_required', {x: $t('patients.name')}) }}
                                </p>
                            </template>
                        </FormInputText>
                        <FormInputText v-model="next_of_kin.phone.$model" identifier="next_of_kin_phone"
                                       :label="$t('patients.phone')"
                                       :placeholder="$t('patients.phone')" :disabled="is_saving"
                                       :has-error="next_of_kin.phone.$error">
                            <template v-slot:errors>
                                <p v-if="!next_of_kin.phone.required">
                                    {{ $t('validation.x_is_required', {x: $t('patients.phone')}) }}
                                </p>
                            </template>
                        </FormInputText>
                        <FormInputText v-model="next_of_kin.id_number.$model" identifier="next_of_kin_id_number"
                                       :label="$t('patients.id_number')"
                                       :placeholder="$t('patients.id_number')" :disabled="is_saving"
                                       :has-error="next_of_kin.id_number.$error">
                            <template v-slot:errors>
                                <p v-if="!next_of_kin.id_number.required">
                                    {{ $t('validation.x_is_required', {x: $t('patients.id_number')}) }}
                                </p>
                            </template>
                        </FormInputText>
                        <Button className="--secondary --outline --mini" :onclick="() => removeNextOfKin(i)"
                                class="button-remove">
                            <font-awesome-icon :icon="['far','times']"/>
                        </Button>
                    </FormGroupThree>
                </div>

                <div class="row-add-container" v-if="$v.patient.next_of_kin.$model.length < 3">
                    <Button className="--primary --outline --small" :onclick="addNextOfKin">
                        {{ $t('patients.add_next_of_kin') }}
                    </Button>
                </div>
            </Form>
            <Form class="form" @submit="save" :disabled="is_saving">
                <SectionHeader :title="$t('patients.associated_visitors')"/>
                <vue-good-table class="visitors-table"
                    mode="remote"
                    styleClass="vgt-table vgt-custom vgt-white"
                    max-height="300px"
                    :columns="columns"
                    :rows="visitors"
                    :isLoading.sync="is_loading_visitors"
                    :search-options="{
                        enabled: false,
                    }"
                    :pagination-options="{
                        enabled: false,
                    }"
                    :sort-options="{
                          enabled: true,
                          multipleColumns: true,
                    }">
                </vue-good-table>
            </Form>

            <div class="divider"></div>
            <div class="table-area">
                <p class="title">{{ $t('audits.audits') }}</p>
                <vue-good-table
                    mode="remote"
                    styleClass="vgt-table striped vgt-custom"
                    :columns="auditColumns"
                    :rows="audits"
                    :isLoading.sync="is_loading_audits"
                    :search-options="{
                        enabled: false,
                    }"
                    :pagination-options="{
                        enabled: true,
                        mode: 'records',
                        dropdownAllowAll: false,
                        perPage: 15,
                        perPageDropdownEnabled: false,
                        rowsPerPageLabel: $t('x_per_page', {x: $t('audits.audits')}),
                    }"
                    :totalRows="totalAuditRecords"
                    @on-page-change="onAuditPageChange"
                    @on-sort-change="onAuditSortChange">
                    <template slot="table-row" slot-scope="props">
                        <div v-if="props.column.field === 'values'" class="td-values">
                            <div v-if="Object.keys(props.row.attributes.old_values).length" class="row">
                                <p class="name">{{ $t('audits.old_values') }}</p>
                                <div class="values">
                                    <p v-for="key in Object.keys(props.row.attributes.old_values)">
                                        <strong>{{ $t(`patients.${key}`) }}: </strong>{{ props.row.attributes.old_values[key] }}
                                    </p>
                                </div>
                            </div>
                            <div v-if="Object.keys(props.row.attributes.new_values).length" class="row">
                                <p class="name">{{ $t('audits.new_values') }}</p>
                                <div class="values">
                                    <p v-for="key in Object.keys(props.row.attributes.new_values)">
                                        <strong>{{ $t(`patients.${key}`) }}: </strong>{{ props.row.attributes.new_values[key] }}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <span v-else>
                      {{ props.formattedRow[props.column.field] }}
                    </span>
                    </template>
                </vue-good-table>
            </div>
        </main>
    </div>
</template>

<script>
import _ from 'lodash';
import Headbar from "../../components/headbar/Headbar";
import Form from "../../components/form/Form";
import SectionHeader from "../../components/SectionHeader";
import Button from "../../components/Button";
import FormInputText from "../../components/form/FormInputText";
import FormInputSelect from "../../components/form/FormInputSelect";
import {required, numeric} from 'vuelidate/lib/validators'
import FormGroupThree from "../../components/form/FormGroupThree";
import UsersCreateModal from "@/components/users/UsersCreateModal.vue";
import DeceasedModal from "@/components/patients/DeceasedModal.vue";
import DischargedModal from "@/components/patients/DischargedModal.vue";

export default {
    name: "patients-update-page",
    components: {FormGroupThree, FormInputText, FormInputSelect, Button, SectionHeader, Form, Headbar},
    data: function () {
        const columns = [
            {
                label: this.$t('visitors.name'),
                field: 'attributes.name',
                sortable: false,
            },
            {
                label: this.$t('patients.visitor_id'),
                field: 'attributes.id_number',
                sortable: false,
            },
            {
                label: this.$t('patients.contact_number'),
                field: 'attributes.phone',
                sortable: false,
            },
        ];
        return {
            columns: columns,
            original: null,
            auditColumns: [
                {
                    label: this.$t('audits.user'),
                    field: 'relationships.user.data.attributes.name',
                    sortable: false,
                },
                {
                    label: this.$t('audits.event'),
                    field: row => this.$t(`audits.${row.attributes.event}`),
                    sortable: false,
                },
                {
                    label: this.$t('audits.date_n_time'),
                    field: row => this.$moment.utc(row.attributes.created_at).format('DD/MM/YYYY - HH:mm'),
                    sortable: false,
                },
                {
                    label: this.$t('audits.values'),
                    field: 'values',
                    sortable: false,
                }
            ],
            visitors: [],
            patient: {
                first_name: null,
                last_name: null,
                id_number: null,
                date_of_birth: null,
                visitor_id: null,
                contact_number: null,
                gender: null,
                locality: null,
                next_of_kin: [],
                third_party: null,
                ward: null,
                admitted_on: null,
                admitted_from: null,
                media_permission: null,
                max_daily_bookings: null,
                max_weekly_bookings: null,
            },
            audits: [],
            is_discharged: undefined,
            is_deceased: undefined,
            genderOptions: [
                'male', 'female'
            ],
            localityOptions: [],
            thirdPartyOptions: [
                'yes', 'no'
            ],
            wardOptions: [],
            mediaPermissionOptions: [
                'yes', 'no'
            ],
            is_saving: false,
            is_discharging: false,
            is_deceasing: false,
            is_loading_ward_options: false,
            is_loading_locality_options: false,
            is_loading_original: false,
            is_loading_visitors: false,
            is_loading_audits: false,
            totalAuditRecords: null,
            auditServerParams: {},
        }
    },
    validations: {
        patient: {
            first_name: {
                required
            },
            last_name: {
                required
            },
            id_number: {
                required
            },
            date_of_birth: {
                required
            },
            gender: {
                required
            },
            locality: {
                required
            },
            next_of_kin: {
                $each: {
                    name: {required},
                    phone: {required},
                    id_number: {required}
                }
            },
            third_party: {
                required
            },
            ward: {
                required
            },
            admitted_on: {
                required
            },
            admitted_from: {
                required
            },
            media_permission: {
                required
            },
            max_daily_bookings: {
                required, numeric
            },
            max_weekly_bookings: {
                required, numeric
            }
        }
    },
    methods: {
        updateAuditParams(newProps) {
            this.auditServerParams = Object.assign({}, this.auditServerParams, newProps);
        },
        onAuditPageChange(params) {
            this.updateAuditParams({page: params.currentPage});
            this.retrieveAudits();
        },
        onAuditSortChange(params) {
            this.updateAuditParams({sort: params});
            this.retrieveAudits();
        },
        removeNextOfKin(index) {
            index = parseInt(index);
            if (index >= this.$v.patient.next_of_kin.$model.length)
                return;

            this.$v.patient.next_of_kin.$model.splice(index, 1);
        },
        addNextOfKin() {
            this.$v.patient.next_of_kin.$touch();

            if (!this.$v.patient.next_of_kin.$anyError && this.$v.patient.next_of_kin.$model.length < 3)
                this.$v.patient.next_of_kin.$model.push({
                    key: this.$v.patient.next_of_kin.$model.length + 1,
                    name: null,
                    phone: null,
                    id_number: null,
                });
        },
        populate() {
            if (!this.original || !this.patient)
                return;

            this.is_discharged = this.original.attributes.discharged_on;
            this.is_deceased = this.original.attributes.deceased_on;
            this.$v.patient.first_name.$model = this.original.attributes.first_name;
            this.$v.patient.last_name.$model = this.original.attributes.last_name;
            this.$v.patient.id_number.$model = this.original.attributes.id_number;
            this.$v.patient.date_of_birth.$model = this.original.attributes.date_of_birth;
            this.$v.patient.gender.$model = this.original.attributes.gender;
            this.$v.patient.admitted_from.$model = this.original.attributes.admitted_from;
            this.$v.patient.max_daily_bookings.$model = this.original.attributes.max_daily_bookings.toString();
            this.$v.patient.max_weekly_bookings.$model = this.original.attributes.max_weekly_bookings.toString();

            if (this.original.attributes.media_permission)
                this.$v.patient.media_permission.$model = 'yes';
            else this.$v.patient.media_permission.$model = 'no';

            if (['yes', 'accepted'].includes(this.original.attributes.third_party.toLowerCase()))
                this.$v.patient.third_party.$model = 'yes';
            else this.$v.patient.third_party.$model = 'no';

            this.$v.patient.locality.$model = _.find(this.localityOptions, {id: this.original.relationships.locality.data.id});
            this.$v.patient.ward.$model = _.find(this.wardOptions, {id: this.original.relationships.ward.data.id});

            if (this.original.attributes.next_of_kin && this.original.attributes.next_of_kin.constructor === Object)
                this.$v.patient.next_of_kin.$model = [this.original.attributes.next_of_kin];
            else
                this.$v.patient.next_of_kin.$model = this.original.attributes.next_of_kin;

            if (this.original.attributes.admitted_on && this.original.attributes.admitted_on.indexOf('T'))
                this.$v.patient.admitted_on.$model = this.original.attributes.admitted_on.substr(0, this.original.attributes.admitted_on.indexOf('T'));

        },
        async retrieveOriginalPatient() {
            this.is_loading_original = false;
            await this.$axios.get(`patients/${this.$route.params.id}`)
                .then(({data}) => {
                    this.is_loading_original = false;
                    this.original = data.data;
                }).catch(e => {
                    this.is_loading_original = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('patients.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        async retrieveWardOptions() {
            this.is_loading_ward_options = false;
            await this.$axios.get('wards/list')
                .then(({data}) => {
                    this.is_loading_ward_options = false;
                    this.wardOptions = data.data;
                })
                .catch(e => {
                    this.is_loading_ward_options = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('wards.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        async retrieveLocalityOptions() {
            this.is_loading_locality_options = false;
            await this.$axios.get('localities/list')
                .then(({data}) => {
                    this.is_loading_locality_options = false;
                    this.localityOptions = data.data;
                })
                .catch(e => {
                    this.is_loading_locality_options = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('localities.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        async retrieveVisitors() {
            this.is_loading_visitors = true;
            await this.$axios.get(`patients/${this.$route.params.id}/list-visitors`)
                .then(({data}) => {
                    this.is_loading_visitors = false;
                    this.visitors = data.data;
                })
                .catch(e => {
                    this.is_loading_visitor_options = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('visitors.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        retrieveAudits() {
            this.is_loading_audits = false;
            this.$axios.get(`patients/${this.$route.params.id}/audits`, {params: this.auditServerParams})
                .then(({data}) => {
                    this.audits = data.data;
                    this.totalAuditRecords = data.meta.total;
                    this.is_loading_audits = false;
                })
                .catch(e => {
                    this.is_loading_audits = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('audits.error_retrieve_audits')),
                        type: 'error',
                    });
                });
        },
        save() {
            this.$v.patient.$touch();
            if (this.$v.patient.$anyError || this.is_saving)
                return;

            this.is_saving = true;

            const payload = Object.assign({}, this.$v.patient.$model);
            payload.media_permission = payload.media_permission === 'yes';
            payload.ward_id = payload.ward.id;
            payload.locality_id = payload.locality.id;
            payload.next_of_kin = payload.next_of_kin.map(n => ({name: n.name, phone: n.phone, id_number: n.id_number}))

            delete payload.ward;
            delete payload.locality;

            this.$axios.patch(`patients/${this.$route.params.id}`, payload).then(({data}) => {
                this.$notify({
                    text: this.$t('patients.success_updated'),
                    type: 'success',
                });

                this.is_saving = false;

                this.$router.push({name: 'patients-index'});
            }).catch(e => {
                this.is_saving = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('patients.error_update')),
                    type: 'error',
                });
            });
        },
        toggleDischarge() {
            if (this.is_discharged) {
                this.is_discharging = true;

                this.$axios.get(`patients/${this.$route.params.id}/admit`).then(({data}) => {
                    this.$notify({
                        text: this.$t('patients.success_updated'),
                        type: 'success',
                    });

                    this.is_discharged = false;
                    this.is_discharging = false;

                    this.$router.push({name: 'patients-index'});
                })
                    .catch(e => {
                        this.is_discharging = false;

                        this.$notify({
                            title: this.$t('error'),
                            text: this.$larerror(e.response.data.errors, this.$t('patients.error_discharged')),
                            type: 'error',
                        });
                    });
            } else {
                this.$modal.show(
                    DischargedModal, {},
                    {
                        name: 'discharged-modal',
                        adaptive: true,
                        resizable: true,
                        height: 'auto',
                        scrollable: true,
                        classes: 'modal',
                    }, {
                        'before-close': (e) => {
                            if (e.params === true) {
                                this.is_discharged = true;
                                this.is_discharging = false;

                                this.$router.push({name: 'patients-index'});
                            }
                        }
                    }
                );
            }
        },
        toggleDecease() {
            if (this.is_deceased) {
                this.is_deceasing = true;

                this.$axios.get(`patients/${this.$route.params.id}/revive`).then(({data}) => {
                    this.$notify({
                        text: this.$t('patients.success_updated'),
                        type: 'success',
                    });

                    this.is_deceased = false;
                    this.is_deceasing = false;

                    this.$router.push({name: 'patients-index'});
                })
                    .catch(e => {
                        this.is_deceasing = false;

                        this.$notify({
                            title: this.$t('error'),
                            text: this.$larerror(e.response.data.errors, this.$t('patients.error_deceased')),
                            type: 'error',
                        });
                    });
            } else {
                this.$modal.show(
                    DeceasedModal, {},
                    {
                        name: 'deceased-modal',
                        adaptive: true,
                        resizable: true,
                        height: 'auto',
                        scrollable: true,
                        classes: 'modal',
                    }, {
                        'before-close': (e) => {
                            if (e.params === true) {
                                this.is_deceased = true;
                                this.is_deceasing = false;

                                this.$router.push({name: 'patients-index'});
                            }
                        }
                    }
                );
            }
        }
    },
    async mounted() {
        this.retrieveAudits();
        await this.retrieveOriginalPatient();
        await this.retrieveWardOptions();
        await this.retrieveLocalityOptions();
        await this.retrieveVisitors();
        this.populate();
    },
    head() {
        return {
            title: {
                inner: this.$t('patients.update_patient')
            },
        }
    }
}
</script>

<style lang="scss" scoped>
.page-container {
    main {
        @apply flex flex-row flex-wrap;

        .form {
            @apply mb-8;

            width: calc(50% - 1rem);

            &:nth-child(odd) {
                @apply mr-4;
            }

            &:nth-child(even) {
                @apply ml-4;
            }

            .form-body {
                @apply px-8 py-7;

                &.next-of-kin {
                    .next-of-kin-row-group {
                        .input-group {
                            @apply w-auto flex-1;
                        }

                        .button-remove {
                            @apply flex-none self-start mt-9;
                        }
                    }
                }
            }

            .row-add-container {
                @apply px-8 pb-8 mt-auto;
            }

            .visitors-table {
                @apply px-4;
            }
        }

        .divider {
            @apply w-full border-b-2 border-grey;
        }

        .table-area {
            @apply pt-7 pb-9 w-full;

            p.title {
                @apply text-black font-bold text-xl leading-none mb-6;
            }

            .td-values {
                @apply flex flex-col gap-y-4;

                .row {
                    @apply flex flex-col gap-y-1;

                    p.name {
                        @apply text-sm font-bold text-black leading-normal;
                    }

                    .values {
                        @apply flex flex-col;

                        .row-value {

                        }
                    }
                }
            }
        }
    }
}
</style>